@import "../../../../assets/scss/mixins.scss";

.summaryRoot {
  height: 100vh;
  width: 100%;

  &.smallScreen {
    padding: 0;
  }

  &:not(.smallScreen) {
    @include applyNotchProperty(padding-bottom, bottom, 24px, 24px, true);
    @include applyNotchProperty(padding-left, left, 24px, 24px, true);
    @include applyNotchProperty(padding-right, right, 24px, 24px, true);
    @include applyNotchProperty(padding-top, top, 24px, 24px, true);
  }
}

.summaryContainer {
  width: 100%;
  height: 100%;

  &.smallScreen {
    align-items: flex-end !important;
    display: flex;
    height: 100vh;
    padding: 0;
  }
}

.summary {
  background-color: #fff;
  margin: 0 !important;
  max-width: 720px !important;
  padding: 0 !important;
  width: 100%;

  &.smallScreen {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    bottom: 0;
    height: calc(100% - 64px) !important;
  }

  &:not(.smallScreen) {
    max-height: 720px;
  }
}

.summaryFooter {
  background-color: #F2F1F3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 26px !important;

  button {
    margin: 0 !important;
  }

  &.smallScreen {
    flex-direction: column !important;
  }
}

.summarySection {
  margin-top: 24px;
}

.summaryQuestionsContainer {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(32px, 32px));
  justify-content: space-between;
  padding: 0 32px;
}