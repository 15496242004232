.MuiChip-root {
  font-family: "Poppins" !important;
}
.view-ques {
  &,
  * {
    font-size: 15px !important;
    font-weight: 500 !important;
    font-family: Poppins !important;
    line-height: 20px !important;
    color: #000 !important;
    max-width: 160px !important;
    max-height: 100px !important;
    overflow: hidden;
  }
}

.questionField {
  &,
  * {
    font-size: 0.8125rem !important;
    font-weight: 400 !important;
    font-family: Poppins !important;
    line-height: 1.5 !important;
    color: #495057 !important;
  }
}
.AlertQp {
  background-color: #f7fbff;
  padding: 10px;
  border-left: 5px #2170d8 solid;
}
