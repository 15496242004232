.card {
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
    0px 10px 32px -4px rgba(24, 39, 75, 0.1) !important;
  width: 18.125rem !important;
  height: 21.25rem !important;
  border-radius: 5px !important;
}

.cardContainter {
  display: flex;
  justify-content: center;
  gap: 2rem;
  min-height: 70vh;
}
.relatedInfo {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #794df5;
}

.cardMedia {
  height: 9rem !important;
}

.cardTitle {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

.courseTagName1 {
  position: absolute;
  right: 10px;
  top: 10px;
}
.courseTagName2  {
  position: absolute;
  right: 10px;
  top: 110px;
}
.tag {
  position: absolute;
  top: 3px;
  right: 3px;
  font-weight: 500;
  border-radius: 5px;
  max-width: 105px;
  color: #fff;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 30px;
}
.tag2 {
  position: absolute;
  top: 110px;
  right: 3px;
  font-weight: 500;
  border-radius: 5px;
  max-width: 185px;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 30px;
}

.tagCard {
  font-weight: 500;
  border-radius: 5px;
  max-width: 185px;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 30px;
}

.tagAP {
  background-color: rgba(255, 255, 255, 0.8);
  color: #323036;
  white-space: nowrap;
}
.tagSD {
  background-color: #D99229;
  color: #fff;
}
.tagDF {
  background-color: #ec2f29;
  color: #fff;
}

.tagPN {
  background-color: #089A79;
  color: #fff;
}

.tagRAP {
  background-color: rgba(255, 255, 255, 0.8);
  color: #323036;
  white-space: nowrap;
}