@import "./Home.scss";

.content__header__container {
  margin-top: 80px;
  margin-bottom: 3.5rem;
}

.content__header__title_wrap,
.content__header__description {
  text-align: center;
}

.content__header__title_wrap {
  margin: 0;
}

.content__header__title,
.content__header__sub {
  font-weight: 600;
  font-size: 28px;
}

.content__header__title {
  color: $TervPrimary;
}

.content__header__sub {
  color: $TextDGray;
}

.content__header__description {
  font-size: 18px;
  color: $TextLGray;
}


.container {
  background: linear-gradient(#1B1834, #381571, #794DFD);
  padding-bottom: 50px;
  width: 100%;
  height: auto;
}



.resourcesContainer {
  display: flex;
  justify-content: center;
  gap: 2.4rem;
  flex-wrap: wrap;
  & > div {
    flex: 1 1 33%;
  }
}

.resourceCard {
  max-width: 18.875rem;
  background: linear-gradient(to right, #381571, #1B1834);
  border-radius: 10px;
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  & > div {
    position: relative;
    height: 12.5rem;
    width: 100%;
  }
  & img {
    height: 12.5rem;
    width: 100%;
    border-radius: 10px;
  }
}

.resourceCard:hover {
  padding: 8px;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
}

.resourceCardLink {
  position: absolute;
  bottom: 0.625rem;
  right: 0.625rem;
  background: rgba(255, 255, 255, 0.85);
  padding: 0.25rem 1.5rem;
  border-radius: 5px;
}

.resourceCardTag {
  font-weight: 600;
  color: $TervSecondary;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.resourceCardTitle {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%;
}

.button {
  width: Fixed (99.42px)px;
height: Fixed (39.25px)px;
padding: 19.62px 31.4px 19.62px 31.4px;
gap: 13.08px;
border-radius: 6.54px;
opacity: 0px;
border: none;
background-color: 
#2170D8;
}

.resourceCardDesc {
  font-size: 1rem;
  color: #7c7887;
  margin-bottom: 0;
  padding-left: 2%;
}
