@import "../../assets/scss/mixins.scss";

.footer {
  background-color: #efefef;
  display: flex;
  margin-top: auto;
  position: relative;
  @media screen and (max-width: 1023px) {
    @include applyNotchProperty(padding-left, left, 24px, 24px, true);
    @include applyNotchProperty(padding-right, right, 24px, 24px, true);
    @include applyNotchProperty(padding-bottom, bottom, 16px, 16px, true);
  }
  @media screen and (min-width: 1024px) {
    @include applyNotchProperty(padding-left, left, 32px, 32px, true);
    @include applyNotchProperty(padding-right, right, 32px, 32px, true);
    @include applyNotchProperty(padding-bottom, bottom, -1px, -1px, true);
  }
}
.footerMobile {
  background-color: #efefef;
  display: flex;
  margin-top: auto;
  position: relative;
}
