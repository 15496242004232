.gridItem {
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: rgba(158, 158, 158, 0.25) 0px 4px 16px;
}

.skeleton {
  position: absolute;
  transform: scale(1) !important;
  height: 100% !important;
  width: 100%;
  margin-left: 45%;
  align-items: center;
  margin-top: 5%;
}