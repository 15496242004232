.buttonYes {
  background-color: #e2180c !important;
  font-family: Poppins !important;
  color: #fff !important;
}

.buttonNo {
  background-color: #ececec !important;
  font-family: Poppins !important;
  color: #565656 !important;
  border: 0 !important;
}

.dialogActions {
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 20px !important;
}

.dialogMain {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
}

.dialogContent {
  padding: 30px 20px 0px 20px !important;
}

.dialogTitle {
  color: #262626;
  font-weight: 600;
  font-size: 17px;
  margin-top: 15px;
}

