.page {
  margin: 0 auto;
  max-width: 1440px;
  padding: 2rem 64px;
  width: 100%;
  &,
  * {
    font-family: "Poppins", sans-serif;
  }
}

@media (max-width: 960px) {
  .page {
    margin: 0;
    padding: 2rem 1rem;
  }

  .loadDataContainer {
    flex-direction: column;
  }

  .adminLoadDataContainer {
    flex-direction: column;
  }
}

.buttonYes {
  background-color: #794DF5 !important;
  font-family: Poppins !important;
  color: #ffffff !important;
  border: 0 !important;
  width: 219px;
  height: 40px;
}

.dialogActions {
  padding-top: 40px !important;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 20px !important;
}

.dialogActions1 {
  padding-top: 15px !important;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 20px !important;
}

.paraStyle {
  position: absolute;
  width: 480px;
  height: 30px;
  left: 60px;
  top: 219px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #794DF5;
}

.description {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dialogMain {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
}

.dialogContent {
  padding: 0px 50px 0px 50px !important;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #757475;
    border-radius: 10px;
    margin: 0 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
}

.dialogTitle {
  color: #262626;
  font-weight: 600;
  font-size: 17px;
  margin-top: 20px;
}

.pageTitle {
  color: #323036;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.pageContent {
  display: grid;
  gap: 24px;
  margin-bottom: 80px;
  margin-top: 32px;
  width: 100%;
  &.student {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, max-content) 400px repeat(6, max-content);
  }
  &.admin {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, max-content) 400px repeat(6, max-content);
  }

  &.studentReal {
    grid-template-rows: max-content 400px repeat(6, max-content);
  }

  &.adminReal {
    grid-template-rows: max-content auto repeat(6, max-content);
  }
}
.packagePurchaseContainer {
  align-items: center;
  background-color: #fd691e;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 26px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  padding: 12px;
  position: relative;
  text-align: center;
  width: 100%;
}
.packagePurchaseText {
  text-align: center;
  z-index: 10;
}
.packagePurchaseButton {
  background-color: #794df5;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
  padding: 12px 20px;
  z-index: 10;
  svg {
    height: 20px;
  }
}
.packagePurchaseContainerDots {
  height: 64px;
  position: absolute;
  &1 {
    left: 12px;
    top: 10px;
  }
  &2 {
    bottom: 10px;
    right: 12px;
  }
}
.student {
  .leaderboard,
  .leaderboardBg {
    height: 100%;
    width: 100%;
  }
  .leaderboard {
    background-color: #201b2c;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1px 1fr;
    padding: 24px;
    position: relative;
  }
  .leaderboardDivider {
    height: calc(100% - 16px);
    margin: auto;
    width: 100%;
  }
  .leaderboardBg {
    object-fit: cover;
    object-position: center;
    position: absolute;
  }
  .leaderboardDivider {
    background-color: #6d6d6d;
  }
  .leaderboardSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 10;
  }
  .leaderboardTitle {
    color: #fff;
    font-size: 18px;
    justify-content: center;
  }
  .leaderboardTop3 {
    margin-top: 32px;
  }
  .leaderboardTop7 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 64px;
  }
  .leaderboardViewAllButton {
    color: #fff;
  }
}
.admin {
  .leaderboard {
    background-color: #fff;
    height: 100%;
    padding: 16px;
    width: 100%;
  }
  .leaderboardTitle {
    color: #000000;
    font-size: 16px;
  }
  .leaderboardTop3 {
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .leaderboardViewAllButton {
    color: #0039ff;
  }
  .totalQuestionsChartContainer {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    height: 100%;
    position: relative;
    width: 100%;
  }
  .totalQuestionsChart {
    height: calc(100% - 24px);
    position: relative;
    width: 100%;
  }
  .totalQuestionsLegendContainer {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .totalQuestionsLegend {
    align-items: center;
    display: flex;
    flex-grow: 0;
    height: 24px;
  }
  .totalQuestionsLegendColor {
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }
  .totalQuestionsLegendName {
    font-size: 16px;
  }
}
.leaderboardTitle {
  align-items: center;
  display: flex;
  font-weight: 500;
}
.leaderboardDescription1 {
  color: #000;
  display: flex;
  font-size: 17px;
  font-weight: 400;
  justify-content: center;
  line-height: 1.75;
}
.leaderboardDescription2 {
  color: #858585;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: 1.5;
}
.leaderboardTop3 {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 16px;
  width: 100%;
}
.leaderboardViewAllButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}
.leaderboardBody {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.leaderboardFooter {
  align-items: center;
  display: flex;
  justify-content: center;
}
.avgAssessmentMarksTitle {
  align-items: flex-start;
  flex-direction: column !important;
  height: auto;
}
.avgAssessmentMarksOptions {
  margin: 0 auto !important;
  margin-top: 28px !important;
}
.avgAssessmentMarksChartContainer {
  padding: 20px 24px 24px 24px;
  width: 100%;
}
.avgAssessmentMarksChart {
  display: flex;
  width: 100%;
}
.avgAssessmentMarksPoint {
  color: #302929;
  flex-shrink: 0;
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
  margin-left: 32px;
  position: relative;
  &::before {
    background-color: #1ba94c;
    border-radius: 20px;
    content: " ";
    display: block;
    height: 8px;
    left: -22px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
  }
}
.avgAssessmentMarksContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}
.avgAssessmentMarksChartContent {
  flex-grow: 1;
  width: 100%;
}
.avgAssessmentMarksPieChart {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.studentPointsChart {
  svg {
    text {
      font-size: 15px !important;
    }
  }
}
.questionsSolved {
  svg {
    text {
      font-size: 14px !important;
    }
  }
}
.studentPointsViewChooser {
  background: #f0f6ff;
  border-radius: 5px;
  color: #000000;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 6px;
  line-height: 18px;
  padding: 10px 14px;
  position: absolute;
  right: 0;
  top: -12px;
  z-index: 10;
}
.studentPointsViewButton {
  background-color: transparent;
  border: none;
  display: block;
  margin-left: 14px;
  position: relative;
  text-align: left;
  &.active {
    &::before {
      background-color: #794df5;
      border: 1px solid #794df5;
    }
    &::after {
      border: 2px solid #f0f6ff;
      border-radius: 6px;
      content: " ";
      display: block;
      height: 10px;
      left: -13px;
      position: absolute;
      top: 4px;
      width: 10px;
      z-index: 2;
    }
  }
  &::before {
    border: 1px solid #000;
    border-radius: 6px;
    content: " ";
    display: block;
    height: 12px;
    left: -14px;
    position: absolute;
    top: 3px;
    width: 12px;
  }
}
.studentLevel {
  align-items: center;
  background-color: #fff;
  color: #24262e;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  justify-content: space-between;
  line-height: 24px;
  padding: 16px 20px 16px 20px;
  text-align: center;
  width: 100%;
}
.studentLevelIcon {
  height: 165px;
  margin-top: 10px;
}

.loadDataContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
  grid-column: 1/-1;
  grid-row: 2/3;
}

.adminLoadDataContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
  grid-column: 1/-1;
  grid-row: 4/5;
}

.loadDataMain {
  min-height: 4.25rem;
  flex-grow: 1;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 0.4375rem 1.125rem;
  background: #fffbef;
  border: 1px solid #eeb60c;
  border-radius: 5px;
}

.loadDataText {
  display: flex;
  gap: 0.5rem;
  > p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #63606c;
    margin: 0;
  }
}

.customerSelect {
  position: absolute !important;
  top: 6rem;
  right: 4rem;
}

.paraText1 {
  padding-top: 3%;
  // display: flex;
  // // gap: 25%;
  // align-items: stretch;
  display: grid;
  grid-template-columns: 130px 130px;
  justify-content: center !important;
}

.paraText2 {
  padding-top: 3%;
  display: flex;
  gap: 5%;
  justify-content: center !important;
}

.paraText3 {
  padding-top: 3%;
  display: flex;
  gap: 27%;
  justify-content: center !important;
}

.paraText4 {
  padding-top: 3%;
  display: flex;
  gap: 11%;
  justify-content: center !important;
}

.paraText5{
  
  background-color: #EDF9FF;
  color: #323036;
  width: 422px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 2%;
  padding-right: 2%;
  text-align: center;
  justify-content: center !important;
}
