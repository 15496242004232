.inline-group {
  max-width: 9rem;
  padding: 0.5rem;
}

.inline-group .form-control {
  text-align: right;
}
/* Chrome, Safari, Edge, Opera */
.numberInput input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}

.TableHead {
  width: 100% !important;
  border-radius: 5px !important;
}

.TableRow {
  height: 40px !important;
  border-radius: 5px !important;
}
.typeTableBody {
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #daf2fd;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
}
