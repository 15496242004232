.sectionDrawer {
  background-color: #fff !important;
  width: 100% !important;

  &:not(.smallScreen) {
    height: calc(100% - 40px) !important;
    margin: 20px !important;
    max-width: 380px !important;
    min-width: 320px !important;
    padding: 16px 0 0 0;

    .sectionDrawerQuestionsContainer {
      grid-template-columns: 32px 32px 32px 32px 32px;
      justify-content: space-between;
      padding: 0 32px !important;
    }
  }

  &.smallScreen {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    height: calc(100% - 60px) !important;
    margin: 0 !important;
    max-width: 100% !important;
    padding: 16px 0 0 0;

    .sectionDrawerQuestionsContainer {
      grid-template-columns: repeat(auto-fill, minmax(32px, 32px));
      justify-content: space-between;
      padding: 0 16px !important;
      width: 100%;
    }
  }
}

.sectionDrawerSection {
  margin-bottom: 48px;
}

.sectionDrawerQuestionsContainer {
  display: grid;
  gap: 24px;
}