.bg {
  background-color: #85DAE1;
  height: 48px;
  width: 100%;
}

.avatar {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  height: 40px;
  margin: auto;
  margin-top: -20px;
  object-fit: cover;
  object-position: center;
  width: 40px;
}

.menuItemIcon {
  svg {
    font-size: 18px;
    height: 18px;
    width: 18px;
  }
}