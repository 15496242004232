.stopwatch {
  align-items: center;
  border: 1px solid #fff;
  border-radius: 4px;
  display: flex;
  padding: 4px 8px;

  & * {
    color: #fff;
  }
}