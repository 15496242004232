.noticeHeader {
  background: linear-gradient(90deg, #8e2de2 0%, #4a00e0 100%);
  padding: 0.7rem;
  & > p {
    text-align: center;
    margin: auto;
    color: #fff;
    width: 80%;
    font-size: 1.25rem;
    line-height: 2rem;
    .spanHighlight {
      background: #ff0000;
      border-radius: 5px;
    }

    .spanCaps {
      display: block;
      font-weight: 600;
    }
  }
}
.noticeHeaderMobile {
  background: linear-gradient(90deg, #8e2de2 0%, #4a00e0 100%);
  padding: 10px 0px;
  & > p {
    text-align: left;
    margin: auto;
    color: #fff;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    padding: 10px;
    .spanHighlight {
      background: #ff0000;
      border-radius: 5px;
    }

    .spanCaps {
      display: block;
      font-weight: 600;
    }
  }
}

.heroCard {
  padding: 4rem;
  background-image: url(../../assets/company-specific//banner.png);
  display: flex;
  background-size: cover;
}
.heroCardMobile {
  padding: 15px;
  background-image: url(../../assets/company-specific/mobileBanner.jpg);
  background-size: cover;
}

.heroTitle {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.25rem;
  color: #fff;
}

.heroTitleMobile {
  padding-top: 32px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.heroCardChildOne {
  flex: 1;

  & > p {
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #fff;
  }

  & > ul {
    padding-left: 0;
    & > li {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.875rem;
      color: #fff;
      margin-top: 1.5rem;
      display: flex;
      gap: 0.75rem;
      align-items: center;
    }
  }
  .mainBuyNow {
    border: 0;
    width: 25rem;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    background-color: #23d306;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #ffffff;
  }
}
.heroCardChildOneMobile {
  flex: 1;

  & > p {
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #fff;
  }

  & > ul {
    padding-left: 0;
    padding-top: 20px;
    & > li {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #fff;
      list-style: none;
      margin-top: 1.5rem;
      display: flex;
      gap: 0.45rem;
    }
  }

  .mainBuyNow {
    border: 0;
    width: 280px;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    background-color: #23d306;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #ffffff;
  }
}

.heroCardChildTwo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.heroCardChildTwoMobile {
  position: absolute;
  top: 191px;
  right: 18px;
}

.reviewBox {
  background-color: #ffffff;
  width: 11.25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.reviewContainer {
  display: flex;
  gap: 1rem;
}
.reviewletter {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
  text-align: center;
}
.reviewletterMobile {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  color: #323036;
  text-align: center;
}

.reviewBoxRating {
  font-size: 1.125rem;
  line-height: 1.8125rem;
  color: #19181b;
}

.reviewBoxReviews {
  font-size: 0.625rem;
  line-height: 1rem;
  color: #96939f;
}

.achievementBannerMain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem;
  background: #f3fff7;
  & > p {
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.375rem;
    color: #1ba94c;
  }

  & > span {
    font-size: 1rem;
    font-style: italic;
    line-height: 1.625rem;
    color: #63606c;
    align-self: flex-end;
  }
}
.achievementBannerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3fff7;
  padding: 10px;
  margin: 40px auto;
  & > p {
    padding: 24px 0 0 0;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #1ba94c;
  }

  & > span {
    font-size: 12px;
    font-style: italic;
    line-height: 20px;
    color: #63606c;
    margin-bottom: 24px;
  }
}
.preparationBannerMain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6rem;
  background: #f3fff7;
}
.preparationBannerMainMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px auto;
  background: #f3fff7;
  padding: 10px;
}
.preparationBannerp {
  margin: "0";
  text-align: "center";
  font-weight: "600";
  font-size: "24px";
  line-height: "38px";
  color: "#1ba94c";
  white-space: "nowrap";
}
.preparationBannerparagraph {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.375rem;
  color: #1ba94c;
}
.timerparagraph {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 2.375rem;
}
.timeparagraph {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 2.375rem;
}
.ctaButton {
  background-color: #23d306; /* For browsers that do not support gradients */
  background-image: (linear-gradient(to left right, #42be20, #1ba94c));
  color: #f2f1f3;
  padding: 16px 60px;
  border: none;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 2.375rem;
  border-radius: 5px;
}
.CtaButtonMobile {
  background-color: #23d306; /* For browsers that do not support gradients */
  background-image: (linear-gradient(to left right, #42be20, #1ba94c));
  color: #f2f1f3;
  padding: 8px;
  border: none;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
  max-width: 320px;
}
.CtaLastButtonMobile {
  background-color: #23d306; /* For browsers that do not support gradients */
  background-image: (linear-gradient(to left right, #42be20, #1ba94c));
  color: #f2f1f3;
  padding: 10px;
  border: none;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 2.375rem;
  border-radius: 5px;
}
.timerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}
.wiproGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  gap: 24px;
}
.wiproGridMobile {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  gap: 24px;
}
.bonusParagraph {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 184px;
  height: 104px;
  font-family: Poppins;
  font-style: normal;

  font-size: 14px;
  line-height: 26px;
  text-align: center;
  & > span {
    font-weight: bold;
  }
}
.bonusGrid {
  display: flex;
  justify-content: center;
}
.bonusGridValue {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: indigo;
}

.bonusP {
  padding: 10px 5px;
  border: grey;
  text-align: center;
  color: #ffffff;
  box-shadow: 10px;
}
.wiproBanner {
  padding: 10px 5px;
  border: grey;
}
.cardTimer {
  padding: 10px 5px;
  border: grey;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 10px;
}
.timerContainer {
  display: flex;
  gap: 5.25rem;
  margin-bottom: 4rem;
}
.timerContainerMobile {
  display: flex;
  gap: 19px;
  margin-bottom: 4rem;
}

.timerItem {
  background-color: #fff;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);

  border-radius: 5px;
  width: 9.375rem;

  h6,
  div {
    text-align: center;
  }

  h6 {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #63606c;
  }

  div {
    font-weight: 500;
    font-size: 3rem;
    line-height: 4.5rem;
    color: #19181b;
  }
}
.timerItemMobile {
  background-color: #fff;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);

  border-radius: 5px;
  width: 71px;
  height: 71px;

  h6,
  div {
    text-align: center;
    padding-top: 9px;
  }

  h6 {
    font-size: 7px;
    line-height: 14px;
    color: #63606c;
  }

  div {
    font-weight: 500;
    font-size: 22px;
    line-height: 15px;
    color: #19181b;
  }
}

.cartButton {
  border: 1px solid #794df5;
}

.learningContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.375rem;
    color: #19181b;
  }
}
.learningContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h4 {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.375rem;
    color: #19181b;
  }
}

.learningInfo {
  display: flex;
  align-items: center;
  gap: 10rem;
  h6 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #323036;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.4375rem;
    color: #323036;
  }
}
.learningInfoMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  h6 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #323036;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.4375rem;
    color: #323036;
  }
}

.numberFilled {
  background-color: #a6f1d8;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  padding-top: 0.75rem;
  border-radius: 50%;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.375rem;
  color: #4a4851;

  &::after {
    content: " ";
    display: block;
    height: 6.25rem;
    width: 0.375rem;
    background-color: #f2f1f3;
    margin-top: 1.5625rem;
  }
}
.numberFilledMobile {
  background-color: #a6f1d8;
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #4a4851;

  &::before {
    background-color: #f2f1f3;
    border-radius: 10px;
    content: " ";
    display: block;
    position: absolute;
    width: 8px;
    z-index: 1;
  }
}
.learningPathItemList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.learningPathItem {
  display: flex;
  align-items: center;
  gap: 5rem;
}
.learningPathItemListMobile {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  z-index: 4;
}

.learningPathItemMobile {
  display: flex;
  align-items: center;
  gap: 5rem;
  z-index: 4;
}

.learningPathCard {
  background: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 5px;
  width: 40.625rem;
  height: 7.375rem;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 4px solid #1ba94c;
  h6 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #323036;
  }

  p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #63606c;
    margin: 0;
  }
}

.learningPathCardChildOne {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.learningPathCardType {
  padding: 0.25rem 0.5rem;
  background: #f2f1f3;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #4a4851;
  text-transform: uppercase;
}
.learningPathCardMobile {
  background: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 5px;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 4px solid #1ba94c;
  z-index: 4;
  h6 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #323036;
  }

  p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #63606c;
    margin: 0;
  }
}
.learningPathCardChildOneMobile {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 14px;
}

.learningPathCardTypeMobile {
  padding: 4px 8px;
  background: #f2f1f3;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #4a4851;
  text-transform: uppercase;
}
.placementGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 43px 210px;
  overflow: hidden;
  gap: 34px;
  background-image: url("./BonusBG.svg");
  background-size: cover;
  // max-width: 1900px;
}
.placementGroupMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 25px;
  overflow: hidden;
  gap: 30px;
  background-image: url("./BonusMobile.svg");
  background-size: cover;
  // max-width: 1900px;
}
.dots {
  display: flex;
}
.dotsPosition {
  position: absolute;
  left: 0;
}
.dotsPositionr {
  position: absolute;
  right: 0;
}
.dotsPositionw {
  position: absolute;
  right: 0;
}
.svgPosition {
  position: absolute;
  left: 185px;
}
.svgPositionMobile {
  position: absolute;
  left: 125px;
  bottom: 2427px;
}

.svgPositionMobileAchivement {
  padding: 10px;
}

.placementItem {
  font-size: 1.125rem;
  line-height: 1.8125rem;
  width: 321px;
  height: 130px;
  color: #ffffff;
  margin: 0;

  div {
    font-size: 1.25rem;
    line-height: 2rem;
    text-transform: uppercase;
  }

  span {
    font-weight: 600;
  }
}
.tryButton {
  position: relative;

  .freeStar {
    color: #fff !important;
    right: -24px;
    top: -24px;
    width: 32px;

    &,
    &::before,
    &::after {
      background-color: #ffe61a;
      border-radius: 2px;
      height: 52px;
      position: absolute;
      width: 65px;
    }

    &::before {
      transform: rotateZ(27.5deg);
    }

    &::after {
      transform: rotateZ(-27.5deg);
    }

    &::before,
    &::after {
      content: " ";
      display: block;
      left: 0;
      top: 0;
    }
  }

  .freeStarText {
    color: #e70000;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    position: relative;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }
}
.tryButtonMobile {
  position: relative;

  .freeStar {
    color: #fff !important;
    right: -4px;
    top: -25px;
    width: 32px;

    &,
    &::before,
    &::after {
      background-color: #ffe61a;
      border-radius: 2px;
      height: 39px;
      position: absolute;
      width: 51px;
    }

    &::before {
      transform: rotateZ(27.5deg);
    }

    &::after {
      transform: rotateZ(-27.5deg);
    }

    &::before,
    &::after {
      content: " ";
      display: block;
      left: 0;
      top: 0;
    }
  }

  .freeStarText {
    color: #e70000;
    font-size: 8.5px;
    font-weight: 600;
    line-height: 16px;
    position: relative;
    text-align: center;
    font-weight: bold;
    z-index: 2;
    padding-bottom: 10px;
  }
}
.corner {
  position: absolute;
  left: 0;
  top: 10px;
}
.bannerPreparation {
  background-image: url("./Featuresbg.svg");
  background-size: cover;
  padding: 0.5rem;
  width: 100%;
  border-radius: 5px;
  margin-top: 6rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.bannerPreparationMobile {
  background-image: url("./assets/FeaturesMobile.svg");
  background-size: cover;
  padding: 0.5rem;
  width: 100%;
  border-radius: 5px;
  margin-top: 6rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.bannerPreparationBg {
  background-size: cover;
  padding: 0.5rem;
  width: 100%;
  border-radius: 5px;
  margin-top: 6rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.preparationparagaraph {
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  & > span {
    font-weight: bold;
  }
}
.wiproCardSpace {
  padding-left: 177px;
  padding-right: 100px;
  & > p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    width: 90%;
  }
}
.wiproCardSpaceMobile {
  padding-left: 5px;
  & > p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
  }
}
.space {
  padding-top: 40px;
}
.spaceBanner {
  padding-top: 65px;
}
.spaceDesktop {
  padding-top: 64px;
}
.buyRoundOne {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  & p {
    font-weight: 600;
    font-size: 16px;
  }
  & li {
    font-weight: normal;
    font-size: 16px;
    padding-bottom: 8px;
  }
}

.offerIcon {
  position: absolute;
  top: -12px;
  right: -2px;
}

.gridCardItem {
  color: black;
  background: #ffffff;
  border-radius: 5px;
  width: 225px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gridCardItemIcon {
  margin-bottom: 20px;
}


@media only screen and (max-width: 767px) {
  .placementItem {
    width: 100%;
    height: fit-content;
  }
  .timerContainerMobile {
    grid-gap: 6px;
    gap: 8px;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timerItemMobile {
    width: 65px;
    height: 65px;
  }
  .svgPositionMobile {
    position: initial;
  }
  .mobileCenter {
    display: flex;
    justify-content: center;
  }
}