.page {
  display: flex;
  margin-bottom: 60px;
  min-height: calc(100vh - 60px - 60px);
  padding: 0 !important;

  &,
  * {
    font-family: "Poppins", sans-serif;
  }
}
.pageMobile {
  margin-bottom: 60px;
  min-height: calc(100vh - 60px - 60px);
  padding: 0 !important;

  &,
  * {
    font-family: "Poppins", sans-serif;
  }
}

.packagesDetails {
  width: 57.5%;
}
.packagesDetailsMobile {
  width: 100%;
}


.packagesDetailsHeading {
  color: #262626;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
  margin-bottom: 20px !important;
}
.packagesDetailsHeadingMobile {
  color: #262626;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
  margin-bottom: 20px !important;
}

.packagesDetailsContent {
  margin: auto;
  max-width: 608px;
  padding: 24px;
  width: 100%;
}
.packagesDetailsContentMobile {
  margin: auto;
  max-width: 608px;
  padding: 7px;
  width: 100%;
}

.packagesContainer {
  margin-left: -16px;
  max-height: 312px;
  overflow-y: auto;
  padding: 0 16px 16px 16px;
  width: calc(100% + 32px);
}

.packageDescriptionContainer {
  margin-left: -16px;
  max-height: 312px;
  overflow-y: auto;
  padding: 0 16px 16px 16px;
  width: calc(100% + 32px);
}

.package {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  cursor: pointer;
  display: flex;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 24px;
  position: relative;

  &.selected {
    &::before {
      background-color: #794df5;
      content: " ";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 4px;
    }
  }
}

.packageSno {
  color: #000000;
  font-size: 18px;
  line-height: 27px;
  margin-right: 12px;
  width: 16px;
}

.packageImage {
  border-radius: 5px;
  height: 80px;
  position: relative;
  width: 100px;
}

.packageTexts {
  margin-left: 20px;
  width: calc(100% - 100px - 20px - 16px - 12px);
}

.packageName {
  color: #20222f;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  width: 100%;
}
.packageNameMobile {
  color: #20222f;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  width: 100%;
}

.packagePrice {
  align-items: center;
  display: flex;
}

.packageOriginalPrice {
  color: #0c0b0d;
  font-size: 20px;
  font-weight: 600;
}
.packageOriginalPriceMobile {
  color: #0c0b0d;
  font-size: 16px;
  font-weight: 600;
}

.packageStrikingPrice {
  color: #3a3a3a;
  font-size: 16px;
  line-height: 24px;
  text-decoration: line-through;
}
.packageStrikingPriceMobile {
  color: #3a3a3a;
  font-size: 14px;
  line-height: 24px;
  text-decoration: line-through;
}

.featuredTag,
.premiumTag,
.latestTag,
.specialTag {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  z-index: 2;
}

.premiumTag,
.latestTag,
.featuredTag,
.specialTag {
  border-bottom-left-radius: 4px;
  right: 0;
}

.featuredTag {
  background: linear-gradient(90deg, #ff512f 0%, #f09819 100%);
}

.premiumTag {
  align-items: center;
  background: linear-gradient(90deg, #870000 0%, #190a05 100%);
  display: inline-flex;

  svg {
    margin-top: -2px;
  }
}

.latestTag {
  background: linear-gradient(90deg, #cc2b5e 0%, #753a88 100%);
}

.specialTag {
  background: linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
}

.purchaseDetails {
  background-color: #fff;
  width: 42.5%;
}
.purchaseDetailsMobile {
  background-color: #fff;
  width: 100%;
  padding: 10 0;
}

.purchaseDetailsContent {
  margin: auto;
  max-width: 448px;
  padding: 24px;
  width: 100%;
}

.title {
  color: #262626;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
}

.description {
  color: #504f51;
  font-size: 13px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 32px;
}

.textField {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;

  label {
    color: #262626;
    font-size: 13px;
    margin: 0 0 6px 0;
  }

  input {
    appearance: none;
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    border-radius: 3px;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 16px;
    height: 40px;
    letter-spacing: 0.01em;
    line-height: 24px;
    margin: 0;
    outline-color: transparent !important;
    padding: 0 12px;

    &:active,
    &:focus {
      border-color: #007aff !important;
      outline-color: #007aff !important;
    }
  }
}

.couponContainer {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  .textField {
    width: calc(100% - 120px) !important;
  }

  button {
    margin: auto 0 0 20px;
    width: 100px;
  }
}

.amountTable {
  font-size: 15px;
  line-height: 22px;
  margin-top: 24px;
  width: 100%;

  td {
    padding: 10px 0;

    &:nth-child(1) {
      color: #504f51 !important;
      font-weight: 400;
      text-align: left;
    }

    &:nth-child(2) {
      color: #20222f !important;
      font-weight: 600;
      text-align: right;
    }
  }
}

.tc {
  align-items: center;
  color: #20222f;
  display: flex;
  font-size: 15px !important;
  line-height: 22px;
  margin: 18px 0 !important;
}

.dialog {
  max-width: 390px;
  padding: 48px 32px 32px 32px;

  &.success {
    .dialogIcon {
      background-color: #0C8C38;
    }
  }

  &.error {
    .dialogIcon {
      background-color: #E22B12;
    }
  }
}

.dialogPackagesList {
  border-radius: 10px;
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
}

.dialogTitle {
  color: #262626;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 38px;
  text-align: center;
}

.dialogDescription {
  color: #5B5A5F;
  font-size: 14px;
  line-height: 23px;
  padding: 20px 12px 32px 12px;
  text-align: center;
}

.dialogIcon {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: auto;
  margin-bottom: 32px;
  width: 40px;
}