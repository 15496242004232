.container {
  background-color: #fff;
  counter-reset: sno;

  :global(.MuiToolbar-root) {
    height: 100px;
  }

  :global(.MuiInputBase-root) {
    background-color: #f5f5f5;
    border: 1px solid #dadada;
    border-radius: 3px;
  }

  :global(.MuiInput-underline) {
    height: 40px !important;
    padding-left: 10px !important;

    &::before,
    &::after {
      content: none !important;
    }
  }

  :global(.MuiTableHead-root) {
    background-color: #c5eafc !important;
  }

  tr:global(.MuiTableRow-root) {
    color: #000;
    font-weight: 500;
    line-height: 1.5 !important;

    &:nth-child(even) {
      background-color: #f9fdff !important;
    }
  }

  td:global(.MuiTableCell-root) {
    font-size: 14px;
  }

  th:global(.MuiTableCell-root) {
    background-color: #c5eafc !important;
    color: #000000;
    font-size: 13px;
    font-weight: 400 !important;
    height: 60px;
    line-height: 1.25;
    padding: 0 15px;
  }
}

.sno {
  color: transparent;
  width: 0;

  &::after {
    color: #000;
    content: counter(sno);
    counter-increment: sno;
  }
}

.customTableToolbar {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  padding-right: 24px;
  width: 100%;

  :global(.MuiToolbar-root) {
    flex-grow: 1 !important;
  }
}

.exportButton {
  background-color: #f5f5f5 !important;
  border: 1px solid #dadada !important;
  border-radius: 3px !important;
  font-size: 16px !important;
  height: 40px !important;
  padding: 0 16px !important;
  text-transform: capitalize !important;
}