.container {
  width: 42.375rem;
  margin: 0 auto;
  padding: 3.125rem 0;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d8d6db;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  width: 42.375rem;
}

.submitButton {
  background-color: #794df5 !important;
  font-family: "Poppins", "sans-serif" !important;
  width: 6.3125rem !important;
  height: 3.0625rem !important;
  font-size: 1.125rem !important;
  text-transform: none !important;
  margin-right: auto !important;
}
