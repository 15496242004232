.tabNextListContainer {
  display: flex;
  justify-content: center;
}

.tabNextItem {
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.tabNextIndicator {
  height: 0.3rem;
  border-radius: 5px 5px 0 0;
}
