.page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tabContent {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.sectionsTab {
  border: 1px solid #ddd;
  margin: 32px auto 0 auto;
  width: auto;
}
.red{
  color:#DE5445
}
.green{
  color:#089A79
}
.proctoringHead {
  justify-content: space-around;
}
.proctoringHeader {
  display: flex;
  justify-content: space-evenly;
}
.proctoringBody {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.radioButton {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  opacity: 0;
}
.logHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #7C7887;
}
.logSubHeading {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
color: #19181B;
}
.scroll {
  overflow-y: hidden;
}
.customselect {
  padding: 5px;
  border: 1px solid #19181B;
  border-radius: 5px;
}