@import "../../assets/scss/var";

.modal {
  &,
  * {
    font-family: "Poppins";
  }
}

.modalContent {
  max-width: 800px !important;
  width: 100% !important;
}

.uploadButton {
  background-color: #f6f6f6;
}

.uploadHeader {
  margin: 8px 0 16px 0;
}

.header {
  background-color: #000;
  font-family: Poppins;
  padding: 6px 0 !important;
  position: relative !important;
  &,
  * {
    color: #fff !important;
    font-size: 16px !important;
    text-align: center;
  }
}

.closeButton {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    transform: translateY(-50%) scale(1.5);
  }
}

.successNoteContainer {
  margin: auto;
  padding: 12px 0 16px 0;
}

.successNote {
  align-items: center;
  background-color: #dfffe9;
  border-radius: 8px;
  color: #51b960;
  display: inline-flex;
  flex-shrink: 0 !important;
  font-size: 16px !important;
  padding: 12px 18px;
  span {
    margin-left: 12px;
  }
}

.dialogContent {
  &:not(.tableContainer) {
    flex-shrink: 0;
  }
}

.links {
  & > * {
    color: #5061f2 !important;
    white-space: nowrap;
    &:hover {
      text-decoration: underline !important;
    }
    &:last-of-type {
      margin-left: 16px !important;
    }
  }
}

.tableContainer {
  padding: 0 !important;
}

.tabs {
  border-radius: 8px !important;
  box-shadow: 0px 4px 16px rgba(53, 52, 52, 0.2) !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  max-width: 280px;
  overflow: hidden;
}

.tabButton {
  border: none !important;
  border-radius: 0 !important;
  padding-top: 8px;
  &:not(.active) {
    background-color: #fff !important;
    color: #000 !important;
  }
  &.active {
    background-color: #794df5 !important;
    color: #fff !important;
  }
}

.tabButtonTitle {
  display: block;
  font-size: 16px;
}

.tabButtonValue {
  display: block;
  font-size: 40px;
}

.tableCell {
  * {
    flex-shrink: 0;
  }
}
