.termsAndCondition {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    width: 940px;
    // height: 6690px;
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    margin-top: 50px;
}

.termsheader {
    margin-top: 24px;
    margin-left: 47px;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
}

.topics {
    margin-top: 20px;
    margin-left: 40px;
    width: 850px;
}

.topic-heading {
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
}

.topic-heading1 {
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-top: 25px;
    margin-left: 10px;
}

.topic-content {
    display: flex;
    align-items: left;
    justify-content: left;
    line-height: 28px;
    margin-left: 10px;
}

.topic-content1 {
    margin-top: 10px;
    display: flex;
    align-items: left;
    justify-content: left;
    line-height: 28px;
    margin-left: 10px;
}

.subtopic-header {
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.subtopic-content {
    margin-left: 5px;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #323036;
    font-weight: normal;
}

.subtopic-content1 {
    line-height: 28px;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #323036;
    font-weight: normal;
    margin-left: 10px;
}

.subtopic-content2 {
    line-height: 28px;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #323036;
    font-weight: normal;
    margin-top: 20px;
    margin-left: 10px;
}

.termsBackButton-Container {
    background-color: #f4f7fa;
    width: 940px;
    height: 80px;
    margin-top: 37px;
}
