.communicationTypeContainer {
    width: 663px;
    background-color: #EDF9FF;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.mailEditorStyle {
    width: 41.5rem;
}

.studentsInfoContainer {
    display: flex;
    gap: 1rem;
}

.studentsCountInfo {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.updateStudentsButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: #3A15A4;
    cursor: pointer;
}

.sendOptionContainer {
    display: flex;
    flex-direction: column;
    width: 141px;
    height: 75px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    box-shadow: 2px 6px 16px rgba(84, 84, 84, 0.2);
    border-radius: 5px;
    position: absolute;
}

.sendOptionButtons{
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #19181B !important;
    text-transform: lowercase !important;
    justify-content: left !important;
}

.modal {
  &,
  * {
    font-family: "Poppins";
  }
}

.modalContent {
  max-width: 800px !important;
  width: 100% !important;
}

.uploadButton {
  background-color: #f6f6f6;
}

.uploadHeader {
  margin: 8px 0 16px 0;
}

.header {
  background-color: #F9F9F9;
  width: 500px !important;
  height: 40px !important;
  padding: 6px 0 !important;
  position: relative !important;
  &,
  * {
    color: #323036 !important;
    font-size: 16px !important;
    text-align: center;
  }
  font-family:Poppins;
}

.templateContainer {
  width: 700px !important;
  background-color: yellow;
}

.templateHeader{
  background-color: #F9F9F9;
  width: 600px !important;
  height: 40px !important;
  padding: 6px 0 !important;
  position: relative !important;
  &,
  * {
    color: #323036 !important;
    font-size: 16px !important;
    text-align: center;
  }
  font-family:Poppins;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    transform: translateY(-50%) scale(1.5);
  }
}

.scheduleMailSubHeader {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #63606C;
}

.scheduleMailLabel {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #262626;
}

.scheduledMailInfo {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #63606C;
    margin-top: 1.5rem;
}

.scheduledMailInfoContainer {
  width: 315px;
  height: 34px;
  left: 0px;
  top: 27px;
  border: 1px solid #D8D6DB;
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
}

.successNoteContainer {
  margin: auto;
  padding: 12px 0 16px 0;
}

.successNote {
  align-items: center;
  background-color: #dfffe9;
  border-radius: 8px;
  color: #51b960;
  display: inline-flex;
  flex-shrink: 0 !important;
  font-size: 16px !important;
  padding: 12px 18px;
  span {
    margin-left: 12px;
  }
}

.dialogContent {
  &:not(.tableContainer) {
    flex-shrink: 0;
  }
}

.links {
  & > * {
    color: #5061f2 !important;
    margin-bottom: 8px !important;
    &:hover {
      text-decoration: underline !important;
    }
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}

.tableContainer {
  padding: 0 !important;
}

.tabs {
  border-radius: 8px !important;
  box-shadow: 0px 4px 16px rgba(53, 52, 52, 0.2) !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  max-width: 280px;
  overflow: hidden;
}

.tabButton {
  border: none !important;
  border-radius: 0 !important;
  padding-top: 8px;
  &:not(.active) {
    background-color: #fff !important;
    color: #000 !important;
  }
  &.active {
    background-color: #794df5 !important;
    color: #fff !important;
  }
}

.tabButtonTitle {
  display: block;
  font-size: 16px;
}

.tabButtonValue {
  display: block;
  font-size: 40px;
}

.tableCell {
  * {
    flex-shrink: 0;
  }
}
