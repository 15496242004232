@import "../../../../assets/scss/mixins.scss";

$largeScreen-padding: 24px;

.content {
  display: flex !important;
  flex: 1;
  position: relative;
  width: 100%;

  &:not(.largeScreen) {
    flex-direction: column !important;
  }

  &.smallScreen.codingQuestion {
    overflow: hidden !important;

    @include hasNotch {
      max-height: calc(100% - 24px - 56px - 72px) !important;
    }

    @include noNotch {
      max-height: calc(100% - 24px - 56px - 72px) !important;
    }
  }
}

.sliderContainer {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;

  :global(.MuiSlider-rail),
  :global(.MuiSlider-track) {
    opacity: 0 !important;
  }

  :global(.MuiSlider-thumb) {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    cursor: col-resize !important;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    height: 48px !important;
    justify-content: center;
    margin-top: -23px;
    padding: 4px !important;
    position: relative;
    transition-duration: 300ms;

    &::before {
      border-radius: 10px;
      content: ' ';
      display: block;
      height: calc(100% + 4px);
      margin-left: -2px;
      margin-top: -2px;
      position: absolute;
      width: calc(100% + 4px);
    }

    &:hover {
      &::before {
        border: 2px solid #aaa;
      }

      .widthSliderDot {
        background-color: #aaa !important;
      }
    }

    &:global(.MuiSlider-active) {
      &::before {
        background-color: #888 !important;
        border: 2px solid #888 !important;
      }

      .widthSliderDot {
        background-color: #fff !important;
      }
    }

    &::after {
      display: none !important;
    }
  }

  .widthSliderDot {
    background-color: #aaa;
    border-radius: 5px;
    height: 6px;
    margin: auto;
    position: relative;
    transition-duration: 300ms;
    width: 6px;
    z-index: 2;
  }
}

.questionSection {
  margin-right: auto;
  position: relative;
}

.solutionSection {
  margin-left: auto;
  position: relative;
}

:global(.small-screen) {
  .content {
    display: block;
  }
}

:global(.medium-screen) {
  .content {
    display: grid;
    gap: $largeScreen-padding;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    &>* {
      width: 100% !important;
    }
  }
}

:global(.medium-screen),
:global(.large-screen) {
  .content {
    grid-template-rows: 1fr;
    height: 100%;
  }
}