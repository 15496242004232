@import "../../../../../../assets/scss/mixins.scss";

$largeScreen-padding: 24px;

.wrapper {
  &.mediumScreen {
    .headerBar {
      &.bar1 {
        gap: 16px;
        grid-template-columns: 0.5fr 1fr 1.5fr;
      }

      &.bar2 {
        gap: 16px;
        grid-template-columns: 1fr 1.5fr 48px;
      }
    }
  }

  &.largeScreen {
    .headerBar {
      gap: 16px;
      grid-template-columns: 1fr 1.25fr 1fr;
    }
  }
}

.container {
  padding: 0 $largeScreen-padding !important;
}

.header {
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1px 1fr;
  height: 100%;
}

.headerBar {
  align-items: center;
  display: grid;
  flex-grow: 1;

  &>* {
    overflow: hidden;
  }
}

.sectionInfo {
  color: #000 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.questionInfo {
  color: #666 !important;
  font-size: 12px !important;
  font-weight: normal;
  line-height: 20px !important;
}

.sectionSelectorContainer {
  border-radius: 3px !important;
  height: 40px !important;
  overflow: hidden !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  position: relative !important;

  &,
  .sectionSelector {
    height: 40px !important;
  }

  .sectionSelector {
    padding-bottom: 12px !important;
    padding-top: 7px !important;
  }

  fieldset {
    border: none !important;
  }
}

.sectionSelector {
  border: 1px solid #d8d6db !important;
  border-radius: 3px !important;
}

.paginationList {
  flex-wrap: nowrap !important;
}

.submitButton {
  flex-shrink: 0;
  height: 40px !important;
}

.flaggedQuestion {
  &::before {
    background-color: #EEB60C;
    border-radius: 3px;
    content: " ";
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    bottom: 2px;
    transform: translateX(-50%);
    width: 4px;
  }
}

.answeredQuestion {
  &::before {
    background-color: #43B224;
    border-radius: 3px;
    content: " ";
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    bottom: 2px;
    transform: translateX(-50%);
    width: 4px;
  }
}