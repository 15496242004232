.complexButtonEasy{
  background:rgba(81, 185, 96, 0.2);
  color:#00B11A;
  padding:10px;
  text-align:center;
  border-radius:5px;
  width:65%;
}
.complexButtonMed{
  background:rgba(242, 201, 76, 0.2);;
  color:#D4A207;
  padding:10px;
  text-align:center;
  border-radius:5px;
  width:65%;
}
.complexButtonHard{
  background:rgba(235, 87, 87, 0.2);;
  color:#EB5757;
  padding:10px;
  text-align:center;
  border-radius:5px;
  width:65%;
}