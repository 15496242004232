/** @format */
/** @format */

.main {
    width: 100%;
  }
  .header {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #F85B40;
  }
  .Title {
    font-family: Inter, sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.73px;
    letter-spacing: -0.01em;
    text-align: left;
    color:#794DFD;
  }

  .sub {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: justified;
    color:#4A4851;
    
    @media (min-width: 960px) {
        width: 620px;
    }
  }
 
  .joinGroupContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // gap: 7.75rem;
    gap:2%;
    // background: url("../../../assets/join-group-bg.png") no-repeat center;
    // background-size: cover;
    width: 100%;
    height: auto;
    padding: 3rem 2rem;
  }

  @media (max-width: 960px) {
    .joinGroupContainer {
      flex-direction: column;
    }
    .joinGroupActions {
      min-width: 60%;
    }
  }

  .joinGroupList {
    font-weight: 500;
    font-size: 17px;
    padding-left: 0;
    // width: 620px;
    @media (min-width: 960px) {
        width: 620px;
    }
  }
  .listItem {
    & > span {
      font-weight: 500;
      font-size: 1rem;
      color: #4a4851;
      margin-left: 0.8125rem;
      display: flex;
    }
    list-style-type: none;
    display: flex;
  }

  .joinGroupHeader {
    font-weight: 700;
    font-size: 36px;
    color: #000000;
    margin-bottom: 2rem;
  }

  .joinGroupActions {
    background: #ffffff;
    border: 1px solid #f2f1f3;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
      0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1.875rem 1.872rem;
  }

  .joinGroupBoxDesc {
    font-weight: 500;
    font-size: 1rem;
    color: #7c7887;
    margin: 0;
    text-align: center;
  }

  .buttonDiv {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 580px) {
    .joinGroupContainer {
      padding: 2rem 1rem;
    }
  }