.main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.25rem;
  padding: 0 12px;
  align-items: center;
}

.header {
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  margin: 0;
  color: #323036;
}
