.cardContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: -100px;
  width: calc(100% + 100px);
}
.placeContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-width: 100px;
}
.place {
  border-radius: 32px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.ptsCurveStatus {
  padding: 0 10px;
}
.card {
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  flex-grow: 1;
  height: 50px;
  overflow: hidden;
  padding: 5px;
  position: relative;
  width: calc(100% - 100px);
  &.isUser {
    box-shadow: #ff8d28 0 0 0 3px;
  }
}
.photo {
  border-radius: 50%;
  height: 40px;
  margin: 0;
  width: 40px;
}
.textContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 18px;
}
.name {
  color: #272450;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-right: auto;
  margin-top: 2px;
  text-align: left;
}
.name,
.college {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.college,
.deptYear {
  color: #403f47;
  font-size: 13px;
  margin-top: 0px;
}
.pts {
  color: #794df5;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: 20px;
}
.deptYear {
  display: flex;
  justify-content: flex-start;
}
