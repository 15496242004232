.CARD_NEW {
  width: 290px !important;
  height: 340px !important;
  margin: 2% !important;
  // box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
  // 0px 10px 32px -4px rgba(24, 39, 75, 0.1) !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08),
    0 -1px 4px -1px rgba(0, 0, 0, 0.24) !important;
  border-radius: 6px !important;
  transition: transform 0.2s !important;

  &:hover {
    transform: scale(1.03) !important;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.08) !important;
  }
}
.CardMedia {
  height: 150px !important;
  object-fit: fill !important;
}
.CardDesc {
  font-size: 15px !important;
  font-weight: 400 !important;
  max-height: 3.2rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardDescPara {
  background-color: #fff;
  color: #000;
}

.cardDescTooltip {
  background-color: #fff !important;
  padding: 10px !important;
  border-radius: 10px !important;
  max-height: 10rem !important;
  box-shadow: 1px 1px 1px rgba(24, 39, 75, 0.1) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #784df5;
    border-radius: 10px;
    margin: 0 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
}

.CardHeader {
  font-size: 15px !important;
  font-weight: 600 !important;
  max-height: 3.2rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  div {
    padding: 0.3rem;
    border: 1px solid #784df4;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 40px;
    opacity: 0.75;
    &:hover {
      border: 1px solid #784df4;
      opacity: 1;
    }
  }
}
