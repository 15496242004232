@import "./Home.scss";

.footerContainer {
  display: grid;
  grid-template: repeat(3, min-content) / repeat(12, 1fr);
  margin-top: 3.5rem;
  width: 100%;
  background-color: #fcfcfc;
  padding: 2.5rem 6.875rem;
}

.footerP1,
.footerP2,
.footerContactAddressP {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.8125rem;
  color: #323036;
}

.listGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blockHeader {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.8125rem;
  color: #323036;
  margin-bottom: 1.5rem;
}

.listGroupItem {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #323036;
}

.footerP1 {
  grid-column: 1/3;
  grid-row: 1/2;
}

.footerP2 {
  grid-column: 6/8;
  grid-row: 1/2;
}

.footerBlock1 {
  grid-column: 1/3;
  grid-row: 2/3;
}

.footerBlock2 {
  grid-column: 3/5;
  grid-row: 2/5;
  border-right: 1px solid #d8d6db;
}

.footerBlock3 {
  grid-column: 6/8;
  grid-row: 2/3;
}

.footerBlock4 {
  grid-column: 8/10;
  grid-row: 2/3;
}

.footerBlock5 {
  grid-column: 10/12;
  grid-row: 2/3;
}

.footerContact,
.footerContactAddress {
  margin-top: -6rem;
}

.footerContact {
  grid-column: 1/3;
  grid-row: 3/4;
}

.footerContactAddress {
  grid-column: 3/5;
  grid-row: 3/4;
}

@media (max-width: 960px) {
  .footerContainer {
    padding: 15px;
    display: flex;
    flex-direction: column;
  }
  .footerP2 {
    padding-top: 30px;
    grid-column: 1/-1;
    grid-row: 3/4;
  }

  .footerContactAddress,
  .footerContact {
    margin-top: 10px;
  }

  .footerBlock2 {
    padding-top: 30px;
    grid-column: 5/7;
    border: 0;
  }

  .footerBlock3 {
    padding-top: 30px;
    grid-column: 6/1;
    grid-row: 4/5;
  }

  .footerBlock4 {
    padding-top: 30px;
    grid-column: 5/7;
    grid-row: 4/5;
  }

  .footerBlock5 {
    padding-top: 30px;
    grid-column: 1/5;
    grid-row: 5/6;
  }

  .footerContact {
    padding-top: 30px;
    grid-column: 5/6;
    grid-row: 8/9;
  }
}

.footerContactUs {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: left;
  color: #323036;
  margin: 0;
  margin-bottom: 1.5rem;
}

.footerContactUsContainer {
  display: flex;
  gap: 1rem;
}

.socialMediaItem {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  background-color: $TervPrimary;
  color: #fff;
  border-radius: 5px;
}

.footerAddressStyle {
  font-size: 0.9rem;
  line-height: 1.625rem;
  color: #323036;
  margin-right: 0.5rem;
}
