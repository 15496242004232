@import "../../../../assets/scss/mixins.scss";

.button {
  background-color: #fff !important;
  border: 2px solid #ddd !important;
  color: #000;
  height: 20px !important;
  margin: 0 !important;
  width: 20px !important;

  &.large {
    height: 32px !important;
    width: 32px !important;
  }

  &.flagged,
  &.answered,
  &.currentQuestion {
    color: #fff !important;
  }

  &.flagged {
    background-color: #EEB60C !important;
    border-color: #EEB60C !important;

    &:hover {
      background-color: #ce9d0c !important;
      border-color: #ce9d0c !important;
    }
  }

  &.answered {
    background-color: #43B224 !important;
    border-color: #43B224 !important;

    &:hover {
      background-color: #37941e !important;
      border-color: #37941e !important;
    }
  }

  &.currentQuestion {
    background-color: #63606C !important;
    border-color: #63606C !important;

    &:hover {
      background-color: #63606C !important;
      border-color: #63606C !important;
    }
  }

  &.answered,
  &.currentQuestion {
    &.flagged {
      border-color: #ce9d0c !important;
      box-shadow: #fff 0 0 0px 2px inset;
      border-width: 3px !important;
    }
  }

  &.currentQuestion {
    &.answered {
      border-color: #37941e !important;
      box-shadow: #fff 0 0 0px 2px inset;
      border-width: 3px !important;
    }
  }
}