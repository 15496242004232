.tabs {
  background-color: #fff;
  border-radius: 6px 6px 6px 6px;
  // box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
  //   0px 4px 6px -4px rgba(24, 39, 75, 0.12);
  box-shadow: rgba(158, 158, 158, 0.25) 0px 4px 16px 0px,
    0px 0px 8px 0px rgba(158, 158, 158, 0.08);
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  margin-bottom: 48px;
  width: 100%;
  &.noChildren {
    border-radius: 6px !important;
    overflow: hidden;
  }
  &.purple {
    .tabButton {
      &.active {
        // background-color: #f3efff !important;
        background-color: #e8e0ff !important;
        color: #794df5 !important;
        .badge {
          background-color: #794df5 !important;
        }
        &::after {
          background-color: #794df5 !important;
        }
      }
    }
  }
  &.green {
    .tabButton {
      &.active {
        background-color: #deffe9 !important;
        color: #03832f !important;
        .badge {
          background-color: #03832f !important;
        }
        &::after {
          background-color: #03832f !important;
        }
      }
    }
  }
}
.activeText {
  &.active {
    color: #794df5 !important;
  }
}
.tabbar {
  border-bottom: 1px solid #d9d9d9;
  display: inline-flex !important;
  flex-grow: 0;
  height: 56px;
  border-radius: 6px 6px 0 0;
  justify-content: flex-start;
  padding-right: 10%;
  overflow: hidden;
}
.tabButton {
  align-items: center !important;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: inline-flex !important;
  font-size: 15px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  min-width: 98px;
  padding: 5px 28px;
  position: relative;
  &:not(.active) {
    background-color: transparent;
    color: #6c6767;
    .badge {
      background: #a6a6aa !important;
      color: #fff !important;
    }
  }
  &.active {
    .badge {
      color: #ffffff !important;
    }
    &::after {
      bottom: 0;
      content: " ";
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 12;
    }
  }
}
.actions {
  align-items: center;
  display: flex;
  margin-left: auto;
  z-index: 13;
}
.text {
  position: relative;
  text-transform: capitalize;
  z-index: 2;
}
.badge {
  border-radius: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  margin-left: 5px;
  padding: 2px 8px;
  position: relative;
  text-align: center;
  z-index: 2;
}
.content {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 32px 16px 16px 16px;
  position: relative;
  width: 100%;
  z-index: 10;
  border-radius: 0 0 5px 5px;
}
.filterMenu {
  position: sticky;
  top: 56px;
  transition-duration: 300ms;
  transition-property: width padding;
}
@media screen and (min-width: 960px) and (max-width: 1240px){
  .tabButton{
    padding-left: 0.1%;
    padding-right: 0%;
  } 
}