@import "../../../../assets/scss/mixins.scss";

.root {
  &.smallScreen {
    padding: 0px;
  }
}

.container {
  width: 100%;
}

.popup {
  max-width: 920px !important;

  &.smallScreen {
    border-radius: 0px !important;
    height: 100%;
    margin: 0 !important;
    max-height: 100% !important;

    .webCam {
      height: 200px;
      max-width: 280px;
      width: 100%;
    }
  }

  &:not(.smallScreen) {
    .webCam {
      height: 200px;
      width: 280px;
    }
  }
}

.webCam {
  background-color: #000;
  border: 2px solid #794df5;
  margin-bottom: 32px;
  margin-top: 32px;
  position: relative;

  video {
    height: 100%;
    width: 100%;
  }
}