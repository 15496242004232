.groupBlockHeader {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #63606c;
  margin: 0;
  margin-bottom: 1rem;
}

.groupBlockIcons {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 3.25rem;
  color: #63606c;
  margin: 0;
}
.groupFlex {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  margin-bottom: 1.5rem;
}

.groupFlexOne {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 25rem;
}

.groupFieldHeader,
.groupFieldDesc {
  margin: 0;
  color: #323036;
}

.groupFieldHeader {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.625rem;
}

.groupFieldDesc {
  font-size: 0.875rem;
  line-height: 1.4375rem;
}

.container {
  display: flex;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
  }
}

.switchContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  color: #7c7887;
}
