.questionsSolved {
  svg {
    text {
      font-size: 14px !important;
    }
  }
}
.chart {
  height: 300px;
  width: 341px;
}