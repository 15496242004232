.templateInfo {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #63606C;
}

.templateNamestyle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    // line-height: 24px;
    color: #19181B;
    width: 12rem;
    height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.templateHeader{
    background-color: #F9F9F9;
    width: 600px !important;
    height: 40px !important;
    padding: 6px 0 !important;
    position: relative !important;
    &,
    * {
      color: #323036 !important;
      font-size: 16px !important;
      text-align: center;
    }
    font-family:Poppins;
  }
  
  .closeButton {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      transform: translateY(-50%) scale(1.5);
    }
  }

.form {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 2rem;
  
    display: flex;
    gap: 4rem;
  }
  
  .privBox {
    background-color: #f2f1f3;
    padding: 24px 40px;
    margin: -2rem -2rem -2rem 0;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    & h4 {
      color: #3a3a3a;
      font-size: 1.125rem;
    }
  
    & p {
      color: #63606c;
      font-size: 0.875rem;
    }
  }
  
  .privAccordions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .mailAccordion {
    box-shadow: none !important;
    border: 1px solid #d8d6db !important;
    width: 34rem !important;
    margin-bottom: 15px;
  }
  
  .selectedItems {
    color: #794df5;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .checkboxPrimary {
    color: #794df5 !important;
  }
  
  .accordionSumm {
    justify-content: space-between !important;
    & p {
      padding: 0;
      margin: 6px 0px 0px 0px;
      color: #323036;
      font-weight: 500;
      font-size: 1rem;
    }
  
    & div {
      display: flex;
      gap: 1em;
      align-items: center;
    }
  }

  .messageContainer {
    margin: "1.5rem";
  }
  
  .checkboxLabel {
    color: #4a4851 !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
  }
  
  .accordionSummRoot {
    flex-direction: row-reverse !important;
    background-color: #ffffff;
    gap: 1rem !important;
  }
  
  .accordionDet {
    flex-direction: column !important;
    padding: 0 1.75rem !important;
  }
  
  .submitButton {
    background-color: #794df5 !important;
    font-family: "Poppins", "sans-serif" !important;
    width: 9.5625rem !important;
    height: 3.125rem !important;
    font-size: 1.125rem !important;
    text-transform: none !important;
    margin-right: auto !important;
  }
  