//
//
$sidebar-bg: #ffffff;
$sidebar-menu-item-color: #545a6d;
$sidebar-menu-sub-item-color: #545a6d;
$sidebar-menu-item-icon-color: #7f8387;
$sidebar-menu-item-hover-color: #383c40;
$sidebar-menu-item-active-color: #784df5;
$sidebar-width: 250px;
$sidebar-collapsed-width: 70px;
$sidebar-width-sm: 160px;
$sidebar-dark-bg: #2a3042;
$sidebar-dark-menu-item-color: #a6b0cf;
$sidebar-dark-menu-sub-item-color: #79829c;
$sidebar-dark-menu-item-icon-color: #6a7187;
$sidebar-dark-menu-item-hover-color: #ffffff;
$sidebar-dark-menu-item-active-color: #ffffff;
$header-height: 60px;
$header-bg: #ffffff;
$header-item-color: #555b6d;
$header-dark-bg: #2a3042;
$header-dark-item-color: #e9ecef;
$topbar-search-bg: #f3f3f9;
$footer-height: 60px;
$footer-bg: #EFEFEF;
$footer-color: #606060;
$topnav-bg: #fff;
$menu-item-color: #545a6d;
$menu-item-active-color: #556ee6;
$rightbar-width: 280px;
$display-none: none;
$display-block: block;
$navbar-brand-box-width: 250px;
$boxed-layout-width: 1300px;
$boxed-body-bg: #ebebf4;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$apex-grid-color: #f8f9fa;
//
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #eff2f7;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);
$blue: #556ee6;
$indigo: #564ab1;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f46a6a;
$orange: #f1734f;
$yellow: #f1b44c;
$green: #34c38f;
$teal: #050505;
$cyan: #50a5f1;
$colors: ();
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);
$primary: #784df5;
$secondary: #333333;
$success: #51B960;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;
$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "pink": $pink,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);
$theme-color-interval: 8%;
$yiq-contrasted-threshold: 200;
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;
//
$enable-caret: false;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;
//
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);
$sizes: ();
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);
//
$body-bg: #f8f8fb;
$body-color: $gray-700;
//
$link-color: theme-color("primary");
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
$emphasized-link-hover-darken-percentage: 15%;
//
$paragraph-margin-bottom: 1rem;
//
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");
//
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);
@include _assert-ascending($container-max-widths, "$container-max-widths");
//
$grid-columns: 12;
$grid-gutter-width: 34px;
//
$line-height-lg: 1.5;
$line-height-sm: 1.5;
$border-width: 1px;
$border-color: $gray-200;
$border-radius: 0.25rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.2rem;
$rounded-pill: 50rem;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$component-active-bg: $primary;
$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;
$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;
$embed-responsive-aspect-ratios: ();
$embed-responsive-aspect-ratios: join(
  ((21 9), (16 9), (4 3), (1 1)),
  $embed-responsive-aspect-ratios
);
//
$font-family-sans-serif: "Poppins", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.8125rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$headings-margin-bottom: $spacer / 2;
$headings-font-family: null;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: null;
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;
$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;
$small-font-size: 80%;
$text-muted: $gray-600;
$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;
$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;
$mark-padding: 0.2em;
$dt-font-weight: $font-weight-bold;
$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;
$list-inline-padding: 0.5rem;
$mark-bg: #fcf8e3;
$hr-margin-y: $spacer;
//
$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;
$table-color: $body-color;
$table-bg: null;
$table-accent-bg: $gray-100;
$table-hover-color: $table-color;
$table-hover-bg: $gray-100;
$table-active-bg: $table-hover-bg;
$table-border-width: $border-width;
$table-border-color: $border-color;
$table-head-bg: $gray-100;
$table-head-color: $gray-700;
$table-dark-color: $white;
$table-dark-bg: $gray-800;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($table-dark-bg, 7.5%);
$table-dark-color: $white;
$table-striped-order: odd;
$table-caption-color: $text-muted;
$table-bg-level: -9;
$table-border-level: -6;
//
$input-btn-padding-y: 0.47rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;
$input-btn-focus-width: 0.15rem;
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;
$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg: $line-height-lg;
$input-btn-border-width: $border-width;
//
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;
$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;
$btn-border-width: $input-btn-border-width;
$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);
$btn-link-disabled-color: $gray-600;
$btn-block-spacing-y: 0.5rem;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$label-margin-bottom: 0.5rem;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;
$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;
$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;
$input-bg: $white;
$input-disabled-bg: $white;
$input-color: $gray-700;
$input-border-color: $gray-400;
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075);
$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;
$input-focus-bg: $input-bg;
$input-focus-border-color: darken($input-border-color, 10%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: none;
$input-placeholder-color: $gray-600;
$input-plaintext-color: $body-color;
$input-height-border: $input-border-width * 2;
$input-height-inner: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2}
);
$input-height-inner-half: calc(
  #{$input-line-height * 0.5em} + #{$input-padding-y}
);
$input-height-inner-quarter: calc(
  #{$input-line-height * 0.25em} + #{$input-padding-y / 2}
);
$input-height: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
);
$input-height-sm: calc(
  #{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border}
);
$input-height-lg: calc(
  #{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border}
);
$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$form-text-margin-top: 0.25rem;
$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.3rem;
$form-check-input-margin-x: 0.25rem;
$form-check-inline-margin-x: 0.75rem;
$form-check-inline-input-margin-x: 0.3125rem;
$form-grid-gutter-width: 10px;
$form-group-margin-bottom: 1rem;
$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
$custom-forms-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$custom-control-gutter: 0.5rem;
$custom-control-spacer-x: 1rem;
$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: $input-bg;
$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: $input-box-shadow;
$custom-control-indicator-border-color: $gray-500;
$custom-control-indicator-border-width: $input-border-width;
$custom-control-indicator-disabled-bg: $input-disabled-bg;
$custom-control-label-disabled-color: $gray-600;
$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-disabled-bg: rgba(
  theme-color("primary"),
  0.5
);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;
$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow;
$custom-control-indicator-focus-border-color: $input-focus-border-color;
$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg;
$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http:e"),
  "#",
  "%23"
);
$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http:e"),
  "#",
  "%23"
);
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;
$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http:e"),
  "#",
  "%23"
);
$custom-switch-width: $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius: $custom-control-indicator-size / 2;
$custom-switch-indicator-size: calc(
  #{$custom-control-indicator-size} - #{$custom-control-indicator-border-width *
    4}
);
$custom-select-padding-y: $input-padding-y;
$custom-select-padding-x: $input-padding-x;
$custom-select-font-family: $input-font-family;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem;
$custom-select-font-weight: $input-font-weight;
$custom-select-line-height: $input-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px;
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http:e"),
  "#",
  "%23"
);
$custom-select-background: $custom-select-indicator no-repeat right
  $custom-select-padding-x center / $custom-select-bg-size;
$custom-select-feedback-icon-padding-right: calc(
  (1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x +
    $custom-select-indicator-padding}
);
$custom-select-feedback-icon-position: center right
  ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half;
$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075);
$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width
  $input-btn-focus-color;
$custom-select-padding-y-sm: $input-padding-y-sm;
$custom-select-padding-x-sm: $input-padding-x-sm;
$custom-select-font-size-sm: $input-font-size-sm;
$custom-select-height-sm: $input-height-sm;
$custom-select-padding-y-lg: $input-padding-y-lg;
$custom-select-padding-x-lg: $input-padding-x-lg;
$custom-select-font-size-lg: $input-font-size-lg;
$custom-select-height-lg: $input-height-lg;
$custom-range-track-width: 100%;
$custom-range-track-height: 0.5rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-300;
$custom-range-track-border-radius: 1rem;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);
$custom-range-thumb-width: 1rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 1rem;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
  $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width;
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg: $gray-500;
$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: $input-focus-box-shadow;
$custom-file-disabled-bg: $input-disabled-bg;
$custom-file-padding-y: $input-padding-y;
$custom-file-padding-x: $input-padding-x;
$custom-file-line-height: $input-line-height;
$custom-file-font-family: $input-font-family;
$custom-file-font-weight: $input-font-weight;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: $input-box-shadow;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
  en: "Browse",
);
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: theme-color("success");
$form-feedback-invalid-color: theme-color("danger");
$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http:e"),
  "#",
  "%23"
);
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http:E"),
  "#",
  "%23"
);
$form-validation-states: ();
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid,
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid,
    ),
  ),
  $form-validation-states
);
//
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $gray-600;
$nav-tabs-border-color: $gray-400;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $card-bg;
$nav-tabs-link-active-border-color: $gray-400 $gray-400 $nav-tabs-link-active-bg;
$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;
$nav-divider-color: $gray-200;
$nav-divider-margin-y: $spacer / 2;
$navbar-padding-y: $spacer / 2;
$navbar-padding-x: $spacer;
$navbar-nav-link-padding-x: 0.5rem;
$navbar-brand-font-size: $font-size-lg;
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2;
$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http:e"),
  "#",
  "%23"
);
$navbar-dark-toggler-border-color: rgba($white, 0.1);
$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http:e"),
  "#",
  "%23"
);
$navbar-light-toggler-border-color: rgba($black, 0.1);
$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;
//
$dropdown-min-width: 10rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 0;
$dropdown-inner-border-radius: calc(
  #{$dropdown-border-radius} - #{$dropdown-border-width}
);
$dropdown-divider-bg: $gray-200;
$dropdown-divider-margin-y: $nav-divider-margin-y;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175);
$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;
$dropdown-link-active-color: darken($gray-900, 5%);
$dropdown-link-active-bg: $gray-100;
$dropdown-link-disabled-color: $gray-600;
$dropdown-item-padding-y: 0.35rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $gray-600;
$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: 1.25;
$pagination-color: $gray-600;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $gray-400;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;
$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-400;
$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;
$pagination-disabled-color: $gray-400;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-400;
$jumbotron-padding: 2rem;
$jumbotron-color: null;
$jumbotron-bg: $gray-200;
$card-spacer-y: 0.75rem;
$card-spacer-x: 1.25rem;
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-border-color: $gray-300;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $gray-300;
$card-cap-color: null;
$card-color: null;
$card-bg: $white;
$card-title-desc: $gray-600;
$card-img-overlay-padding: 1.25rem;
$card-group-margin: $grid-gutter-width / 2;
$card-deck-margin: $card-group-margin;
$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $grid-gutter-width;
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.7rem;
$tooltip-margin: 0;
$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;
$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: $gray-200;
$popover-border-radius: $border-radius-lg;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2);
$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: 0.75rem;
$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;
$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);
$toast-max-width: 350px;
$toast-padding-x: 0.75rem;
$toast-padding-y: 0.25rem;
$toast-font-size: 0.875rem;
$toast-color: null;
$toast-background-color: rgba($white, 0.85);
$toast-border-width: 1px;
$toast-border-color: rgba(0, 0, 0, 0.1);
$toast-border-radius: 0.25rem;
$toast-box-shadow: 0 0.25rem 0.75rem rgba($black, 0.1);
$toast-header-color: $gray-600;
$toast-header-background-color: rgba($white, 0.85);
$toast-header-border-color: rgba(0, 0, 0, 0.05);
$badge-font-size: 75%;
$badge-font-weight: $font-weight-medium;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.4em;
$badge-border-radius: $border-radius;
$badge-transition: $btn-transition;
$badge-focus-width: $input-btn-focus-width;
$badge-pill-padding-x: 0.6em;
$badge-pill-border-radius: 10rem;
$modal-inner-padding: 1rem;
$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;
$modal-title-line-height: $line-height-base;
$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: $gray-300;
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius-lg;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5);
$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x;
$modal-xl: 500px;
$modal-lg: 800px;
$modal-md: 350px;
$modal-sm: 300px;
$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
//
$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;
$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;
$progress-height: 0.625rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: $gray-300;
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: theme-color("primary");
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;
$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: $border-color;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;
$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;
$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;
$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;
$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;
$thumbnail-padding: 0.25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $gray-300;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075);
$figure-caption-font-size: 90%;
$figure-caption-color: $gray-600;
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: quote("/");
$breadcrumb-border-radius: $border-radius;
$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;
$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $white;
$carousel-indicator-transition: opacity 0.6s ease;
$carousel-caption-width: 70%;
$carousel-caption-color: $white;
$carousel-control-icon-width: 20px;
$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http:e"),
  "#",
  "%23"
);
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http:e"),
  "#",
  "%23"
);
$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out;
$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: 0.25em;
$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.2em;
$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white;
$code-font-size: 87.5%;
$code-color: $pink;
$kbd-padding-y: 0.2rem;
$kbd-padding-x: 0.4rem;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $gray-900;
$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex;
$overflows: auto, hidden;
$positions: static, relative, absolute, fixed, sticky;
$print-page-size: a3;
$print-body-min-width: map-get($grid-breakpoints, "lg");
