.page {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1336px;
  padding-bottom: 128px;
  padding-top: 24px;
  position: relative;
  width: 100%;
  &,
  * {
    font-family: "Poppins", sans-serif;
  }
}
.titleBox {
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
}
.title {
  align-items: center;
  display: flex;
}
.titleText {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  margin-left: 12px;
}
.completionDetailsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.completionDetails {
  color: #5c5f65;
  font-size: 15px;
  font-weight: 400;
  margin-left: 6px;
}
.tabbar {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  height: 52px;
  margin: 32px auto;
  padding: 8px 16px;
}
.tabButton {
  font-size: 15px !important;
  font-weight: 500 !important;
  height: 100%;
  line-height: 24px;
  line-height: 1;
  margin-right: 28px;
  outline: none !important;
  padding-bottom: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 0 !important;
  &:last-of-type {
    margin: 0 !important;
  }
  &.currentTab {
    box-shadow: 0px 2px 10px rgba(76, 80, 94, 0.25);
  }
  &:not(.currentTab) {
    background-color: transparent !important;
    color: #454155 !important;
  }
}
.scoreSummaryProgress {
  height: 300px;
  margin: 0 auto;
  margin-top: 16px;
  position: relative;
  width: 300px;
}
.scoreSummaryProgressPercentageContainer {
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.scoreSummaryProgressScorePercentage,
.scoreSummaryProgressTotalPercentage {
  color: #000000;
  text-align: center;
}
.scoreSummaryProgressScorePercentage {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.scoreSummaryProgressTotalPercentage {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.scoreSummaryInsightBoxContainer {
  background-color: #bbbbbb;
  border: 1px solid #bbbbbb;
  border-radius: 0 0 10px 10px;
  display: grid;
  gap: 1px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 8px;
  overflow: hidden;
}
.scoreSummaryInsightBoxContainerMobile {
  background-color: #bbbbbb;
  border: 1px solid #bbbbbb;
  border-radius: 0 0 10px 10px;
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 8px;
  overflow: hidden;
}

.scoreSummaryInsightBox {
  align-items: center;
  background-color: #f8faff;
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  position: relative;
  &.green,
  &.red,
  &.blue {
    &::before {
      content: " ";
      display: block;
      height: 5px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  &.green {
    &::before {
      background-color: #5ac62f;
    }
  }
  &.red {
    &::before {
      background-color: #e22b12;
    }
  }
  &.blue {
    &::before {
      background-color: #3548d3;
    }
  }
  h3 {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 27px;
    margin: 0 !important;
    text-align: center;
  }
  h1 {
    color: #262e3f;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    margin: 16px 0 0 0 !important;
    text-align: center;
  }
}

.scoreSummaryInsightBoxMobile {
  align-items: center;
  background-color: #f8faff;
  display: grid;
  flex-direction: row;
  padding: 16px 8px;
  position: relative;
  &.green,
  &.red,
  &.blue {
    &::after {
      content: " ";
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0px;
      display: block;
      width: 5px;
    }
  }
  &.green {
    &::after {
      background-color: #5ac62f;
    }
  }
  &.red {
    &::after {
      background-color: #e22b12;
    }
  }
  &.blue {
    &::after {
      background-color: #3548d3;
    }
  }
  h3 {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 20px;
    margin: 0 !important;
    text-align: center;
  }
  h1 {
    color: #262e3f;
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    margin: 16px 0 0 0 !important;
    text-align: center;
  }
}
.sectionPerformance {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 420px 420px;
}
.sectionPerformanceMobile {
  display:grid;
  gap: 40px;
  grid-template-columns: 1fr;
  grid-template-rows: 420px 420px;
  padding: 0 5px;
}
.chart {
  height: 300px;
}
.chartMobile {
  height: 300px;
}

.improvementsContainer {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  width: 100%;
}
.improvementsContainerMobile {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  padding: 0 5px;
  width: 100%;
}
.improvementBox {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(64, 63, 69, 0.15);
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 16px;
  position: relative;
  &:not(.collapsed) {
    &::before {
      opacity: 1;
    }
  }
  &::before {
    content: " ";
    display: block;
    height: 4px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition-duration: 250ms;
    width: 100%;
  }
  &.moderate {
    &::before {
      background-color: #eeb60c;
    }
    .type {
      background-color: #fdf3d6;
      color: #c69606;
    }
  }
  &.good {
    &::before {
      background-color: #43b224;
    }
    .type {
      background-color: #d1ffc5;
      color: #43b224;
    }
  }
  &.poor {
    &::before {
      background-color: #d42216;
    }
    .type {
      background-color: #fee3e1;
      color: #d42216;
    }
  }
}
.titleContainer {
  cursor: pointer;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}
.titleContainerMobile {
  cursor: pointer;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.titleContainer,
.titleTextContainer {
  align-items: center;
  display: flex;
}
.titleIconContainer {
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 48px;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  width: 48px;
}
.titleIcon {
  height: 32px;
  width: 32px;
}
.improvementBoxTitleText {
  color: #101113;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 0 16px !important;
}
.improvementBoxTitleTextMobile {
  color: #101113;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 0 16px !important;
}
.type {
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  height: 35px;
  line-height: 2;
  text-align: center;
  text-transform: capitalize;
  width: 140px;
}
.content {
  padding-left: 40px;
  padding-right: 40px;
  button {
    font-size: 18px;
    font-weight: 600;
    height: 42px;
    margin-top: 20px;
    padding: 0 20px;
  }
}
.description {
  color: #3a3a3a;
  font-size: 18px;
  font-weight: 400;
  margin-top: 16px;
}
.improvementsSectionContainer {
  margin: 0 auto;
  max-width: 622px;
  width: 100%;
}
.performanceMeter {
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 40px;
  position: relative;
  width: 100%;
  div {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.15;
    position: relative;
    text-align: center;
    &:nth-child(1) {
      background-color: #d42216;
      border-radius: 5px 0 0 5px;
      &::before {
        color: #000;
        content: "0";
        display: block;
        font-size: 16px;
        font-weight: 400;
        left: 0;
        line-height: 1.5;
        position: absolute;
        top: 100%;
      }
    }
    &:nth-child(2) {
      background-color: #eeb60c;
      &::before {
        color: #000;
        content: "50";
        display: block;
        font-size: 16px;
        font-weight: 400;
        left: 50%;
        line-height: 1.5;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
      }
    }
    &:nth-child(3) {
      background-color: #43b224;
      border-radius: 0 5px 5px 0;
      &::before {
        color: #000;
        content: "100";
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        position: absolute;
        right: -10px;
        top: 100%;
      }
    }
  }
}
.performanceMeterMobile {
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 40px;
  position: relative;
  width: 100%;
  padding:0 10px;
  div {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.15;
    position: relative;
    text-align: center;
    &:nth-child(1) {
      background-color: #d42216;
      border-radius: 5px 0 0 5px;
      &::before {
        color: #000;
        content: "0";
        display: block;
        font-size: 16px;
        font-weight: 400;
        left: 0;
        line-height: 1.5;
        position: absolute;
        top: 100%;
      }
    }
    &:nth-child(2) {
      background-color: #eeb60c;
      &::before {
        color: #000;
        content: "50";
        display: block;
        font-size: 16px;
        font-weight: 400;
        left: 50%;
        line-height: 1.5;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
      }
    }
    &:nth-child(3) {
      background-color: #43b224;
      border-radius: 0 5px 5px 0;
      &::before {
        color: #000;
        content: "100";
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        position: absolute;
        right: -1px;
        top: 100%;
      }
    }
  }
}
.tooltipContainerMobile {
  display: block;
  height: 100%;
  left: -10px;
  position: absolute !important;
  top: 0;
  width: calc(100% + 40px);
}


.tooltipContainer {
  display: block;
  height: 100%;
  left: -20px;
  position: absolute !important;
  top: 0;
  width: calc(100% + 40px);
}
.tooltip {
  align-items: center;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 50% 0 50% 50%;
  color: #ffffff;
  display: flex;
  font-size: 14px !important;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  position: absolute !important;
  text-align: center;
  top: 50px;
  transform: rotateZ(-45deg) !important;
  width: 40px;
  span {
    transform: rotateZ(45deg) !important;
  }
}
.performanceInfo {
  color: #3b3e47;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
.moderateType {
  color: #c69606;
}
.poorType {
  color: #d42216;
}
.goodType {
  color: #43b224;
}
.moderateType,
.poorType,
.goodType {
  font-weight: 400;
}
.sectionPerformanceCodingLegend,
.sectionPerformanceAptitudeLegend {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 2.5;
  margin-left: 56px;
  position: relative;
  &::before {
    content: " ";
    display: block;
    height: 16px;
    margin-left: -24px;
    position: absolute;
    top: 14px;
    width: 16px;
  }
}
.sectionPerformanceCodingLegendMobile {
  color: #000000;
  font-size: 11px;
  font-weight: 500;
  line-height: 2.5;
  margin-left: 56px;
  position: relative;
  &::before {
    content: " ";
    display: block;
    height: 16px;
    margin-left: -24px;
    position: absolute;
    top: 14px;
    width: 16px;
  }
}

.sectionPerformanceAptitudeLegendMobile {
  color: #000000;
  font-size: 11px;
  font-weight: 500;
  line-height: 2.5;
  margin-left: 56px;
  position: relative;
  &::before {
    content: " ";
    display: block;
    height: 16px;
    margin-left: -24px;
    position: absolute;
    top: 14px;
    width: 16px;
    background-color: #2697fc;
  }
}
.sectionPerformanceAptitudeLegend {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 2.5;
  margin-left: 56px;
  position: relative;
  &::before {
    content: " ";
    display: block;
    height: 16px;
    margin-left: -24px;
    position: absolute;
    top: 14px;
    width: 16px;
    background-color: #2697fc;
  }
}
.sectionPerformanceCodingLegendMobile,
.sectionPerformanceCodingLegend {
  &::before {
    background-color: #41526e;
  }
}

.sectionSummary {
  font-size: 22px;
  font-weight: 500;
  color: #000;
  display: flex;
  justify-content: center;
}
.sectionTab {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(53, 52, 52, 0.2);
  border-radius: 5px;
  margin: 30px auto;
  width: fit-content;
  display: flex;
}
.sectionTabBtn {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.sectionTabBtn {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding: 5px 35px;
  &::before {
      border-bottom: 3px solid #794DF5
    };
    &::before {
      border-bottom: 3px solid #794DF5
    };
}
