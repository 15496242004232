@import "./Home.scss";

.carouselSlider {
  padding-bottom: 5%;
  width: 95%;
  display: flex;
  align-items: center;
  margin: 0 auto; 
}

.main {
  background-color: #fcfcfc;
  margin: 6rem auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4rem;
}

@import "./Home.scss";

.marqueeContainer {
  // width: 500px; /* Adjust the width as needed */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

// .marqueeContent {
//   display: inline-block;
//   animation: marquee 10s linear infinite;
//   color: #1ba94c;
//   white-space: nowrap; /* Prevent content from wrapping */
// }

// .marqueeContent2 {
//   display: inline-block;
//   animation: marquee2 10s linear infinite;
//   color: #1ba94c;
//   white-space: nowrap; /* Prevent content from wrapping */
// }

// .marqueeContentPaused {
//   animation-play-state: paused;
//   font-size: 22px;
// }

// .marqueeContent:hover {
//   animation-play-state: paused;
// }

// .marqueeContent2:hover {
//   animation-play-state: paused;
// }

// @keyframes marquee {
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// }

// @keyframes marquee2 {
//   0% {
//     transform: translateX(-100%);
//   }
//   100% {
//     transform: translateX(100%);
//   }
// }

// @media screen and (max-width: 600px) {
//   .marqueeContainer {
//     width: 90%; /* Adjust as needed */
//   }

//   .marqueeContentPaused {
//     font-size: 16px; /* Adjust as needed */
//   }
// }

.content__header__container {
  margin-bottom: 3.5rem;
}

.content__header__title_wrap,
.content__header__description {
  text-align: center;
}

.content__header__title_wrap {
  margin: 0;
}

.content__header__title,
.content__header__sub {
  font-weight: 600;
  font-size: 28px;
}

.content__header__title {
  color: $TervPrimary;
}

.content__header__sub {
  color: #ffffff;
}

.content__header__description {
  font-size: 18px;
  color: $TextLGray;
}


.card {
  background: #ffffff;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  border-radius: 10px;
  width: 21.875rem;
}

@media (max-width: 960px) {
  .card {
    width: 17.575rem;
  }
}

.desc {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #323036;
  margin: 1.375rem 1.25rem;
}

.authorInfo {
  height: 7.5rem;
  background: #1ba94c;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > img {
    height: 3rem;
    width: 3rem;
    border-radius: 1.5rem;
    margin-top: -1.5rem;
    margin-bottom: 1rem;
  }
}

.authorName {
  // font-weight: 600;
  font-size: 12px;
  // line-height: 1.6875rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.head {
  // font-family: Inter;
font-size: 47.02px;
font-weight: 600;
line-height: 56.9px;
text-align: left;
color: #ffffff;
}

.para {
font-size: 20.57px;
font-weight: 500;
line-height: 33.8px;
text-align: left;
color: #ffffff;
}

.authorDesig {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.625rem;
  // color: #f2f1f3;
  margin: 0;
}


.topic {
  // font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  color: #2FFF4B;
}
.main {
  width: 100%;
}
.joinGroupContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
//   border-style: solid;
//   border-width: 1px;
  background: linear-gradient(to right,#FAFBFF,#98ADF5);
  border-radius: 25px;
  width: 100%;
  height: auto;
  padding: 3rem 2rem;
}

.joinGroupContainerTech1 {
    display: flex;
  align-items: center;
  justify-content: left;
//   border-style: solid;
//   border-width: 1px;
  background: #C0E1BF;
  border-radius: 25px;
  width: 700px;
  height: 400px;
  padding: 2rem 2rem; 
  @media (max-width : 960px){
      height: 500px ;
  }
  @media (max-width : 595px){
    width: 100%;
    height: auto;
}
}

.joinGroupContainerTech2 {
    display: flex;
  align-items: center;
  justify-content: space-around;
//   border-style: solid;
//   border-width: 1px;
  background:#D2DEFF;
  border-radius: 25px;
  width: 700px;
  height: 400px;
  padding: 2rem 2rem;
  @media (max-width : 960px){
    height: 500px ;
} 
@media (max-width : 595px){
  width: 100%;
  height: auto;
}
}

.joinGroupContainer2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
  //   border-style: solid;
  //   border-width: 1px;
    background: linear-gradient(to right,#FFF8FB,#C099F6);
    border-radius: 25px;
    width: 100%;
    height: auto;
    padding: 3rem 2rem;
  }

  .joinGroupContainer3 {
    display: flex;
    align-items: center;
    justify-content: space-around;
  //   border-style: solid;
  //   border-width: 1px;
    background: linear-gradient(to right,#F3FDF7,#72EE9E);
    border-radius: 25px;
    width: 100%;
    height: auto;
    padding: 3rem 2rem;
  }

  .joinGroupContainer4 {
    display: flex;
    align-items: center;
    justify-content: space-around;
  //   border-style: solid;
  //   border-width: 1px;
    background: linear-gradient(to right,#FCF8F2, #FAC26E);
    border-radius: 25px;
    width: 100%;
    height: auto;
    padding: 3rem 2rem;
  }


@media (max-width: 1020px) {
  .joinGroupContainer {
    flex-direction: column;
  }
  .joinGroupContainer2 {
    flex-direction: column;
  }
  .joinGroupContainer3 {
    flex-direction: column;
  }
  .joinGroupContainer4 {
    flex-direction: column;
  }
  .joinGroupContainer5 {
    flex-direction: column;
  }
  .joinGroupActions {
    min-width: 60%;
  }
}


.joinGroupList {
  font-size: 30px;
  font-weight: 600;
  line-height: 46px;
  text-align: left;
  color: #333333;
  // width: 620px;
  @media (min-width: 1060px) {
      width: 600px;
  }
}

.joinGroupListTech {
  font-size: 30px;
  font-weight: 600;
  line-height: 46px;
  text-align: left;
  color: #333333;
  margin-top: 20px;

  @media (max-width : 1060px){
    font-size: 25px;
    line-height: 30px;
    font-weight: 600px;
  }
}
.listItem {
  & > span {
    font-weight: 500;
    font-size: 1rem;
    color: #4a4851;
    margin-left: 0.8125rem;
    display: flex;
  }
  list-style-type: none;
  display: flex;
}

.joinGroupHeader {
  color: #fff;
  font-size: 56px;
  font-weight: 500;
  line-height: 79.88px;
  text-align: left;
}

@media (max-width: 580px) {
  .joinGroupContainer {
    padding: 2rem 1rem;
  }
  .joinGroupContainer2 {
    padding: 2rem 1rem;
  }
  .joinGroupContainer3 {
    padding: 2rem 1rem;
  }
  .joinGroupContainer4 {
    padding: 2rem 1rem;
  }
  .joinGroupContainer5 {
    padding: 2rem 1rem;
  }
}
.carouselProvider {
  background: linear-gradient(#1B1834, #381571, #794DFD);
  width: 100%;
  height: auto;
}