.main {
  & > p,
  & > span {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
  }

  & > p {
    &::first-letter {
      font-weight: 600;
      color: #000;
    }
    color: #323036;
    margin-top: 1rem;
  }

  & > span {
    text-decoration-line: underline;
    color: #0030da;
    cursor: pointer;
  }
}

.instructionBox {
  background: #fff9cc;
  border-radius: 5px;
  padding: 1rem;
  & > p {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    color: #323036;
    margin: 0;
    margin-bottom: 1rem;
  }

  & li {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    letter-spacing: 0.01em;
    color: #323036;
    margin-bottom: 0.5rem;
    & > span {
      color: #000;
      font-weight: 600;
    }
  }
}

.launchIns {
  background: #fffbef;
  border: 1px solid #eeb60c;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  gap: 0.625rem;
  margin-bottom: 1.5rem;
  & > p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #4a4851;
  }
}

.launchUl {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #4a4851;
  font-weight: 500;
}

.launchButtons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.launchMain {
  padding: 2rem;
}
