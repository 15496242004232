.manage-prepare__form {
  background-color: #fff;
  border: 1px solid #f2f1f3;
  border-radius: 6px;
  padding: 32px 24px;
  margin: 32px 0;
  box-shadow: rgba(158, 158, 158, 0.25) 0px 4px 16px 0px,
    0px 0px 8px 0px rgba(158, 158, 158, 0.08);
}

.Prize {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #323036;
}
.RadioPrize {
  display: flex;
  align-items: baseline;
  padding-left: 2rem;
  padding-right: 1rem;
}

.prizeExplain {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #323036;
}
