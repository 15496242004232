@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap");

/* .main-content{
  background: '#E5E5E5';
}
.main-content {
  background: #ffffff !important;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
} */
.prepare-body {
  background-color: white;
}
.withFilter7489 {
  width: 100%;
  height: 100%;
  flex: 1 1 0%;
  position: relative;
}
.withFilter7489 .fliter {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 290px;
  transform: translateX(0);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.withFilter7489 .fliter.openFilter {
  transform: translateX(0);
}
.withFilter7489 .fliter.closeFilter {
  transform: translateX(-290px);
}
.fliterContainer {
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translateX(0);
  width: 100%;
}
.fliterContainer.openFilter {
  transform: translateX(290px);
  width: calc(100% - 290px);
}
.smallScreen .fliterContainer.openFilter {
  transform: translateX(0);
  width: calc(100%);
}
.filter-button {
  width: 102px;
  height: 39px;
  background: #f9f9f9;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3a3a3a;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 20px 10px;
}

#input_container {
  position: relative;
  padding: 0 0 0 20px;
  margin: 0 20px;
  background: #ddd;
  direction: ltr;
  width: 200px;
}
#input {
  height: 20px;
  margin: 0;
  padding-right: 30px;
  width: 100%;
}
#input_img {
  position: absolute;
  bottom: 2px;
  right: 5px;
  width: 24px;
  height: 24px;
}

.banner-content {
  position: relative;
  top: 80px;
  text-align: left;
  width: 395px;
}

@media (max-width: 480px) {
  .search-input {
    width: 300px !important;
  }
}

.search-input {
  width: 400px;
  height: 40px;
  margin: 16px 0;
  box-sizing: border-box !important;
  overflow: hidden;
  padding: 0 !important;
}
.search-AdminInput {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  border: 1px solid #bababa !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  overflow: hidden;
  padding: 0 !important;
}
.search-AdminInput .form-controls {
  position: relative !important;
  border: none !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #a6a6a6;
  width: 100%;
  height: 100%;
  padding-left: 15px;
}
.search-AdminInput .form-controls:focus {
  border: none !important;
  outline: none;
}
.search-input .form-controls {
  position: relative !important;
  border: none !important;
  border: 1px solid #bababa !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  border-radius: 5px !important;
  color: #212020;
  width: 100%;
  height: 100%;
  padding-left: 15px;
}
.search-input .form-controls:focus {
  border: 1px solid #794df5 !important;
  outline: none;
}

.prepare {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #63606c;
}

.prepare-desc {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: #19181b;
}

.prepare-content {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #794df5;
}

.continue-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #19181b;
}

.continue-desc {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #63606c;
  bottom: 0px;
}

.continue-card {
  text-align: start;
  position: relative;
  top: 10px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.courseTag {
  position: absolute;
  top: 10px;
  right: 16px;
}
.cardGrid_Course {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 40px;
  gap: 40px;
  align-items: center;
}
.course-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #19181b;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  margin-bottom: 16px;
}

.continue-button {
  width: 100%;
  height: 40px;
  border: 1px solid #794df5 !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: #794df5 !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
  /* position: absolute !important;
  bottom: 13px;
  left: 10px; */
}
.rewatch-disable {
  width: 100%;
  height: 40px;
  border: 1px solid #faf9fb !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: #757575 !important;
  background-color: #bebebe !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
}

.continue-rating {
  font-size: 14px;
  position: relative;
  bottom: 4px;
  left: 4px;
}
.preview-button {
  width: 90%;
  border: 1px solid #794df5 !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: white !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  background: #794df5 !important;
  text-transform: none !important;
  position: absolute !important;
  bottom: 13px;
  left: 10px;
}
.search-course {
  position: relative;
  width: 100%;
  height: 40px;
  float: left;
  top: 40px;
  background: #ffffff;
  border: 1px solid #bababa;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px -4px rgb(24 39 75 / 12%),
    0px 4px 6px -4px rgb(24 39 75 / 12%);
  border-radius: 5px;
}

.form-controls {
  border: 1px solid white !important;
}

.form-controls::placeholder {
  font-size: 0.95rem;
  color: #aaa;
}

.various-course .MuiTabs-root {
  background: white !important;
  border-bottom: 1px solid #d9d9d9;
}

.various-course .PrivateTabIndicator-root-52 {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: relative;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.various-course button:focus {
  outline: none !important;
  outline: none !important;
}
.various-course .MuiTabs-indicator {
  background-color: #1ba94c !important;
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
  height: 4px !important;
}
.various-course .PrivateTabIndicator-colorPrimary-35 {
  background-color: #1ba94c !important;
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
  height: 4px !important;
}
.various-course .PrivateTabIndicator-colorPrimary-41 {
  background-color: #1ba94c !important;
}
.various-course .PrivateTabIndicator-root-34 {
  width: 100%;
  bottom: 0px;
  height: 4px !important;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.various-course .MuiTab-wrapper {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.various-course .MuiTab-textColorPrimary.Mui-selected {
  color: #1ba94c;
  background: #dbffe7 !important;
  border-bottom: 3px solid #1ba94c;
}
@media (min-width: 600px) {
  .various-course .MuiTab-root {
    min-width: 170px !important;
  }
}
.original-price {
  position: relative;
  top: 5px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-decoration-line: line-through;

  color: #f91818;
}

.course-discount {
  position: relative;
  top: 12px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #545260;
}

.logo {
  position: relative;
  width: 92px;
  height: 40px;
  left: 64px;
}
.course141 {
  flex-grow: 1;
  width: 100%;
  margin-top: 56px;
  margin-bottom: 64px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 4px 6px -4px rgb(24 39 75 / 12%),
    0px 4px 6px -4px rgb(24 39 75 / 12%);
}

.rating-value {
  font-size: 12px;
  position: relative;
  bottom: 4px;
}

.hours-svg {
  margin-bottom: 4px;
  margin-right: 3px;
}
.card-design {
  margin: auto !important;
  position: relative;
  top: 50px;
}

.duration {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  color: #908d99;
}
.level {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  color: #908d99;
}

.viewAll {
  width: 100%;
  height: 40px;
  border: 1px solid #1ba94c !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: #1ba94c !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
  /* position: absolute !important;
  bottom: 13px;
  left: 10px; */
}
.filterSubHeading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #323036;
}

.filterOption {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #19181b;
}
.filterOptionTags {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: white;
}
.scrollDesign::-webkit-scrollbar {
  width: 5px;
}
.scrollDesign::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scrollDesign::-webkit-scrollbar-thumb {
  background: #ded9ec;
  border-radius: 10px;
  width: 50px;
}

.scrollDesign::-webkit-scrollbar-thumb:hover {
  background: #bba9f0;
}
.enrolled {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #19181b;
}

.filterIndicate {
  display: flex;
  padding: 12px 0 12px 0;
}
.filterParagraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #19181b;
  background-color: #f2f1f3;
  border: 1px solid #e0dde3;
  padding: 8px 12px;
  border-radius: 6px;
}

.centerAlign {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
