.manage-prepare__header--main {
  background-color: #fff;
  min-height: 170px;
  margin: -32px -18px 24px -18px;
  padding: 36px 32px 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-content: flex-start;
  box-shadow: rgba(158, 158, 158, 0.25) 0px 4px 16px 0px,
    0px 0px 8px 0px rgba(158, 158, 158, 0.08);
}

.manage-prepare__header {
  margin-bottom: 0.5rem;
}

.manage-prepare__header,
.manage-prepare__header-sub {
  text-align: left;
}

.manage-prepare__header-sub {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #4a4851;
  margin: 0;
}
