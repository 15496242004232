.eventsTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #63606C;
    margin-top: -2.8rem;
    margin-left: 1.5rem;
}
.eventsCalendarContainer {
    width: 344px;
    height: 330px;
    background: #FFFFFF;
    border: 1px solid #D8D6DB;
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 2rem;
}
.monthSelector {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #2170D8 !important;
}
.monthMenu {
    position: absolute !important;
    margin-left: 57rem;
}
.yearMenu {
    position: absolute !important;
    margin-left: 60rem;
}
.calendar {
    &,
    button {
        border-radius: 100%;
        height: 41px;
    }
}
.dateNavigation {
    display: flex;
    height: 45px;
    margin-top: -2rem;
    gap: 1rem;
    margin-left: 4.7rem;
}
.chevronButton {
    width: 45px !important;
    height: 40px !important;
    padding: 9px 5px 10px 15px;
    border-radius: 50% !important;
    cursor: pointer;
    background-color: #F8F7FA;
}
.dataInfoContainer {
    width: 10rem;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: #F8F7FA;
    padding: 8px 8px 8px 16px
}
.dataInfo {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #2170D8;
}
.vectorButton {
    margin-left: 1rem;
    margin-top: 0.5rem;
    cursor: pointer;
}
.boxContainer {
    width: 25rem;
    height: 14rem;
    overflow: auto;
    gap: 1rem;
}
.box {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 16px 12px 24px;
    position: relative;
    box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
    border-radius: 10px;
    flex-shrink: 1;
    max-width: 35rem;
    height: 64px;
    margin-bottom: 1rem;
    // width: 31.5rem;
    &::before {
      content: " ";
      display: block;
      height: 64px;
      left: 0;
      position: absolute;
      border-radius: 10px 0 0 10px;
      top: 0;
      width: 1%;
    }
    &.blue {
      &::before {
        background-color: #41b9e6;
      }
      .icon {
        background-color: #e6f4f9;
        color: #41b9e6;
      }
    }
    &.green {
      &::before {
        background-color: #DE5445;
      }
      .icon {
        background-color: #d4f2d8;
        color: #05c120;
      }
    }
    &.ink {
      &::before {
        background-color: #2170D8;
      }
      .icon {
        background-color: #dee3f7;
        color: #0030da;
      }
    }
    &.red {
      &::before {
        background-color: #D99229;
      }
      .icon {
        background-color: #fae7e2;
        color: #da3208;
      }
    }
  }
  .viewButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #1BA94C;
    margin-top: 1rem;
    cursor: pointer;
  }
  .titleContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .title {
    color: #19181B;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    width: 12rem;
    height: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .description {
    color: #323036;
    display: inline-block;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    width: 100%;
  }
  
  .noEventsContainer {
    width: 15rem;
    height: 5rem;
    margin-top: 3rem;
    margin-left: 4.5rem;
    text-align: center;
  }

  .noEventsMessage {
    margin-top: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #63606C;
  }
  @media screen and (min-width: 1080px) and (max-width: 1200px){
    .eventsTitle{
      margin-left: 1.8rem;
    }
    .dateNavigation{
      margin-left: 90px;
    }
    .boxContainer{
      width: 20rem;
      margin-left: 19px;
    }
    .noEventsContainer{
      margin-left: 2.5rem;
    }
  }