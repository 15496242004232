.list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2.5rem;
}

.item {
  padding: 1rem 0.375rem;
  background-color: #f2f1f3;
  border-radius: 5px;
  min-width: 6.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
  color: #323036;
  cursor: pointer;
  transition: background-color 0.4s;
}

.selected {
  background-color: #1ba94c;
  color: #fff;
}
