.noDataContainer {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.noData {
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(83, 80, 80, 0.15);
  color: #7d7a7a;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 220px;
  justify-content: center;
  line-height: 22px;
  text-align: center;
  width: 220px;
}
