.buttonFilledMain,
.buttonFilledMain2,
.buttonOutlinedMain {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 0.5rem;
  height: 2.5rem;
  min-width: 5rem;
  padding: 0 1rem;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.875rem;
  transition: background-color 0.3s;
}
.buttonOutlinedMain2 {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 0.5rem;
  height: 2.5rem;
  min-width: 5rem;
  padding: 0 1rem;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.875rem;
  transition: background-color 0.3s;
}
.buttonFilledMain,
.buttonFilledMain2 {
  & svg {
    color: #ffffff !important;
    & path {
      fill: #ffffff !important;
    }
  }
}

.disabled {
  opacity: 0.8;
  &:hover {
    background-color: #794df5 !important;
    transition: none;
  }
}
.disabledSubmission {
  background-color: #bdbbc3 !important;
  &:hover {
    background-color: #a7a7a8 !important;
    transition: none;
  }
}

.buttonFilledMain {
  background-color: #794df5;
  color: #fff;
  border: 0;
  &:hover {
    background-color: #5d29fd;
  }
}
.buttonFilledMain2 {
  background-color: #1ba94c;
  color: #fff;
  border: 0;
  &:hover {
    background-color: #1c9c49;
  }
}

.buttonOutlinedMain {
  color: #794df5;
  border: 1px solid #794df5;
  background-color: #fff;
  &:hover {
    background-color: #eefff4;
  }
}
.buttonOutlinedMain2 {
  color: #1ba94c;
  border: 1px solid #1ba94c;
  background-color: #fff;
  &:hover {
    background-color: #eefff4;
  }
}
.secondary {
  background-color: #1ba94c;
  &:hover {
    background-color: #1ba94c;
  }
}

.tertiary {
  background-color: #f2f1f3;
  color: #000;
  &:hover {
    background-color: #f2f1f3;
  }
}
