@import "../../practice-lab.scss";

.content {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 20px;

  @at-root :global(.practice-light-mode) & {
    @include scrollbarLight;
  }

  @at-root :global(.practice-dark-mode) & {
    @include scrollbarDark;
  }

  &,
  * {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &.noTabs {
    height: 100% !important;
  }
}

.noHintText {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: calc(100% - 40px);
  justify-content: center;
  margin: auto;
  opacity: 0.35;
  padding: 20px;
  width: 100%;
}

.container {
  height: calc(100% - 40px);
}

.group {
  &.visible {
    filter: none;
  }

  &:not(.visible) {
    filter: blur(5px);
  }
}