@import "./Home.scss";

.carouselSlider {
  padding-bottom: 5%;
  width: 95%;
  display: flex;
  align-items: center;
  margin: 0 auto; 

  // @media (max-width: 1020px) {
  //   // height: 574px;
  //   height: 700px;
  // }
}

.topic {
  // font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  color: #2FFF4B;
}
.main {
  width: 100%;
}
.joinGroupContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(19, 15, 37, 0.4);
  background-color: rgba(19, 15, 37, 0.4);
  border-radius: 25px;
  // gap: 7.75rem;
  // background: url("../../../assets/join-group-bg.png") no-repeat center;
  // background-size: cover;
  width: 100%;
  height: auto;
  padding: 3rem 2rem;
}

@media (max-width: 1020px) {
  .joinGroupContainer {
    flex-direction: column;
  }
  .joinGroupActions {
    min-width: 60%;
  }
}

.joinGroupList {
  font-size: 22px;
  font-weight: 400;
  line-height: 46px;
  text-align: left;
  color: #fff;
  // width: 620px;
  @media (min-width: 960px) {
      width: 600px;
  }
}
.listItem {
  & > span {
    font-weight: 500;
    font-size: 1rem;
    color: #4a4851;
    margin-left: 0.8125rem;
    display: flex;
  }
  list-style-type: none;
  display: flex;
}

.joinGroupHeader {
  color: #fff;
  font-size: 56px;
  font-weight: 500;
  line-height: 79.88px;
  text-align: left;
}

@media (max-width: 580px) {
  .joinGroupContainer {
    padding: 2rem 1rem;
  }
}
.carouselProvider {
  background: linear-gradient(#1B1834, #381571, #794DFD);
  width: 100%;
  height: auto;
}