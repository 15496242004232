.page {
  display: flex;
  padding: 24px 32px 128px 32px;
  position: relative;
  width: 100%;
  &,
  * {
    font-family: "Poppins", sans-serif;
  }
}

.pageMobile {
  display: flex;
  padding: 10px 2px 128px 2px;
  position: relative;
  width: 100%;
  &,
  * {
    font-family: "Poppins", sans-serif;
  }
}

.questionCell {
  // width: 45% !important;
}
.pageContent {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1336px;
  position: relative;
  width: 100%;
}
.chartContainer {
  display: grid;
  gap: 64px;
  grid-template-columns: 1fr 1fr;
  padding: 8px 44px 32px 44px;
}
.chartContainerMobile {
  display: grid;
  gap: 64px;
  padding: 8px 2px 32px 2px;
}
.chartBox {
  height: 360px !important;
}
.chartBoxTitle {
  font-size: 16px !important;
}
.insightBoxContainer {
  background-color: #bbbbbb;
  border: 1px solid #bbbbbb;
  display: grid;
  gap: 1px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 8px;
}
.insightBoxContainerMobile {
  background-color: #bbbbbb;
  border: 1px solid #bbbbbb;
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 8px;
}
.insightBox {
  align-items: center;
  background-color: #f8faff;
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  h3 {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 27px;
    margin: 0 !important;
    text-align: center;
  }
  h1 {
    color: #262e3f;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    margin: 16px 0 0 0 !important;
    text-align: center;
  }
}
.tableContainer {
  max-height: 526px;
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
}
.table {
  margin: 0 !important;
  position: relative;
  width: 100%;
  thead {
    tr {
      background-color: #c5eafc;
      th {
        background-color: #c5eafc;
        color: #000000;
        font-size: 15px;
        font-weight: 600;
        height: 46px;
        line-height: 24px;
        position: sticky;
        text-align: center;
        top: 0;
        &:nth-child(2) {
          text-align: left;
          width: 64px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        &:nth-child(2) {
          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 220px;
          }
        }
      }
      &:nth-child(even) {
        background-color: #f9fdff;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }
      td {
        color: #000000;
        font-size: 15px;
        font-weight: 400;
        height: 48px;
        line-height: 24px;
        text-align: center;
        &:nth-child(2) {
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          // white-space: nowrap;
          width: 64px;
        }
        &:nth-child(4),
        &:nth-child(5) {
          text-transform: capitalize;
        }
        button {
          appearance: none;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}
.tableMobile {
  margin: 0 !important;
  position: relative;
  width: 100%;
  thead {
    tr {
      background-color: #c5eafc;
      th {
        background-color: #c5eafc;
        color: #000000;
        padding-left: 25px;
        font-size: 15px;
        font-weight: 600;
        height: 46px;
        line-height: 24px;
        position: sticky;
        text-align: center;
        top: 0;
        &:nth-child(2) {
          text-align: left;
          width: 64px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        &:nth-child(2) {
          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 220px;
          }
        }
      }
      &:nth-child(even) {
        background-color: #f9fdff;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }
      td {
        color: #000000;
        padding-left: 25px;
        font-size: 15px;
        font-weight: 400;
        height: 48px;
        line-height: 24px;
        text-align: center;
        &:nth-child(2) {
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          // white-space: nowrap;
          width: 64px;
        }
        &:nth-child(4),
        &:nth-child(5) {
          text-transform: capitalize;
        }
        button {
          appearance: none;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}

.legend {
  align-items: center;
  border-top: 2px solid #848484;
  display: flex;
  height: 64px;
  justify-content: center;
  div {
    align-items: center;
    color: #202022;
    display: flex;
    font-size: 16px;
    margin-right: 44px;
    &:last-of-type {
      margin-right: 0 !important;
    }
    & > svg {
      margin-right: 8px;
    }
  }
}
.legendMobile {
  align-items: center;
  border-top: 2px solid #848484;
  display: flex;
  height: 64px;
  justify-content: center;
  div {
    align-items: center;
    color: #202022;
    display: flex;
    font-size: 14px;
    margin-right: 24px;
    &:last-of-type {
      margin-right: 0 !important;
    }
    & > svg {
      margin-right: 8px;
    }
  }
}
.modal {
  width: 500px;
}
.modalHeader {
  background-color: #f9f9f9;
  border-bottom: 1px solid #c4c4c4;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  line-height: 27px;
}
.modalHeader {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
.modalContent {
  padding: 20px;
}
.marks {
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 84px;
  justify-content: center;
  margin: 24px auto 16px auto;
  width: 131px;
  div:nth-child(1) {
    color: #f8f8f8;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }
  div:nth-child(2) {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
  &.poor {
    background: #d42216;
  }
  &.average {
    background: #eeb60c;
  }
  &.good {
    background: #43b224;
  }
}
.marksTable {
  margin: 24px auto 0 auto;
  position: relative;
  width: 328px;
  z-index: 10;
  &::before {
    background-color: #f9f8f8;
    border-radius: 10px;
    content: " ";
    display: block;
    height: 100%;
    left: -40px;
    position: absolute;
    top: 0px;
    width: calc(100% + 80px);
    z-index: -1;
  }
  thead {
    th {
      padding-bottom: 12px;
      padding-top: 24px;
    }
  }
  tbody {
    tr {
      &:nth-child(1) {
        td {
          padding-top: 12px;
        }
      }
      &:last-of-type {
        td {
          padding-bottom: 16px;
        }
      }
    }
  }
  tr {
    font-size: 15px;
    line-height: 22px;
  }
  th,
  td {
    &:nth-child(2) {
      text-align: right;
    }
  }
  th {
    border-bottom: 1px solid #ededed;
    color: #676767;
    font-weight: 400 !important;
    height: 32px;
  }
  td {
    color: #212223;
    line-height: 2.5;
    &:nth-child(1) {
      font-weight: 400 !important;
    }
    &:nth-child(2) {
      font-weight: 600;
      &.red {
        color: #d42216;
      }
      &.green {
        color: #43b224;
      }
    }
  }
}
.modalCloseButton {
  appearance: none;
  background-color: transparent !important;
  border: none;
  color: #000;
  margin: none;
  padding: none;
  position: absolute;
  right: 2px;
}
.category1,
.category2,
.category3,
.category4,
.category5 {
  background: #ffffff;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  height: 32px;
  line-height: 2;
  margin: auto;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}
.category1 {
  border-color: #f1998e;
  color: #e11900;
}
.category2 {
  border-color: #fabda5;
  color: #c14f29;
}
.category3 {
  border-color: #ffcf70;
  color: #674d1b;
}
.category4 {
  border-color: #66d19e;
  color: #03703c;
}
.category5 {
  border-color: #a0bff8;
  color: #276ef1;
}
.questionInfo {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 6px auto 24px auto;
  width: 328px;
  div {
    span {
      font-size: 15px;
      &:first-of-type {
        color: #585858;
      }
      &:last-of-type {
        color: #000;
        font-weight: 500;
        margin-left: 8px;
        text-transform: capitalize;
      }
    }
  }
}
