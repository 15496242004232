.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 16px 16px 24px;
  position: relative;
  width: 100%;
}

.containerDisabled {
  opacity: 0.3;
}

.titleContainer {
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: space-between;
  position: relative;
  :global(.MuiInputBase-root) {
    :global(fieldset) {
      border: 1px solid #cfcfcf !important;
      border-radius: 5px !important;
    }
  }
}
.title {
  color: #24262e;
  font-size: 16px;
  font-style: normal;
  font-weight: 300 !important;
  margin: 0;
  strong {
    font-weight: 500 !important;
  }
}
.description {
  color: #000000;
  flex-shrink: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
}
.content {
  flex: 1;
  margin: 24px 0 0 0;
  overflow: visible;
  position: relative;
  svg {
    overflow: visible !important;
  }
}
