.box {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 12px 24px;
  position: relative;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  border-radius: 10px;
  flex-shrink: 1;
  max-width: 16rem;
  &::before {
    content: " ";
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    border-radius: 10px 10px 0 0;
    top: 0;
    width: 100%;
  }
  &.blue {
    &::before {
      background-color: #41b9e6;
    }
    .icon {
      background-color: #e6f4f9;
      color: #41b9e6;
    }
  }
  &.green {
    &::before {
      background-color: #05c120;
    }
    .icon {
      background-color: #d4f2d8;
      color: #05c120;
    }
  }
  &.ink {
    &::before {
      background-color: #0030da;
    }
    .icon {
      background-color: #dee3f7;
      color: #0030da;
    }
  }
  &.red {
    &::before {
      background-color: #da3208;
    }
    .icon {
      background-color: #fae7e2;
      color: #da3208;
    }
  }
}
.icon {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-left: auto;
  width: 40px;
  svg {
    height: 24px;
    width: 24px;
  }
}
.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.title {
  color: #262e3f;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.8;
  margin: 0;
}

.description {
  color: #35323f;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

@media (max-width: 960px) {
  .description {
    font-size: 13px;
  }
}
