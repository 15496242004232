@import "./Home.scss";

.priceContainer {
  & > div {
    display: inline-flex;
    align-items: center;
  }
  display: flex;
  gap: 0.625rem;
  margin-top: 1rem;
  justify-content: center;
}

.price {
  font-size: 21px;
  color: $TextDGray;
  font-weight: bold;
}

.priceStriked {
  font-weight: 500;
  font-size: 1rem;
  text-decoration-line: line-through;
  color: #f91818;
  margin-right: 0.25rem;
}
