.content {
  color: inherit !important;
  position: relative;

  h4 {
    color: inherit !important;
    font-size: 16px !important;
    text-decoration: underline !important;
  }

  pre {
    font-size: 12px !important;
    margin-bottom: 48px;
    margin-top: 16px !important;
    opacity: 0.75;
    white-space: pre-wrap !important;
    word-break: break-all !important;
    word-wrap: break-word;
  }

  &,
  h4,
  pre {
    color: inherit !important;
    font-size: inherit !important;
  }
}