.rightSection {
  grid-column: 10/13;
  grid-row: 1/3;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.rightSectionHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.studentName {
  font-size: 1.1rem;
  font-weight: 500;
  max-width: 13rem;
}

.actionItemsContainer {
  display: flex;
  flex-direction: column;
  & > p {
    font-size: 1rem;
    font-weight: 550;
    color: #000;
    text-align: center;
  }
}

.sectionQuestions {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > p {
    font-size: 1rem;
    font-weight: 550;
    color: #000;
    text-align: center;
  }
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem;
}

.sectionHeaders {
  display: flex;
  gap: 0.7rem;
  border-bottom: 1px solid #eee;
  overflow-x: auto;
  // width: 20rem;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background: #784df5;
    border-radius: 10px;
    margin: 0 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
}

.sectionHeaders:hover::-webkit-scrollbar {
  display: block;
}

.sectionHeader {
  color: #784df5;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.3em 1em;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  max-height: 2rem;
  white-space: nowrap;
}

.sectionHeaderColor {
  border-bottom: 2px solid #784df5;
  background-color: #F2EDFF;
}

.allQuestions {
  display: grid;
  grid-template: max-content/repeat(5, 1.5rem);
  place-content: center;
  grid-gap: 1rem;
  max-height: 16.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #784df5;
    border-radius: 10px;
    margin: 0 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
}

.eachQuestion {
  border-radius: 5px;
  border: 1.5px solid #000;
  height: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  color: rgb(7, 3, 3);
  cursor: pointer;
}

.activeQuestion,
.gradedQuestion {
  border: 0;
  color: #fff;
}

.activeQuestion {
  background-color: #676767;
}

.gradedQuestion {
  background-color: #51b960;
}

.actionGrades {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  border: 1px solid #dfe1e6;
  background-color: #fafbfc;
  border-radius: 5px;
  padding: 0.3rem;
  cursor: pointer;
}
