.listItem {
  display: grid;
  gap: 0 8px;
  grid-template-rows: 24px 16px;
  margin-bottom: 28px;
  width: 100%;
  &.type1 {
    grid-template-columns: 40px 1fr auto auto;
  }
  &.type2 {
    grid-template-columns: 40px 1fr 72px auto;
  }
}
.iconContainer,
.primaryInfo,
.secondaryInfo,
.statusContainer {
  height: 100%;
  width: 100%;
}
.iconContainer {
  align-items: center;
  background-color: #fdbc1f;
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  justify-content: center;
  padding: 6px;
}
.primaryInfo {
  color: #000000;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.noSecondaryInfo {
    line-height: 2.65;
    grid-row: 1 / 3 !important;
  }
}
.secondaryInfo {
  align-items: center;
  color: #a6a6aa;
  display: inline-flex;
  font-size: 12px;
  font-weight: 300;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.statusContainer,
.percentageCompleted {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  padding: 0 6px;
}
.percentageCompleted,
.statusContainer,
.status,
.actionContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}
.status {
  border-radius: 6px;
  font-size: 14px;
  height: 32px;
  line-height: 1.3;
  padding: 0 12px;
  text-transform: capitalize;
  width: 100%;
  span {
    font-size: 24px;
  }
  &.active {
    background-color: rgba(81, 185, 96, 0.1);
    color: #00b11a;
  }
}
.percentageCompleted {
  color: #000000;
  font-size: 16px;
  text-align: right;
}
.actionContainer {
  grid-column: 4 / 5;
  grid-row: 1 / 3;
}
.actionButton {
  border: 1px solid #794df5;
  border-radius: 6px;
  font-size: 16px;
  height: 100%;
  padding: 0 24px;
  &.fill {
    background-color: #794df5;
    color: #ffffff;
  }
  &.outlined {
    background-color: transparent;
    color: #794df5;
    &:hover {
      background-color: #794df5 !important;
      color: #ffffff !important;
    }
  }
}
