$size: 120px;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  // margin: auto;
  padding-bottom: 0;
  padding-top: 0;
  width: 100%;
}

@keyframes rotate {
  0% {
    transform: rotateX(-37.5deg) rotateY(45deg);
  }

  50% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }

  100% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

.cube,
.cube * {
  height: $size + 1;
  position: absolute;
  width: $size + 1;
}

.sides {
  animation: rotate 3s ease infinite;
  animation-delay: .8s;
  transform: rotateX(-37.5deg) rotateY(45deg);
  transform-style: preserve-3d;
}

.cube .sides>* {
  background-color: #fffc;
  border: $size / 15 solid #222;
  border-radius: 0px;
  box-sizing: border-box;
  text-align: center;
}

.top {

  &,
  div {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
  }

  div {
    background-color: #000;
    font-size: 22px;
    border-radius: 30px;
    color: #fff;
    display: inline-flex !important;
    height: 40px;
    width: 80px;
  }
}

@mixin side($name, $rotate, $i) {
  .#{$name} {
    animation: #{$name}-animation 3s ease infinite;
    animation-delay: $i * 100ms;
    animation-fill-mode: forwards;
    transform: #{$rotate} translateZ($size);
    transform-origin: 50% 50%;
  }

  @keyframes #{$name}-animation {
    0% {
      opacity: 1;
      transform: #{$rotate} translateZ($size)
    }

    20% {
      transform: #{$rotate} translateZ($size / 2)
    }

    70% {
      transform: #{$rotate} translateZ($size / 2)
    }

    90% {
      transform: #{$rotate} translateZ($size)
    }

    100% {
      opacity: 1;
      transform: #{$rotate} translateZ($size)
    }
  }
}

.cube .sides {
  @include side("top", rotateX(90deg), 0);
  @include side("bottom", rotateX(-90deg), 0);
  @include side("front", rotateY(0deg), 1);
  @include side("back", rotateY(-180deg), 1);
  @include side("left", rotateY(-90deg), 1);
  @include side("right", rotateY(90deg), 1);
}

.text {
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: $size * 3;
  text-align: center;
  width: 100%;
}