.manage-prepare__form {
  background-color: #fff;
  border: 1px solid #f2f1f3;
  border-radius: 5px;
  padding: 2.5rem 2rem;
}
.headerForm {
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 32px;
color: #19181B;
}