@mixin scrollbar($color, $hover-color) {
  scrollbar-width: 5px;
  scrollbar-height: 5px;
  scrollbar-color: $color transparent;

  &::-webkit-scrollbar-thumb {
    background: $color;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: $hover-color;
    }
  }

  &::-webkit-scrollbar-button {
    background-color: transparent;
    color: transparent;
    opacity: 0;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}


@mixin scrollbarDark {
  @include scrollbar(#1ba94c, #16853d);
}

@mixin scrollbarLight {
  @include scrollbar(#794df5, #5f3cbe);
}