.size{
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 300px;
    height: 300px;
  }
  .noSize{
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 0;
    height: 0;
  }
  .xmarkHide {
    visibility: hidden;
  }
  .xmarkBlock {
    visibility: block;
  }
  .timer {
    position: fixed;
    top:55%;
    left:5%;
  }
  .row {
    display: flex;
  }
  
  /* Create two equal columns that sits next to each other */
  .column {
    flex: 50%;
    padding: 10px;
  }

 
  .quest {
    position:absolute;
    top: 0;
    right: 0;
    /* bring your own prefixes */
    /* transform: translate(-50%, -10%); */
  }

  .detect{
    height: 50px;
    width: 50px;
  }

  .button{
    position:absolute;
    top: 80%;
    left: 10%;
  }
  
  .text{
    position:absolute;
    top:45%;
    left: 10%;
  }

  .leftClass{
    position:absolute;
    top: 60%;
    left: 5%;
  }