.page {
  margin: 0 auto !important;
  max-width: 1440px !important;
  padding: 32px 64px !important;
}
.filter {
  background-color: #fafafa !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0;
  margin-right: -580px;
  overflow: hidden;
  padding: 8px 0 !important;
  transition-duration: 300ms;
  transition-property: margin box-shadow visibility;
  width: 240px;
  &.visible {
    box-shadow: rgba(84, 84, 84, 0.2) 2px 6px 16px 0px !important;
    margin-right: 8px !important;
  }
}
.cardsContainer {
  background-color: #fff;
  border-radius: 5px;
  display: inline-grid;
  grid-gap: 40px 32px;
  grid-template-columns: repeat(auto-fit, minmax(296px, 1fr));
  justify-items: center;
  margin: 0 auto;
  padding: 40px 36px;
  width: 100%;
}
.noPackagesContainer {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.noPackages {
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(83, 80, 80, 0.15);
  color: #7d7a7a;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 220px;
  justify-content: center;
  line-height: 22px;
  text-align: center;
  width: 220px;
}
.pageMobile {
  position: relative;
  margin: 0 auto !important;
  max-width: 1440px !important;
  padding: 9px 8px !important;
}