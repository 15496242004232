.tabs {
  display: inline-grid;
  min-width: 200px;
  grid-template-columns: 1fr 1fr;
  &.white {
    & > button {
      border-color: #fff;
      color: #fff;
    }
  }
  &.black {
    & > button {
      border-color: #000;
      color: #000;
    }
  }
}
.tab {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  height: 36px;
  position: relative;
  &::after {
    content: " ";
    display: block;
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    width: 1px;
    z-index: 2;
  }
  &:nth-of-type(1) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 0px;
    &.active {
      &::after {
        right: -2px;
      }
    }
  }
  &:nth-of-type(2) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    &.active {
      &::after {
        left: -2px;
      }
    }
  }
  &.active {
    &.violet {
      background-color: #794df5;
      border-color: #794df5;
      color: #fff !important;
      &::after {
        background-color: #794df5;
      }
    }
    &.green {
      background-color: #51b960;
      border-color: #51b960;
      color: #fff !important;
      &::after {
        background-color: #51b960;
      }
    }
  }
}
