@import "./Home.scss";

.coursesContainer {
  margin-top: 2.5rem;
  display: flex;
  gap: 3rem;
}

.courseCard {
  box-shadow: 0px 0px 25px rgba(170, 170, 170, 0.25);
  border-radius: 5px;
  background-color: $HomeBgWhite;
  height: 27.525rem;
  width: 21.875rem;
  position: relative;
  & > img {
    height: 12.5rem;
    width: inherit;
    object-fit: fill;
    border-radius: 10px 10px 0 0;
  }
}

.courseCardPrac {
  box-shadow: 0px 0px 25px rgba(170, 170, 170, 0.25);
  border-radius: 5px;
  background-color: $HomeBgWhite;
  height: auto;
  width: 21.875rem;
  position: relative;
  & > img {
    height: 12.5rem;
    width: inherit;
    object-fit: fill;
    border-radius: 10px 10px 0 0;
  }
}

.mobileCourseCard {
  box-shadow: 0px 0px 25px rgba(170, 170, 170, 0.25);
  border-radius: 5px;
  background-color: $HomeBgWhite;
  height: 25rem;
  width: 16.775rem;
  position: relative;
  & > img {
    height: 12.5rem;
    width: inherit;
    object-fit: fill;
    border-radius: 10px 10px 0 0;
  }
}

.courseContent {
  padding: 0 1rem;
  width: 100%;
  // overflow: hidden;
  // white-space: nowrap;

  & > p {
    font-weight: 600;
    font-size: 1.125rem;
    color: $TextDGray;
    margin: 0.625rem 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1; 
    height: auto; 
    line-height: 1.5em;
  }
}

.priceContent {
  position: absolute;
  bottom: 5.125rem;
  left: 1rem;
  width: 90%;
}

.priceContentPrac  {
  bottom: 0.325rem;
  top: 1rem;
  padding-left: 3rem;
  width: 90%;
}

.description {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  padding-left: 5%;
  padding-right: 1%;
  margin: 12px 0 0 0;
  margin-bottom: 16px;
  // max-height: 100px;
  // overflow: hidden;
  list-style: none !important;
  text-decoration: none !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  & p strong {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    margin: 12px 0 0 0;
    margin-bottom: 16px;
    // max-height: 100px;
    // overflow: hidden;
    list-style: none !important;
    text-decoration: none !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.courseDetailsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  grid-row-gap: 0.625rem;
  & > div {
    font-size: 0.875rem;
    color: $TextLGray;
    display: inline-flex;
    align-items: center;
    place-self: flex-start;
    gap: 0.625rem;
  }
}

.previewButton {
  text-align: center;
  padding-top: 3.1375rem;
  @media (max-width: 959px) {
    padding-top: 1.1375rem;
  }
}

.previewButtonPrac {
  text-align: center;
  margin-top: 0.6375rem;
  width: 350px;
  margin-bottom: 1rem;
}

