.tableHeader {
    background-color: #C5EAFC;
}
.tableHeaderColor {
    color: #000000;
}
.subTableHead {
    background-color: #F5F9FF;
    display: flex;
    align-items: center;
    padding: 2rem 0.75rem;
    position: relative;
}
.subTableHeadColor {
    color: #000000;
    font-weight: 600;
    position: absolute;
    left: 7.6%;
}
.subTablePosition {
    position: absolute;
    right: 5%;
}
.subTablePositionTwo {
    position: absolute;
    right: 3.7%;
    display: flex;
}
.subTablePositionLeft {
    position: absolute;
    left: 7.6%;
}
.subTablePositionLeft:nth-child(3) {
    left: 26%;
    right: 20%;
}
.subTableRow {
    background-color: #F5F9FF;
    display: flex;
    align-items: center;
    padding: 2rem 0.75rem;
    border-bottom: 1px solid #EFEFEF;
    position: relative;
}
.greenBorder {
    border-left: 7px solid green;
    border-right: 7px solid green;
}
.viewMoreBtn {
    border: 1px solid #794DF5;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    color: #794DF5;
    background: white;
}