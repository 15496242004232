@import "../../../../../assets/scss/mixins.scss";
@import "../../practice-lab.scss";

$checkboxBorderWidth: 1px;
$checkboxHeight: 26px;
$checkboxPadding: 4px;
$checkboxWidth: 72px;
$checkboxButtonSize: $checkboxHeight - ($checkboxPadding * 2);
$checkboxTextWidth: $checkboxWidth - $checkboxButtonSize - ($checkboxPadding * 2);

.container {
  @at-root :global(.practice-small-screen) & {
    min-height: 180px;
  }

  @at-root :global(.practice-medium-screen) & {
    min-height: 200px;
  }

  min-width: auto !important;
  overflow: hidden;
  text-overflow: ellipsis;

  word-wrap: break-word;

  &.lightMode {
    .content {
      @include scrollbarLight;
    }
  }

  &:not(.lightMode) {
    .content {
      @include scrollbarDark;
    }
  }

  &.smallScreen {
    border-radius: 0px !important;
    flex: 1;
    overflow: hidden;

    &:not(.noFreeScroll) {
      min-height: calc(100% - 64px);
    }

    &.noFreeScroll {
      @include hasNotch {
        height: calc(100% - 64px - env(safe-area-inset-bottom));
      }

      @include noNotch {
        height: calc(100% - 64px);
      }
    }
  }
}

.content {
  display: block;
  flex: 1;
  height: calc(100% - 40px);
  left: 0;
  overflow-y: auto !important;
  padding: 20px;
  position: relative;
  top: 0;
  width: 100%;

  @at-root :global(.practice-small-screen) & {
    height: calc(100% - 48px) !important;
  }

  &.noRichText {
    * {
      color: inherit !important;
      font-family: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
    }
  }
}

.checkboxInput {
  border: 0;
  display: inline;
  height: 0px;
  margin: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 0px;
}

.checkbox {
  align-items: center;
  border: 1px solid #9c9c9c !important;
  border-radius: $checkboxHeight !important;
  display: flex;
  height: $checkboxHeight + ($checkboxBorderWidth * 2);
  overflow: hidden !important;
  padding: $checkboxPadding 0 !important;
  position: relative;
  width: $checkboxWidth;

  &:global(.Mui-focusVisible) {
    outline: #fff solid 2px !important;
    outline-offset: 2px;
  }

  &:not(.checked) {
    .checkboxButton {
      background-color: #fff4 !important;
    }
  }

  &.checked {
    .checkboxSwitch {
      margin-left: ($checkboxWidth - $checkboxHeight - ($checkboxBorderWidth * 2)) !important;
    }
  }

  &:disabled {
    .checkboxButton {
      background-color: #9c9c9c;
    }
  }

  span {
    position: relative;
  }
}

.checkboxSwitch {
  display: flex;
  height: 100%;
  margin-left: ((-1 * $checkboxTextWidth) + $checkboxPadding - ($checkboxBorderWidth * 2));
  overflow: unset !important;
  transition-duration: 300ms;
  transition-property: margin-left;
}

.checkboxButton {
  background-color: #9c9c9c;
  border-radius: $checkboxHeight;
  display: block;
  flex-grow: 0;
  height: $checkboxButtonSize !important;
  min-width: auto !important;
  overflow: unset !important;
  padding: 0 !important;
  position: relative;
  transition-duration: 300ms;
  transition-property: background-color;
  width: $checkboxButtonSize !important;
}

.checkboxText {
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: center;
  overflow: unset !important;
  width: $checkboxTextWidth;
}