@import "../../../../assets/scss/mixins.scss";

.timer {
  border: 1px solid #eee;
  border-radius: 4px;
  flex-wrap: nowrap;
  height: 42px;
  max-width: 136px;
  overflow: hidden;
  padding-bottom: 4px;
  position: relative;
  width: 100%;

  &.smallScreen {
    border: none !important;
    border-radius: 0px !important;
    height: 32px !important;
    max-width: unset !important;
    @include applyNotchProperty(padding-left, left, 20px, 20px, true);
    @include applyNotchProperty(padding-right, right, 20px, 20px, true);

    .text {
      margin-bottom: 2px !important;
    }
  }
}

.icon {
  color: #96939F;
  font-size: 20px;
  z-index: 10;
}

.text {
  color: #323036 !important;
  font-size: 18px !important;
  margin-left: 8px !important;
  z-index: 10;

  .colon {
    font-family: Poppins !important;
  }

  .timeSection {
    font-family: monospace !important;
  }
}

.linearProgressBar {
  transition: none !important;
}

.linearProgress,
.linearProgressBg {
  bottom: 0;
  position: absolute !important;
  width: 100% !important;
}

.linearProgressBar {
  &.green {
    background-color: #089A79 !important;
  }

  &.orange {
    background-color: #D99229 !important;
  }

  &.red {
    background-color: #DE5445 !important;
  }
}


.linearProgress {
  background-color: #fff !important;
}

.linearProgressBg {
  background-color: #fff !important;
  height: 100% !important;

  .linearProgressBar {
    opacity: 0.1 !important;
  }
}

.remainingTimeBarContainer {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}