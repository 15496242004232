.container-sCompletedAssessment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f4f7fa;
}

.sCompletedAssessmentContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 550px;
    margin-top: 30px;
    background-color: #ffffff;
}

.container-sAssessmentTitle {
    margin-top: 10px;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #323036;
}

.container-sCompleted {
    margin-left: 5px;
}

.container-sAssessmentStatus {
    font-size: 16px;
    font-weight: normal;
    font-family: "Poppins", sans-serif;
    color: #63606C;
}