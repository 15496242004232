*{
    border: none;
}

.voice-to-text-container {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: none;
    background-color: white;
  }
  
  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border: none;
    background-color: white;
    padding: 10px;
  }
  
  .mic-container {
    width: 150px;
    height: 150px;
    background-color: white;
    border: none;
  }

  .mic-container img {
    width: 100%; /* Ensure the image takes up the full width */
    height: 100%; /* Ensure the image takes up the full height */ /* Ensures the image fits within the container without distortion */
    background-color: white;
  }
  
  .transcript-box {
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 10px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  