@import "../../../../../assets/scss/mixins.scss";

.tabbarContainer {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 5px 1px !important;
  @include applyNotchProperty(padding-left, left, -1px, 0px, true);
  @include applyNotchProperty(padding-right, right, -1px, 0px, true);
  @include applyNotchProperty(padding-bottom, bottom, -1px, 0px, true);
  position: fixed;
  bottom: 0;
  z-index: 200;
  width: 100%;
  &.lightMode {
    background-color: #fff;
  }
  &:not(.lightMode) {
    background-color: #001527;
  }
}
