
/* Make elements with the HTML hidden attribute stay hidden by default */

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-\[-2px\] {
  bottom: -2px;
}

.left-\[-1px\] {
  left: -1px;
}

.right-0 {
  right: 0px;
}

.top-1\/2 {
  top: 50%;
}

.z-10 {
  z-index: 10;
}

.z-\[300\] {
  z-index: 300;
}

.z-\[999\] {
  z-index: 999;
}

.col-span-full {
  grid-column: 1 / -1;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-\[-10px\] {
  margin-top: -10px;
}

.mt-\[-30px\] {
  margin-top: -30px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[64px\] {
  height: 64px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[80px\] {
  height: 80px;
}

.h-full {
  height: 100%;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[190px\] {
  min-height: 190px;
}

.min-h-\[2px\] {
  min-height: 2px;
}

.min-h-\[380px\] {
  min-height: 380px;
}

.min-h-\[40px\] {
  min-height: 40px;
}

.min-h-\[800px\] {
  min-height: 800px;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[75vw\] {
  width: 75vw;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[80px\] {
  width: 80px;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[40px\] {
  min-width: 40px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-\[850px\] {
  max-width: 850px;
}

.max-w-\[900px\] {
  max-width: 900px;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1 1 0%;
}

.grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[36px\] {
  --tw-translate-y: -36px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-center {
  justify-items: center;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-x-1 {
  column-gap: 0.25rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-2 {
  column-gap: 0.5rem;
}

.gap-x-3 {
  column-gap: 0.75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[30px\] {
  border-radius: 30px;
}

.rounded-\[32px\] {
  border-radius: 32px;
}

.rounded-\[50px\] {
  border-radius: 50px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-b-\[30px\] {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.rounded-b-\[60px\] {
  border-bottom-right-radius: 60px;
  border-bottom-left-radius: 60px;
}

.rounded-t-\[60px\] {
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
}

.rounded-tl-\[8px\] {
  border-top-left-radius: 8px;
}

.rounded-tr-\[8px\] {
  border-top-right-radius: 8px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-\[\#0f2922\] {
  --tw-border-opacity: 1;
  border-color: rgb(15 41 34 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-opacity-20 {
  --tw-border-opacity: 0.2;
}

.bg-\[\#009378\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 147 120 / var(--tw-bg-opacity));
}

.bg-\[\#242145\] {
  --tw-bg-opacity: 1;
  background-color: rgb(36 33 69 / var(--tw-bg-opacity));
}

.bg-\[\#2DF8C5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(45 248 197 / var(--tw-bg-opacity));
}

.bg-\[\#474088\] {
  --tw-bg-opacity: 1;
  background-color: rgb(71 64 136 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.\!py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pt-0 {
  padding-top: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[44px\] {
  font-size: 44px;
}

.text-\[6px\] {
  font-size: 6px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-\[700\] {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[1\.25\] {
  line-height: 1.25;
}

.leading-\[27px\] {
  line-height: 27px;
}

.leading-loose {
  line-height: 2;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.text-\[\#2DF8C5\] {
  --tw-text-opacity: 1;
  color: rgb(45 248 197 / var(--tw-text-opacity));
}

.text-\[\#B9CFCA\] {
  --tw-text-opacity: 1;
  color: rgb(185 207 202 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.text-tervGradiant {
  --tw-text-opacity: 1;
  color: rgb(121 77 253 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-20 {
  opacity: 0.2;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.hover\:opacity-75:hover {
  opacity: 0.75;
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 640px) {
  .sm\:-left-10 {
    left: -2.5rem;
  }

  .sm\:-right-10 {
    right: -2.5rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0px;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-\[250px\] {
    height: 250px;
  }

  .sm\:h-\[475px\] {
    height: 475px;
  }

  .sm\:min-h-full {
    min-height: 100%;
  }

  .sm\:w-\[53\%\] {
    width: 53%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-max {
    width: max-content;
  }

  .sm\:max-w-\[320px\] {
    max-width: 320px;
  }

  .sm\:max-w-\[950px\] {
    max-width: 950px;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:rounded-b-\[200px\] {
    border-bottom-right-radius: 200px;
    border-bottom-left-radius: 200px;
  }

  .sm\:rounded-t-\[200px\] {
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
  }

  .sm\:p-0 {
    padding: 0px;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-\[28px\] {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:-mb-3 {
    margin-bottom: -0.75rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:cursor-pointer {
    cursor: pointer;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:gap-1 {
    gap: 0.25rem;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:rounded-\[30px\] {
    border-radius: 30px;
  }

  .md\:rounded-\[50px\] {
    border-radius: 50px;
  }

  .md\:border-2 {
    border-width: 2px;
  }

  .md\:p-2 {
    padding: 0.5rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pl-\[20px\] {
    padding-left: 20px;
  }

  .md\:pl-\[54px\] {
    padding-left: 54px;
  }

  .md\:pt-1 {
    padding-top: 0.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-\[16px\] {
    font-size: 16px;
  }

  .md\:text-\[18px\] {
    font-size: 18px;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .md\:leading-8 {
    line-height: 2rem;
  }
}


