.listItem {
  display: grid;
  gap: 0 8px;
  grid-template-columns: 24px 1fr 1fr 70px;
  grid-template-rows: 24px;
  margin-bottom: 20px;
  width: 100%;
}
.iconContainer,
.primaryInfo {
  height: 100%;
  width: 100%;
}
.iconContainer {
  align-items: center;
  background-color: #f38668;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 0px;
}
.primaryInfo {
  color: #000000;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.progressContainer {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.percentageCompleted {
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 16px;
  justify-content: flex-end;
  padding: 0 6px;
}
@media screen and (min-width: 1080px) and (max-width: 1200px){
  .listItem{
    margin-top: 20px;
  } 
}
