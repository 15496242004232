.card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.titleContainer {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  padding: 16px 12px 20px 12px;
}
.titleIcon {
  align-items: center;
  display: inline-flex;
  height: 26px;
  justify-content: center;
  margin-right: 8px;
  width: 26px;
}
.title {
  color: #24262e;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content {
  display: block;
  flex: 1;
  width: 100%;
  position: relative;
}
