.rangeSlider {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.header {
  background-color: #000;
  padding: 6px 0 !important;
  position: relative !important;
  &,
  * {
    color: #fff !important;
    font-size: 16px !important;
    text-align: center;
  }
  font-family: Poppins;
}

.dropzoneText {
  font-family: Poppins;
  color: #a6a6aa;
  font-size: 18;
}

.uploadNew {
  font-weight: 500 !important;
}
.container {
  width: 20vw;
  height: 10vh;
  border: 3px dashed #dadada;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    transform: translateY(-50%) scale(1.5);
  }
}

.sliderMain {
  width: 350px;
}
.imgIcon {
  float: left;
}

.imgIcon,
.imgIconLeft {
  margin-top: 5px;
}
.imgIconLeft {
  float: right;
}

.dropZoneContainer {
  display: flex;
  align-items: center;
}
.sliderWidth {
  width: 40%;
  margin-left: 15px;
  margin-right: 15px;
}

.diffImage,
.uploadButton {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.uploadButton {
  width: 10em !important;
}

.mainWrapper {
  box-sizing: border-box;
  padding-bottom: 25px;
}

.loader {
  color: #fff !important;
}
.orLine {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.orLine {
  font-size: 15px;
  font-weight: 500;
  display: flex;

  small {
    height: 1px;
    background-color: rgb(228, 222, 222);
  }
  .small1 {
    width: 35%;
  }
  .small2 {
    width: 37%;
  }
}
.gridSystem {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px 0px;
  place-items: center;
  padding: 15px 10px 15px 10px;
  img {
    text-align: center;
    padding: 3px;
    cursor: pointer;
    width: 150px;
    height: 150px;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
      0px 10px 32px -4px rgba(24, 39, 75, 0.3);
    &:hover {
      background-color: #784df5;
    }
  }
}
