@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.gridLogin {
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: rtl;
}
.flexForm {
  display: flex;
  flex-direction: column;
}
.left{
  order: 1;
  width:100%;
  background-color: #5138ed;
  height: 450px;
}
// .center{
//   order: 2;
//   width:50%;
//   background-color: green;
// }

.loading {
  display: flex;
  justify-content:'center';
  margin-top:15%;
  flex-direction: column;
  align-items: center;
}

.flexLogo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1;
}
.mobileflexLogo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  // z-index: 1;
}


.sideBanner {
  background-image: url("./GroupLogin.svg");
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  
}
.MobSideBanner {
  background-image: url("./Mobileban.svg");
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  
}


.sideBanner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #5138ed;
  top: 0;
  left: 0;
  z-index: 0;
}

.loginButton {
  border-radius: 6px;
  border: 1px solid #784df6;
  background: #784df6;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.MobloginButton {
  border-radius: 2px;
  border: 1px solid #784df6;
  background: #5138ED;
  color: #ffffff;
  font-size: 12px;
  // font-weight: bold;
  padding: 15px 55px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
// .inputFieldValidation {
// }

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

input {
  background-color: #eee;
  border: 1px solid #eaeaea;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  min-height: 480px;
}