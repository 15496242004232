.course-overview .MuiTypography-body1 {
  font-family: "Poppins", sans-serif !important;
}

/* .course-container .MuiPaper-root{
  box-shadow: none !important;
} */
.course-overview {
  background-color: white !important;
  flex: 1 1 0%;
}
.course-overview .fullscreen {
  background-color: white !important;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
}
.root {
  flex-basis: "60.33%";
}
.course-overview
  .MuiTypography-root
  .MuiTypography-body1
  .MuiTypography-colorTextPrimary {
  font-family: "Poppins", sans-serif !important;
}
.duration {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
}
.hours-svg {
  margin-bottom: 4px;
  margin-right: 3px;
}

.course-overview .MuiButton-root {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
}

.bread-prepare {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* position: relative;
  top: 5px; */
  color: white;
}

.breadcrumb-content {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
  /* position: relative;
  top: 5px; */
}
.video-player .MuiTypography-colorTextPrimary {
  color: white !important;
  font-size: 14px;
}
.video-player .MuiBreadcrumbs-separator {
  display: flex;
  margin-left: 8px;
  user-select: none;
  margin-right: 8px;
  color: white !important;
}

.list-inline {
  display: flex !important;
}

.price {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
}

.course-banner {
  padding: 64px 32px 32px 16px;
  min-height: 100%;
}

.course-banner .prepare-desc {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  width: 400px;
  color: #ffffff;
}

.course-banner .prepare {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #d8d6db;
  width: 90%;
  position: relative;
  /* overflow: auto; */
}
.banner-rating {
  position: relative;
  left: 8px;
}
.small-font {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
}
/* .banner-language {
} */
.banner-language .lang {
  position: relative;
  left: 10px;
  top: 2px;
  text-transform: capitalize;
}
.course-banner ul {
  margin-top: 16px;
  margin-left: 8px;
}

.course-banner ul li {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  font-style: normal;
  font-family: "Poppins";
}
.course-banner .bull {
  margin: 8px;
}

@media (max-width: 480px) {
  .course-banner .bull {
    margin: 0px;
  }
}

@media (max-width: 600px) {
  .buy-button {
    position: relative;
    left: 60px;
  }
  .cart-button {
    position: relative;
    left: 30px;
    top: 10px;
  }
  .share-button {
    position: relative;
    left: 40px;
    top: 10px;
  }
  .requirement-card {
    padding: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .course-banner {
    position: relative;
    top: 0px !important;
    text-align: left;
  }
  .share-button {
    margin-top: 5px !important;
  }
  .video-player {
    width: 100% !important;
  }
  /* .MuiGrid-grid-sm-7 {
  flex-grow: 0;
  max-width: 100% !important;
  flex-basis: 100% !important;
}
.MuiGrid-grid-sm-5 {
  flex-grow: 0;
  max-width: 100% !important;
  flex-basis: 100% !important;
} */
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  /* .course-banner {
    height: 700px;
  } */

  .buy-button {
    position: relative;
    left: 0px;
  }
  .cart-button {
    position: relative;
    left: 0px;
    top: 0px;
  }
  .share-button {
    position: relative;
    left: 0px;
    top: 10px;
  }
}
@media only screen and (min-width: 768px) {
  .buy-button {
    position: relative;
    right: 0px;
  }
  .cart-button {
    position: relative;
    right: 0px;
  }
}
@media (max-width: 600px) {
  .course-banner {
    top: 0;
  }
}
/* @media (min-width:600px)  */

.buy-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 8px 120px; */
  position: relative;
  left: 10px;
  color: #ffffff !important;
  width: 200px !important;
  height: 40px !important;
  background: #794df5 !important;
  border-radius: 3px !important;
}

.cart-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  position: relative;
  color: #ffffff !important;
  width: 200px !important;
  height: 40px !important;
  border-radius: 3px !important;
  border: 1px solid #ffffff !important;
  left: 10px;
  text-transform: none !important;
}
.share-button {
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff !important;
  box-sizing: border-box;
  border-radius: 3px !important;
}
.share-button:hover {
  color: #ffffff !important;
  background: #794df5 !important;
  border: none;
}
.copied-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  position: relative;
  color: #ffffff !important;
  width: 200px !important;
  height: 40px !important;
  border-radius: 3px !important;
  border: 1px solid #ffffff !important;
  left: 10px;
  text-transform: none !important;
}

.center-align,
.center-align-requirement {
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
}
.center-align ul,
.center-align-requirement ul {
  list-style: none;
  padding-left: 0;
}
.center-align ol,
.center-align-requirement ol {
  list-style: none;
  padding-left: 0;
}
.center-align ol li::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  background-image: url("../../assets/sccess.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.center-align ul li::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  background-image: url("../../assets/sccess.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.center-align > p {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin: 16px 0 !important;
  font-size: 15px;
  line-height: 1.8;
}
.center-align > p::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../../assets/sccess.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10px;
  margin-top: 8px;
}
.center-align div::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  background-image: url("../../assets/sccess.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.center-align-requirement ul li::before {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url("../../assets/orange-dot.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.center-align-requirement ol li::before {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url("../../assets/orange-dot.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.center-align-requirement > p {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin: 16px 0 !important;
  font-size: 15px;
  line-height: 1.8;
}
.center-align-requirement > p::before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 16px;
  background-image: url("../../assets/orange-dot.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 6px;
  margin-top: 8px;
}
.center-align-requirement div::before {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url("../../assets/orange-dot.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.outcome-card {
  background-color: #ecfff2 !important;
  padding: 16px;
  border: 1px solid #f2f1f3;
}
.outcomes-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #19181b;
  margin-bottom: 25px;
}

.requirement-card {
  background-color: #fff6e6 !important;
  padding: 16px;
  border: 1px solid #f2f1f3;
}

/* .MuiCard-root {
  overflow: none !important;
} */

@media (min-width: 1025px) {
  .video-player {
    /* margin: 60px; */
  }
}

@media (min-width: 1140px) {
  .buy-button {
    position: relative;
    right: 20px;
  }
  .cart-button {
    position: relative;
    right: 10px;
  }
}

@media (min-width: 1200px) {
  .course-container {
    max-width: 1300px !important;
  }
  .outcome-card {
    margin-top: 48px;
  }
  .requirement-card {
    margin-top: 48px;
  }
}
/* .react-player .react-player-big-play-button {
  top: % !important;
  left: 45% !important;
} */

.course-banner .course-premium {
  position: relative;
  background: linear-gradient(90deg, #870000 0%, #190a05 100%);
  border-radius: 5px;
  width: 105px;
  height: 28px;
}
.course-banner .premium-detail {
  position: relative;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  padding: 5px;
  left: 10px;
  bottom: 5px;
  height: 20px;
  letter-spacing: 0.01em;
  background: linear-gradient(90deg, #870000 0%, #190a05 100%);
  border-radius: 5px;
  width: 105px;
  height: 28px;
  color: #ffffff !important;
}

.explore-button {
  width: auto;
  border: 2px solid #1ba94c !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 90px !important;
  color: #1ba94c !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: auto;
  position: relative;
  top: 30px;
}

.course-desc p {
  font-size: 14px;
}

.cards {
  border-radius: 5px;
  background-color: #fff;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 0px 25px rgba(170, 170, 170, 0.25);
  border-radius: 10px;
}
.rating-box {
  width: 130px;
  height: 130px;
  margin-right: auto;
  margin-left: auto;
  /* background-color: #FBC02D; */
  color: #fff;
}

.rating-label {
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
}

.rating-bar {
  width: 300px;
  padding: 8px;
  border-radius: 5px;
}
.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 5px;
}

.bar-5 {
  width: 70%;
  height: 13px;
  background-color: #794df5;
  border-radius: 20px;
}

.bar-4 {
  width: 30%;
  height: 13px;
  background-color: #794df5;
  border-radius: 20px;
}

.bar-3 {
  width: 20%;
  height: 13px;
  background-color: #794df5;
  border-radius: 20px;
}

.bar-2 {
  width: 10%;
  height: 13px;
  background-color: #794df5;
  border-radius: 20px;
}

.bar-1 {
  width: 0%;
  height: 13px;
  background-color: #794df5;
  border-radius: 20px;
}

td {
  padding-bottom: 10px;
}

.star-active {
  color: #fbc02d;
  margin-top: 10px;
  margin-bottom: 10px;
}

.star-active:hover {
  color: #f9a825;
  cursor: pointer;
}

.star-inactive {
  color: #cfd8dc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blue-text {
  color: #0091ea;
}

.content {
  font-size: 14px;
  line-height: 23px;
  color: #323036;
  font-style: normal;
  font-weight: normal;
}

.profile-pic {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin-right: 30px;
}

.pic {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.vote {
  cursor: pointer;
}

.main-rating {
  position: relative;
  height: 66px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 74px;
  line-height: 66px;
  color: #794df5;
}

.sec-rating {
  position: relative;
  height: 23px;
  left: 20px;
  top: 30px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: #7c7887;
}

.course-overview .MuiCardContent-root {
  padding: 16px !important;
  position: relative;
}

.list-group-item {
  background-color: #f8f8fb !important;
}

/* iframe{
  width : 100%
 } */

.content-min {
  margin-right: 70px;
}
@media (max-width: 480px) {
  .content-min {
    margin-right: 0px;
  }
}

.acc-min {
  /* margin-left: 180px !important; */
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
}

.course-heading {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #323036;
}

/* tab design css */
.course-overview .MuiAccordionSummary-root {
  height: 60px !important;
}
.course-overview .MuiTabs-root {
  background: white !important;
  border-bottom: 1px solid #d9d9d9;
}

.course-overview .PrivateTabIndicator-root-52 {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: relative;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.course-overview .MuiTab-textColorPrimary.Mui-selected {
  color: #1ba94c !important;
  background: #dbffe7 !important;
}
.course-overview button:focus {
  outline: none !important;
  outline: none !important;
}
.course-overview .MuiTabs-indicator {
  background-color: #1ba94c !important;
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
  height: 4px !important;
}
.course-overview .PrivateTabIndicator-colorPrimary-35 {
  background-color: #1ba94c !important;
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
  height: 4px !important;
}
.course-overview .PrivateTabIndicator-colorPrimary-41 {
  background-color: #1ba94c !important;
}
.course-overview .PrivateTabIndicator-root-34 {
  width: 100%;
  bottom: 0px;
  height: 4px !important;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.course-overview .MuiTab-wrapper {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
}

.price-wraper {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.price-wraper h2 {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
}
.price-wraper p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-decoration-line: line-through;
  color: #e8e8e8;
  padding: 0 20px;
}
.price-wraper span {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #d8d6db;
}
.sec-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #323036;
  margin-bottom: 20px;
}
.instructor-details {
  padding-top: 20px;
  padding-bottom: 20px;
}
.instructor-details p {
  font-size: 16px;
  line-height: 1.8;
}
p.prepareParagraph {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625;
  margin: 16px 0;
}
.buyImage {
  height: 400px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.buyImageExternal {
  height: 400px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}
.mainExternal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  padding-top: 60px;
  background-color: #20242d;
}
.secondaryExternal {
  position: relative;
  width: 100%;
  height: 100%;
}
.course-External {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #20242d;
}
.containerExternal {
  overflow: hidden;
  overflow-y: auto;
  min-height: 100%;
}

.innerHtml {
  font-size: 16px;
  line-height: 1.8;
}

.innerHtml blockquote {
  display: block;
  margin-block-start: 1__qem;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.innerHtml strong,
.innerHtml b {
  font-weight: bold;
}

.innerHtml i,
.innerHtml address {
  font-style: italic;
}

/* heading elements */

.innerHtml p {
  margin-block-start: 1.33__qem;
  margin-block-end: 1.33em;
}

.innerHtml h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67__qem;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
}

.innerHtml h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83__qem;
  margin-block-end: 0.83em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
}

.innerHtml h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1__qem;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
}

.innerHtml h4 {
  display: block;
  margin-block-start: 1.33__qem;
  margin-block-end: 1.33em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
}

.innerHtml h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67__qem;
  margin-block-end: 1.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
}

.innerHtml h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33__qem;
  margin-block-end: 2.33em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
}

/* tables */
.innerHtml table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}

.innerHtml .table table td {
  border: 1px solid #bfbfbf !important;
}

.innerHtml thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.innerHtml tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.innerHtml tfoot {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
}

/* for tables without table section elements (can happen with XHTML or dynamically created tables) */
.innerHtml table > tr {
  vertical-align: middle;
}

.innerHtml col {
  display: table-column;
}

.innerHtml colgroup {
  display: table-column-group;
}

.innerHtml tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.innerHtml td,
.innerHtml th {
  display: table-cell;
  vertical-align: inherit;
}

.innerHtml th {
  font-weight: bold;
}

.innerHtml caption {
  display: table-caption;
  text-align: center;
}

/* lists */

.innerHtml ul,
.innerHtml menu,
.innerHtml dir {
  display: block;
  list-style-type: disc;
  margin-block-start: 1__qem;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

.innerHtml ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1__qem;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

.innerHtml li {
  display: list-item;
  text-align: match-parent;
}

.innerHtml ::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  white-space: pre;
  text-transform: none;
}

.innerHtml ul ul,
.innerHtml ol ul {
  list-style-type: circle;
}

.innerHtml ol ol ul,
.innerHtml ol ul ul,
.innerHtml ul ol ul,
.innerHtml ul ul ul {
  list-style-type: square;
}

.innerHtml a:any-link {
  color: blue;
  text-decoration: underline;
  cursor: auto;
}

.innerHtml a:any-link:active {
  color: red;
}
