.card {
  align-items: center;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 16px;
  position: relative;
  width: 100%;
  &.green {
    background-color: #28b505;
    .place {
      background-color: #009a06;
    }
  }
  &.darkViolet {
    background-color: #4f1271;
    .place {
      background-color: #6b00a7;
    }
  }
  &.maroon {
    background-color: #832161;
    .place {
      background-color: #b303b7;
    }
  }
  &.red {
    background-color: #e22b12;
    .place {
      background-color: #ff6854;
    }
  }
  &.violet {
    background-color: #be00ee;
    .place {
      background-color: #e167ff;
    }
  }
  &.blue {
    background-color: #024bb9;
    .place {
      background-color: #6489bf;
    }
  }
}
.badge {
  opacity: 0.25;
  position: absolute;
  right: 0px;
  top: 4px;
}
.photo {
  border-radius: 50%;
  height: 44px;
  margin-bottom: 10px;
  width: 44px;
}
.name {
  color: #efe9e9;
  font-size: 16px;
  font-weight: 500;
}
.name,
.college,
.deptYear,
.questions,
.pts {
  display: inline-block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.place {
  border-radius: 32px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 24px;
}
.college,
.deptYear {
  color: #ececec;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 4px;
}
.questions,
.pts {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0;
}
