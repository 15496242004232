@import "./Home.scss";

.content__header__container {
  margin-top: 80px;
  margin-bottom: 3.5rem;
}

.content__header__title_wrap,
.content__header__description {
  text-align: center;
}

.content__header__title_wrap {
  margin: 0;
}

.content__header__title,
.content__header__sub {
  font-weight: 600;
  font-size: 28px;
}

.content__header__title {
  color: $TervPrimary;
}

.content__header__sub {
  color: $TextDGray;
}

.content__header__description {
  font-size: 18px;
  color: $TextLGray;
}
