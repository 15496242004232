.title {
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #3a3a3a !important;
  text-align: center;
}

.publishP1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  margin: 0;
  padding: 0;
  color: #262626;
}

.dialogContent {
  max-width: 25rem !important;
}

.publishP2 {
  font-size: 14px;
  line-height: 23px;

  /* or 164% */
  text-align: center;

  color: #5b5a5f;
}

.publishFile {
  background-color: #f8f7f7;
  border-radius: 5px;
  padding: 0.575rem;
}

.publishContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.cancelButton,
.publishButton,
.saveButton {
  border-radius: 3px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Poppins" !important;
  text-transform: none !important;
  width: 9.375rem !important;
  margin-bottom: 1rem !important;
}

.cancelButton {
  background-color: #ececec !important;
  color: #504f51 !important;
}

.publishButton {
  background-color: #794df5 !important;
  margin-left: 1rem !important;
}

.saveButton {
  border: 1px solid #1ba94c !important;
  color: #1ba94c !important;
  margin-left: 1rem !important;
}

.markList {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.markItem {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: 22px;

  color: #3a3a3a;
}
