.pageHeader {
  margin-bottom: 36px;
  padding: 0;
  position: relative;
  width: 100%;
}
.content {
  align-items: left;
  display: flex;
  flex-direction: row;
}
.title {
  color: #323036;
  font-size: 24px;
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.searchBar {
  align-items: center;
  background-color: #fff;
  border: 1px solid #bababa;
  border-radius: 5px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  display: inline-flex;
  flex-grow: 1;
  height: 40px;
  margin-left: 0;
  max-width: 460px;
  overflow: hidden;
  position: relative;
}
.searchBarInput {
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  display: block;
  font-size: 15px;
  height: 100%;
  line-height: 1;
  outline: none !important;
  outline-offset: -1px;
  outline-width: 2px;
  padding-left: 50px;
  position: absolute;
  width: 100%;
  z-index: 2;
  &::placeholder {
    color: #a6a6a6;
  }
}
