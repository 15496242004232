.header {
  background-color: #000;
  padding: 6px 0 !important;
  position: relative !important;
  &,
  * {
    color: #fff !important;
    font-size: 16px !important;
    text-align: center;
  }
  font-family: Poppins;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    transform: translateY(-50%) scale(1.5);
  }
}

.randomPopover {
  padding: 10px;
  border-top: 5px solid #794df5;
}
.randomPopoverRoot {
  margin-top: 10px;
}
.tabsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.accItem {
  font-family: "Poppins" !important;
}

.tabButton {
  width: 240px;
  height: 47px;
  border: 0px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 17px;
  color: #794df5;
  background-color: #fff;
}

.tabButtonActive {
  color: #fff;
  background-color: #794df5;
}
.tabSettings {
  display: flex;
  justify-content: space-around;
  margin-top: 17px;
}

.divider {
  margin-left: 10px;
  margin-right: 10px;
}

.tabFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 17px;
}

.searchBarWrapper {
  width: 60%;
}

.filterWrapper {
  margin-right: 70px;
}

.filterWrapperQB {
  margin-right: 40px;
}

.filterButton {
  border: 1px solid black;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
}

.filterIcon {
  float: left;
}

.filterInnerButton {
  border: 0;
  background: none;
  font-size: 15px;
  font-weight: 500;
}

.clearButton {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #0377D1;
  cursor: pointer;
  margin-left: 5rem;
  margin-top: 1rem;
}

.circularProgressBar {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 28rem;
}