.card {
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
    0px 10px 32px -4px rgba(24, 39, 75, 0.1) !important;
  width: 18.125rem !important;
  height: 21.25rem !important;
  border-radius: 5px !important;
}
.price {
  font-size: 21px;
  color: black;
  font-weight: bold;
}

.cardContainter {
  display: flex;
  justify-content: center;
  gap: 2rem;
  min-height: 70vh;
}
.relatedInfo {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #794df5;
}

.cardMedia {
  height: 9rem !important;
}

.cardTitle {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

.courseTagName {
  position: absolute;
  right: 10px;
  top: 10px;
}
