.main {
  display: flex;
  flex-direction: column;
}

.codingTab {
  font-size: 1rem;
  font-weight: 600;
  color: #404040;
  padding: 0.8rem;
  cursor: pointer;
}

.codingFlex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.outputTab {
  font-size: 1rem;
  font-weight: 600;
  padding: 0.8rem;
  cursor: pointer;
  background-color: #deffe9;
  color: #03832f;
  width: 10rem;
  border-bottom: 4px solid #1BA94C;
}

.codingArea,
.outputArea {
  border: 1px solid #eeeeee;
  height: 20rem;
  background-color: #f7f7f7;
  padding: 1rem;
  font-size: 1rem;
  overflow: auto;
}

.runCode {
  width: 10rem !important;
  margin: 1rem 0.5rem !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
}

.codingTabs {
  display: flex;
  gap: 0.5rem;
}

.codingTabSelected {
  background-color: #deffe9;
  color: #03832f;
  border-bottom: 4px solid #1BA94C;
}
