.card {
  border-radius: 10px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  font-family: Poppins, sans-serif;
  height: 400px;
  max-width: 320px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.08);
}
.assessmentStyle {
  &,
  * {
    background-color: transparent !important;
    color: #000 !important;
    font-family: Poppins !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    list-style: none !important;
    margin: 0px !important;
    margin-right: 10px !important;
    padding: 0px !important;
    text-decoration: none !important;
  }
}

.imageContainer {
  height: 130px;
  position: relative;
  width: 100%;
}

.image,
.imageOverlay {
  height: 100%;
  position: absolute;
  width: 100%;
}

.image {
  object-fit: cover;
  object-position: center;
}

.imageOverlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 130px);
  min-height: 260px;
  padding: 12px 18px 14px 18px;
}

.title {
  color: #000000;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
  // overflow: hidden;
  max-height: 3.3rem;
}

.description {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin: 12px 0 0 0;
  margin-bottom: 16px;
  // max-height: 100px;
  // overflow: hidden;
  list-style: none !important;
  text-decoration: none !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  & p strong {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    margin: 12px 0 0 0;
    margin-bottom: 16px;
    // max-height: 100px;
    // overflow: hidden;
    list-style: none !important;
    text-decoration: none !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.button {
  border-radius: 3px !important;
  border-width: 2px !important;
  font-weight: 500 !important;
  margin-top: 12px;

  &.expired {
    opacity: 0.35 !important;
  }

  &:disabled {
    opacity: 1;
  }
}

.spacer {
  flex-grow: 0;
  margin: auto;
}

.featuredTag,
.premiumTag,
.latestTag,
.specialTag {
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
}

.featuredTag {
  background: linear-gradient(90deg, #ff512f 0%, #f09819 100%);
}

.premiumTag {
  align-items: center;
  background: linear-gradient(90deg, #870000 0%, #190a05 100%);
  display: inline-flex;

  svg {
    margin-top: -2px;
  }
}

.latestTag {
  background: linear-gradient(90deg, #cc2b5e 0%, #753a88 100%);
}

.specialTag {
  background: linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
}

.tryButton {
  position: relative;

  .freeStar {
    color: #fff !important;
    right: -12px;
    top: -12px;
    width: 32px;

    &,
    &::before,
    &::after {
      background-color: #1ba94c;
      border-radius: 2px;
      height: 26px;
      position: absolute;
      width: 26px;
    }

    &::before {
      transform: rotateZ(27.5deg);
    }

    &::after {
      transform: rotateZ(-27.5deg);
    }

    &::before,
    &::after {
      content: " ";
      display: block;
      left: 0;
      top: 0;
    }
  }

  .freeStarText {
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    position: relative;
    text-align: center;
    z-index: 2;
  }
  
}
