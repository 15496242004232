.container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding-top: 4%;
    width: 100%;
  }
  
  .container2 {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  }
  
  .containerDisabled {
    opacity: 0.3;
  }
  
  .header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    max-height: 48px;
  }
  
  .header2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    max-height: 48px;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
  }
  .headerTextContainer {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  
  .headerTextContainer2 {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .titleContainer {
    align-items: center;
    display: flex;
    height: 44px;
    justify-content: space-between;
    :global(.MuiInputBase-root) {
      :global(fieldset) {
        border: 1px solid #cfcfcf !important;
        border-radius: 5px !important;
      }
    }
  }
  .title {
    color: #24262e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 1.75;
    margin: 0;
    strong {
      font-weight: 500 !important;
    }
  }
  
  .title2 {
    color: #24262e;
    font-size: 16px;
    // width: 360px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 1.75;
    margin: 0;
    strong {
      font-weight: 500 !important;
    }
  }
  .description {
    color: #000000;
    flex-shrink: 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 27px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .actionBoxContainer {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .actionBoxContainer2 {
    float: right;
  }
  
  .actionBoxContainerCenter {
    align-items: center;
    display: flex;
    justify-content: center;
    // margin-right: 5.5rem;
  }
  .actionBoxContainerCenter2 {
    float: right;
  }
  .content {
    flex: 1;
    margin: 0;
    // overflow: hidden;
  }
  
  .content2 {
    flex: 2;
    margin: auto;
    width: 90%;
    padding-top: 5%;
  }
  
  .footer {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .footer2 {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: -2%;
  }
  .footerLink {
    background-color: transparent;
    border: none;
    color: #0039ff;
    font-size: 16px;
    text-decoration: underline;
  }
  .footerLink2 {
    background-color: transparent;
    border: none;
    color: #0039ff;
    font-size: 16px;
    text-decoration: underline;
    padding-bottom: 2%;
  }