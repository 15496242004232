.buttonYes {
    background-color: #794DF5 !important;
    font-family: Poppins !important;
    color: #fff !important;
  }
  

  
  .dialogActions {
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 20px !important;
  }
  
  .dialogMain {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  .dialogPara {
    font-size: 15;
    font-weight: 500;
    color: "#5B5A5F";
    margin-top: 12;
    text-align: "center";
  }

  