.canvas {
  align-items: center;
  display: flex;
  min-height: 100%;
  justify-content: center;
  position: relative;
}
.content {
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  overflow: auto !important;
  padding: 8px 12px 12px 12px;
}
.toolbar {
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  height: 40px !important;
  justify-content: space-between;
  min-height: auto !important;
  padding: 0 !important;
}
