@import "../../../assets/scss/mixins.scss";

.page {
  display: flex;
  flex: 1;
  min-height: 100%;
  position: relative;
  user-select: none;

  &.lightTheme {
    background-color: #e5e5e5;
  }

  &.darkTheme {
    background-color: #363876;
  }
}

.body {
  flex: 1;
  position: relative;
  width: 100%;

  &.smallScreen {
    margin-top: 50px;
    min-height: calc(100% - 50px);
  }

  &.mediumScreen,
  &.largeScreen {
    display: grid;
    gap: 16px;
    margin-top: 60px;
    padding-top: 20px;
    @include applyNotchProperty(padding-bottom, bottom, 20px, 20px, true);
    @include applyNotchProperty(padding-left, left, 20px, 20px, true);
    @include applyNotchProperty(padding-right, right, 20px, 20px, true);
  }

  &.mediumScreen {
    grid-template-columns: 1fr;
  }

  &.largeScreen {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: calc(100vh - 60px);
  }
}

.tabFill {
  @include applyNotchProperty(padding-bottom, bottom, -1px, 0px, true);
  width: 100%;
}

.tabFillContent {
  height: 64px;
  width: 100%;
}

.submissionMessage {
  @include applyNotchProperty(padding-left, left, 8px, 8px, true);
  @include applyNotchProperty(padding-right, right, 8px, 8px, true);
  @include applyNotchProperty(padding-bottom, bottom, 8px, 8px, true);
}

.cardGroup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  padding-left: 10%;
}
.cardGroupMobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  padding-left: 10%;
}

.assessment{
  padding-left: 10px;
  padding-top: 1rem;
  height: 9rem;
  width: 20rem;
  border-radius: 4px;
  padding-right: 10px;
}
.questionHeading {
  width:200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.assessmentDuration {
  background-color: #F2F1F3;
  padding: 5px;
  border-radius: 6px;
}
.cardGroupContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.cardGroupsContainer {
  background-color: #fff;
  margin-bottom: 32px;
  width: 100%;

  &:not(.groupedByCategory) {
    .topicTag {
      display: none !important;
    }
  }
}