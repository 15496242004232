.ribbon {
  background-color: #51b960;
  color: white;
  height: 30px;
  align-items: center;
  max-width: 90%;
  font-size: 15px;
  display: inline-flex;
  min-width: 60%;
  justify-content: flex-start;
  place-items: center;
  padding: 0 8px 0 8px;
  position: relative;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    whitespace: nowrap;
    display: inline-block;
    width: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    right: -18px;
    height: 0;
    width: 0;
    border-style: solid;
    border-width: 15px 0 15px 19px;
    border-color: #51b960 transparent;
    transform: rotate(179deg);
  }
}
