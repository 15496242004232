@import "./Home.scss";

.content__header__container {
  margin-top: 80px;
  margin-bottom: 3.5rem;
}

.content__header__title_wrap,
.content__header__description {
  text-align: center;
}

.content__header__title_wrap {
  margin: 0;
}

.content__header__title,
.content__header__sub {
  font-weight: 600;
  font-size: 28px;
}

.content__header__title {
  color: $TervPrimary;
}

.content__header__sub {
  color: $TextDGray;
}

.content__header__description {
  font-size: 18px;
  color: $TextLGray;
}

.main {
    background-color: #fcfcfc;
    margin: 1rem auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
  }
  
  @import "./Home.scss";
  
  .content__header__container {
    margin-bottom: 3.5rem;
  }
  
  .content__header__title_wrap,
  .content__header__description {
    text-align: center;
  }
  
  .content__header__title_wrap {
    margin: 0;
  }
  
  .content__header__title,
  .content__header__sub {
    font-weight: 600;
    font-size: 28px;
  }
  
  .content__header__title {
    color: $TervPrimary;
  }
  
  .content__header__sub {
    color: $TextDGray;
  }
  
  .content__header__description {
    font-size: 18px;
    color: $TextLGray;
  }
  
  
  .card {
    background: #ffffff;
    box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
      0px 12px 42px -4px rgba(24, 39, 75, 0.12);
    border-radius: 10px;
    width: 21.875rem;
    margin-bottom: 30px;
  }
  
  @media (max-width: 960px) {
    .card {
      width: 17.575rem;
    }
  }
  
  .desc {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #323036;
    margin: 1.375rem 1.25rem;
  }
  
  .authorInfo {
    height: 7.5rem;
    background: #1ba94c;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      height: 3rem;
      width: 3rem;
      border-radius: 1.5rem;
      margin-top: -1.5rem;
      margin-bottom: 1rem;
    }
  }
  
  .authorName {
    // font-weight: 600;
    font-size: 12px;
    // line-height: 1.6875rem;
    margin: 0;
    margin-bottom: 0.25rem;
  }
  
  .head {
    // font-family: Inter;
  font-size: 47.02px;
  font-weight: 600;
  line-height: 56.9px;
  text-align: left;
  color: #ffffff;
  }
  
  .para {
  font-size: 20.57px;
  font-weight: 500;
  line-height: 33.8px;
  text-align: left;
  color: #ffffff;
  }
  
  .authorDesig {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #111111;
    margin: 0;
  }
  
