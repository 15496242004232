@import "../../../../../assets/scss/mixins.scss";

$smallScreenSectionHeaderHeight: 40px;
$smallScreenPageHeaderHeight: 50px;
$smallScreenTabBarHeight: 64px;

.languageSelectorInput {
  :global(.MuiFilledInput-underline) {
    background-color: transparent !important;
    border-radius: 3px !important;

    &::after,
    &::before {
      display: none !important;
    }
  }

  :global(.MuiSelect-icon) {
    color: #000 !important;
  }
}

.languageSelector {
  background-color: #fffc !important;
  border-radius: 3px !important;
  color: #000 !important;

  p {
    font-size: 14px !important;
    font-weight: 500 !important;
    height: 100% !important;
    line-height: 2.2 !important;
  }
}

.codeEditor {
  height: 100%;

  &,
  * {
    user-select: text !important;
  }
}

.languageIcon {
  height: 18px !important;
  width: 18px !important;

  * {
    color: currentColor !important;
    fill: currentColor !important;
  }
}