.container {
  border: 1px dashed #a6a6aa;
  border-radius: 3px;
  width: 29.375rem;
  padding: 1rem;
  text-align: center;

  & > p {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    text-align: center;
    color: #a6a6aa;
    margin: 0;
  }
}

.maxSize {
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  color: #cbcbd4;
  margin-top: 0.5rem;
  margin-bottom: 1.125rem;
}

.uploadedStyle {
  display: flex;
  max-height: 6.25rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  font-weight: 500;
  border-left: 5px solid #1ba94c;
  border-radius: 5px;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-align: center;
  color: #a6a6aa;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    cursor: pointer;
  }
}

.toast__success,
.toast__error {
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  font-family: "Poppins";
}

.toast__success {
  background-color: #1ba94c;
}

.toast__error {
  background-color: #d42216;
}
