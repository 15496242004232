#profile-page { flex: 1 1 0%; width: 100%; min-height: 100%; display: flex; }
#profile-page.dropmenu-shadow {box-shadow: rgba(0, 0, 0, 0.08) 0px 15px 25px, rgba(0, 0, 0, 0.04) 0px 5px 10px;}
#profile-page .dropmenu-container {border-radius: 4px;overflow: hidden;}
#profile-page .profile-container { display: flex; flex-direction: column; width: 100%; max-width: 1200px; min-height: 100%; margin: 0 auto; background-color: #ffffff; }
#profile-page .profile-hero { width: 100%; min-height: 136px; position: relative; overflow: hidden; z-index: 0; }
#profile-page .profile-hero .hero-img { width: 100%; height: 100%; background-size: cover; background-repeat: no-repeat; }
#profile-page .profile-hero .hero-img.blur { filter: blur(4px); -webkit-filter: blur(4px);}
#profile-page .profile-body { padding: 0 64px; z-index: 1; }
#profile-page .profile-header { width: 100%; margin-top: -80px; display: flex; }
#profile-page .profile-image { width:104px; height:104px; border: 2px solid #ffffff; border-radius: 50%; overflow: hidden; margin: 0 auto; background-color: #f8f8fb; box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;}
#profile-page .profile-image img { width:100%; height:100%; background-position: center; }
#profile-page .profile-info { text-align: center; margin-top: 8px; min-height: 72px; }
#profile-page .profile-info h3 { color: #000000; font-weight: 600; font-size: 20px; }
#profile-page .profile-info p { color: #3A3A3A; font-size: 16px; }
#profile-page .flex-box { display: flex; }
#profile-page .flex-col { flex-direction: column; }
#profile-page .flex-split { border-left: 1px solid #DFE1E6; min-height: 32px; margin: 0 8px 0 4px; }
#profile-page .flex-gap-1by2 { gap: 4px; }
#profile-page .flex-gap-1 { gap: 8px; }
#profile-page .flex-gap-2 { gap: 16px; }
#profile-page .flex-gap-3 { gap: 24px; }
#profile-page .flex-gap-4 { gap: 32px; }
#profile-page .flex-wrap { flex-wrap: wrap; }
#profile-page .flex-100 { width: 100%; }
#profile-page .flex-50 { width: 50%; }
#profile-page .flex-1 { flex: 1 1 0% }
#profile-page .flex-2 { flex: 2 1 0% }
#profile-page .flex-3 { flex: 3 1 0% }
#profile-page .flex-4 { flex: 4 1 0% }
#profile-page .flex-bottom { align-items: flex-end; }
#profile-page .flex-middle { align-items: center; }
#profile-page .flex-center { justify-content : center; }
#profile-page .flex-right { justify-content : flex-end; }
#profile-page .flex-self-center { justify-self: center; }
#profile-page .center { text-align: center; }
#profile-page .auto { margin: 0 auto; }
#profile-page .br { border-top: 1px solid #E3F2FF; margin: 0 0 16px 0;}
#profile-page .profile-content { width: 100%; }
#profile-page .profile-item { margin: 16px 0 32px 0; }
#profile-page .profile-item h5 { color: #000000; font-size: 16px; font-weight: 600; }
#profile-page .profile-item p { color: #3A3A3A; font-size: 18px; margin-bottom: 0; line-height: 1.5;}
#profile-page .profile-feature-item { margin: 32px 0; }
#profile-page .profile-feature-item p { font-size: 16px; font-weight: 500; margin-bottom: 0; line-height: 1.5; }
#profile-page .profile-feature-item .item-status{ min-width: 120px; }
#profile-page .profile-feature-item.success .item-status{ color:#1BA94C; }
#profile-page .profile-feature-item.pending .item-status{ color:#FDBC1F; }
#profile-page .profile-slider { margin: 16px auto; max-width: 620px; width: 100%;  }
#profile-page .profile-slider p { font-size: 16px; font-weight: 500; margin: 4px 0  0 0; color: #20222F }
#profile-page .profile-slider span { color: #2F2E30; }
#profile-page .form-page { width: 100%; margin: 32px 0 16px 0; }
#profile-page .form-group { max-width: 820px; width: 100%; margin: 12px 0 0 0; padding: 0 0 12px 0; }
#profile-page .form-group.last-0 { margin: -12px 0 32px 0; }
#profile-page .form-group.last { margin: -24px 0 32px 0; }
#profile-page .form-group.fill-width { max-width: 100%; }
#profile-page .form-row { width: 100%; margin: 0 0 12px 0; }
#profile-page .form-group .red { color: #E50000; font-weight: 400;}
#profile-page .form-group .errorTxt { color: #E50000; font-weight: 400; font-size: 12px; margin: 0;}
#profile-page .form-group .errorTxtAlt { color: #E50000; font-weight: 400; font-size: 12px; margin: 0;}
#profile-page .form-group .noErrorTxt { font-weight: 400; font-size: 12px; margin: 0;}
#profile-page .form-group h4 { font-size: 20px; font-weight: 500; color: #000000; margin-bottom: 16px; }
#profile-page .form-group h5 { font-size: 18px; font-weight: 500; color: #20222F; margin-bottom: 16px; }
#profile-page .form-group h6 { font-size: 14px; font-weight: 500; color: #504F51; margin-bottom: 4px; }
#profile-page .form-group h6 b { font-weight: 400; }
#profile-page .form-group textarea,
#profile-page .form-group input[type=number],
#profile-page .form-group input[type=text] { width:100%; font-size: 13px; color: #20222F; border:1px solid #DFE1E6; background-color: #FAFBFC; border-radius:4px; margin:2px 0; outline:none; padding:8px 12px; min-height: 40px; box-sizing:border-box; transition:.3s;}
#profile-page .form-group textarea.error,
#profile-page .form-group input[type=number].error,
#profile-page .form-group input[type=text].error{ border-color: #ffbcbc; box-shadow:0 0 2px -1px #ffbcbc; }
#profile-page .form-group textarea:focus,
#profile-page .form-group input[type=number]:focus,
#profile-page .form-group input[type=text]:focus{ border-color: #d5c8f9; box-shadow:0 0 2px -1px #d5c8f9; }
#profile-page .form-group .h6-button { font-family: "Poppins", sans-serif !important; padding:0; text-decoration: none; letter-spacing: 0; font-size: 14px; font-weight: 500; text-transform: none; color: #504F51; line-height: 1;}
#profile-page .form-group .h6-button .MuiButton-label{font-size: 14px; font-weight: 500;}
#profile-page .form-group .h6-button .MuiSvgIcon-root{font-size: 18px;}
#profile-page .form-group .MuiAccordion-root { border: 1px solid #DFE1E6; background-color:#FAFBFC; box-shadow:none; border-radius: 4px; }
#profile-page .form-group .MuiAccordion-root::before{ background-color: transparent; }
#profile-page .form-group .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root{ min-height: 48px; }
#profile-page .form-group .MuiAccordion-root.Mui-expanded .MuiCollapse-wrapper{ margin-top: -16px; }
#profile-page .form-group .MuiAccordion-root textarea,
#profile-page .form-group .MuiAccordion-root input[type=number],
#profile-page .form-group .MuiAccordion-root input[type=text] { background-color: #ffffff;}
#profile-page .form-group .MuiAccordionDetails-root { width: 100%; }
#profile-page .form-group .MuiAccordionDetails-root > div { width: 100%; }
#profile-page .ellipse-input {position: relative; min-height: 38px;}
#profile-page .ellipse-input.with-split {border-right: 1px solid #DFE1E6;}
#profile-page .ellipse-input .input {position: absolute; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
#profile-page .inputBox { width:100%; color: #20222F; border:1px solid #DFE1E6; background-color: #FAFBFC; border-radius:4px; margin:0 0 2px 0; outline:none; box-sizing:border-box; transition:.3s;}
#profile-page .inputBox.menuSearch { border:0px solid #DFE1E6; border-bottom:1px solid #DFE1E6; background-color: #FAFBFC; border-radius:4px 4px 0 0;}
#profile-page .inputBox.error{ border-color: #ffbcbc; box-shadow:0 0 2px -1px #ffbcbc; }
#profile-page .inputBox:focus{ border-color: #d5c8f9; box-shadow:0 0 2px -1px #d5c8f9; }
#profile-page .inputBox input:hover,
#profile-page .inputBox input:focus,
#profile-page .inputBox input:active{outline: none;}
#profile-page .inputBox input,
#profile-page .inputBox input[type=text] { color: #20222F; margin: 0; border-width: 0;min-height: 38px;background-color: transparent; padding: 8px 12px;}
#profile-page .inputBox .input { margin: 0; border-width: 0;min-height: 38px;background-color: transparent; padding: 8px 12px;line-height: 24px;}
#profile-page .inputBox { cursor: pointer;}
#profile-page .inputBox input[type=text]{ cursor: text;}
#profile-page .inputBox.disabled { cursor: default;}
#profile-page .inputBox.disabled .input,
#profile-page .inputBox .disabled input{ cursor: default; color: #A6A6AA;}
#profile-page .inputBox .chip { border: 1px solid #C7C7C7; padding: 0px 4px 0 12px; border-radius: 16px; height: 26px; }
#profile-page .inputBox .chip span { white-space: nowrap; line-height: 1; font-size: 12px; }
#profile-page .inputBox .chip button { border-width: 0; color: #41526E; background-color: transparent; position: relative; z-index: 100; display: flex; height: 24px; width: 24px; justify-content: center; align-items: center; transform: scale(0.8);transition:all cubic-bezier(0.075, 0.82, 0.165, 1);}
#profile-page .inputBox .chip:hover button{ transform: scale(1)}
#profile-page .inputBox .chip button:hover{ color: #E50000; }
#profile-page .react-datepicker {
  border: 0px solid transparent;border-radius: 32px; box-shadow: 0 1px 1px rgba(0,0,0,0.08), 0 2px 2px rgba(0,0,0,0.08), 0 4px 4px rgba(0,0,0,0.08), 0 8px 8px rgba(0,0,0,0.08), 0 16px 16px rgba(0,0,0,0.08);
}
#profile-page .react-datepicker-popper, #profile-page .react-datepicker{font-family: "Poppins", sans-serif;}
#profile-page .react-datepicker__month-container{border: 1px solid #efefef;}
#profile-page .react-datepicker__header { background-color: transparent;border: 0 solid #efefef;}
#profile-page .react-datepicker__triangle {border-bottom-color:#ffffff;z-index: 1;}
#profile-page .react-datepicker__triangle::before {border-bottom-color:#efefef}
#profile-page .react-datepicker__triangle::after {content: "";
    z-index: 1;
    border-width: 8px;
    left: -8px;
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #ffffff;
    height: 0;
    width: 1px;
    top:-5px;
}
#profile-page .react-datepicker__innerheader {margin-bottom:8px;padding:12px 16px 12px 16px; background-color: transparent; color: #050505;min-width: 328px; border-bottom: 1px solid #434343;}
#profile-page .react-datepicker__header .date{font-size: 18px; color: #050505; font-weight: 600; line-height: 1;}
#profile-page .react-datepicker__header .week{font-size: 15px; color: #050505; font-weight: 400; line-height: 21px;}
#profile-page .react-datepicker__header button{background-color: transparent;border: 0px solid transparent; color: #050505; font-size: 32px; line-height: 1;}
#profile-page .react-datepicker__header button:disabled{ color: #afafaf; }
#profile-page .react-datepicker__day-names {}
#profile-page .react-datepicker__day-names .react-datepicker__day-name{font-size: 12px; font-weight: 300; width: 32px; height: 32px; margin: 4px;}
#profile-page .react-datepicker__day{width: 32px; height: 32px; margin: 4px; font-size: 14px; display: flex; justify-content: center; align-items: center; color: #050505; border-radius: 50%;}
#profile-page .react-datepicker__day.react-datepicker__day--outside-month{color: #A5A5A5;}
#profile-page .react-datepicker__day.react-datepicker__day--keyboard-selected{background-color: #794DF5;color: #ffffff; font-weight: 500;}
#profile-page .react-datepicker__day.react-datepicker__day--selected, #profile-page .react-datepicker__day.react-datepicker__day--selected:hover{background-color: #794DF5;color: #ffffff; font-weight: 500;}
#profile-page .react-datepicker__week{ display: flex; justify-content: center; align-items: center; }

.user-card {
  margin: 0 auto;
}
.profile-card {
  height: 800px;
  overflow-y: hidden;
}
.profile-card:hover, .profile-card-two:hover {
  overflow-y: scroll;
  transition: all .8s ease-in-out;
}
.profile-card::-webkit-scrollbar, .profile-card-two::-webkit-scrollbar {
  width: 10px;
}
.profile-card::-webkit-scrollbar-track, .profile-card-two::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
  margin-bottom: 150px;
}
.profile-card::-webkit-scrollbar-thumb, .profile-card-two::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);;
  border-radius: 10px;
}
.profile-card::-webkit-scrollbar-corner, .profile-card-two::-webkit-scrollbar-corner {
  background-color: transparent;
  border-color: transparent;
}
.profile-card-two {
  height: 600px;
  overflow-y: hidden;
}
.br-top-bg {
  border-top: 10px solid #C5EAFC;
}
.user-card-body {
  padding: 40px !important;
}
.card-bg {
  background-color: #85DAE1;
  height: 100px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.upload-btn {
  height: 32px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #51b960;
  border: 2px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
}
.upload-btn:hover {
  background-color: #40944d;
}
.upload-btn input[type="file"] {
  height: 32px;
  width: 32px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.fieldForPass {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-right: 5px;
}
.fieldForPass input {
  border: none;
}
.row-group label {
  font-size: 14px;
  font-weight: bold;
}
.change-password {
  color: #0377D1;
  text-decoration: underline !important;
}
.password-ellips {
  white-space: nowrap; 
  width: 160px; 
  overflow: hidden;
}
.change-pass-grp,.ml2 {
  margin-left: 40px;
}
.sub-title, .main-title {
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  margin-bottom: 1rem;
}
.main-title {
  font-size: 22px;
}
.button-row button {
  min-width: 150px;
}
.profile-percentage {
  font-size: 16px;
  text-align: center;
  margin: 20px auto;
}
.remove-button {
  position: absolute;
  top: 70px;
  right: 20px;
}
