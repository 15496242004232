.main {
  padding: 2.25rem 0;
}

.checkDiv {
  background: #ffffff;
  border: 1px solid #f2f1f3;
  border-radius: 5px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  color: #19181b;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  line-height: 1.625rem;
  margin-bottom: 1.5rem;
  padding: 0.9375rem 1.125rem;

  &>div {
    display: flex;
    gap: 1.125rem;
  }

  &:nth-of-type(3) {
    margin-bottom: 4rem;
  }
}

.proctoringBox {
  display: flex;

  &>div {
    flex: 1;
  }
}

.title {
  color: #19181b;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.625rem;
  text-align: center;
}

.desc {
  color: #323036;
  font-size: 0.875rem;
  line-height: 1.4375rem;
}

.webcamContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.webcamItem {
  border: 2px solid #794df5;
  height: 18.75rem;
  margin-bottom: 3rem;
  position: relative;
  width: 25rem;

  video {
    height: 100%;
    width: 100%;
  }
}

.redBorder {
  border: 2px solid #e22b12;
}