@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap");

/* .main-content{
  background: '#E5E5E5';
}
.main-content {
  background: #ffffff !important;
} */

.prepare-body {
  background-color: white;
}
.mobileNavHeader {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #19181b;
}
.filter-button {
  width: 102px;
  height: 39px;
  background: #f9f9f9;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3a3a3a;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 20px 10px;
}

#input_container {
  position: relative;
  padding: 0 0 0 20px;
  margin: 0 20px;
  background: #ddd;
  direction: ltr;
  width: 200px;
}
#input {
  height: 20px;
  margin: 0;
  padding-right: 30px;
  width: 100%;
}
#input_img {
  position: absolute;
  bottom: 2px;
  right: 5px;
  width: 24px;
  height: 24px;
}

.banner-content {
  position: relative;
  top: 80px;
  text-align: left;
  width: 395px;
}

@media (max-width: 480px) {
  .search-input {
    width: 300px !important;
  }
}

.search-inputValue {
  width: 400px;
  height: 40px;
  margin-top: 20px;
  box-sizing: border-box !important;
  overflow: hidden;
  padding: 0 !important;
  border: none;
}
.search-AdminInput {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  overflow: hidden;
  padding: 0 !important;
}
.search-AdminInput .form-controls {
  position: relative !important;
  border: none !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #a6a6a6;
  width: 100%;
  height: 100%;
  padding-left: 15px;
}
.search-AdminInput .form-controls:focus {
  border: none !important;
  outline: none;
}
.search-inputValue .SearchDesign {
  position: relative !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #000000;
  width: 100%;
  height: 100%;
  padding-left: 15px;
}
.prepare {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #63606c;
}

.prepare-desc {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: #19181b;
}

.prepare-content {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #794df5;
}

.continue-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #19181b;
}

.continue-desc {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #63606c;
  bottom: 0px;
}

.continue-card {
  text-align: start;
  position: relative;
  top: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.courseTag {
  position: absolute;
  top: 10px;
  right: 16px;
}

.course-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #19181b;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

.continue-button {
  width: 100%;
  height: 40px;
  border: 1px solid #794df5 !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: #794df5 !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
  /* position: absolute !important;
  bottom: 13px;
  left: 10px; */
}
.disableButton {
  width: 100%;
  height: 40px;
  border: 1px solid #8b8b8b !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: #4b4bdf !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
}
.rewatch-disable {
  width: 100%;
  height: 40px;
  border: 1px solid #faf9fb !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: #757575 !important;
  background-color: #bebebe !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
}
.StartHackathon {
  width: 100%;
  height: 40px;
  border: 1px solid #794df5 !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  background-color: #794df5 !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
  /* position: absolute !important;
  bottom: 13px;
  left: 10px; */
}
.continue-rating {
  font-size: 14px;
  position: relative;
  bottom: 4px;
  left: 4px;
}
.preview-button {
  width: 90%;
  border: 1px solid #794df5 !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: white !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  background: #794df5 !important;
  text-transform: none !important;
  position: absolute !important;
  bottom: 13px;
  left: 10px;
}
.search-course {
  position: relative;
  width: 100%;
  height: 40px;
  float: left;
  top: 40px;
  background: #ffffff;
  border: 1px solid #bababa;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px -4px rgb(24 39 75 / 12%),
    0px 4px 6px -4px rgb(24 39 75 / 12%);
  border-radius: 5px;
}

.form-controls {
  border: 1px solid white !important;
}

.form-controls::placeholder {
  font-size: 0.95rem;
  color: #aaa;
}
.SearchDesign {
  border: 1px solid #aaa;
  border-radius: 5px;
}
.SearchDesign:focus {
  border: 1px solid #794df5;
  border-radius: 5px;
}
.SearchDesign::placeholder {
  font-size: 0.95rem;
  color: #aaa;
}
.various-course .MuiTabs-root {
  background: white !important;
  border-bottom: 1px solid #d9d9d9;
}

.various-course .PrivateTabIndicator-root-52 {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: relative;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.various-course button:focus {
  outline: none !important;
  outline: none !important;
}
.various-course .MuiTabs-indicator {
  background-color: #1ba94c !important;
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
  height: 4px !important;
}
.various-course .PrivateTabIndicator-colorPrimary-35 {
  background-color: #1ba94c !important;
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
  height: 4px !important;
}
.various-course .PrivateTabIndicator-colorPrimary-41 {
  background-color: #1ba94c !important;
}
.various-course .PrivateTabIndicator-root-34 {
  width: 100%;
  bottom: 0px;
  height: 4px !important;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.various-course .MuiTab-wrapper {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.various-course .MuiTab-textColorPrimary.Mui-selected {
  color: #1ba94c;
  background: #dbffe7 !important;
  border-bottom: 3px solid #1ba94c;
}
@media (min-width: 600px) {
  .various-course .MuiTab-root {
    min-width: 170px !important;
  }
}
.original-price {
  position: relative;
  top: 5px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-decoration-line: line-through;

  color: #f91818;
}

.course-discount {
  position: relative;
  top: 12px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #545260;
}

.logo {
  position: relative;
  width: 92px;
  height: 40px;
  left: 64px;
}
.course141 {
  flex-grow: 1;
  width: 100%;
  margin-top: 56px;
  margin-bottom: 100px;
}

.rating-value {
  font-size: 12px;
  position: relative;
  bottom: 4px;
}

.hours-svg {
  margin-bottom: 4px;
  margin-right: 3px;
}
.card-design {
  margin: auto !important;
  position: relative;
  top: 50px;
}

.duration {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  color: #908d99;
}

.viewAll {
  width: 100%;
  height: 40px;
  border: 1px solid #1ba94c !important;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 120px;
  color: #1ba94c !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
  /* position: absolute !important;
  bottom: 13px;
  left: 10px; */
}

.paddingCard {
  padding: 1rem;
}
.paddingContainer {
  padding: 4rem;
}
.hackathonHeader {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 38px;
  color: #19181b;
}
.hackathonHeaderMobile {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  color: #19181b;
}
.hackathonParagraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #323036;
  word-break: break-all;
}
.hackathonCardParagraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #7c7887;
}
.hackathonCardHeader {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #323036;
}
.hackathonCardHeaderResponsive {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #323036;
}
.paragraphreg {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #323036;
  padding-left: 3rem;
}
.prizeHackathon {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #323036;
}
.hackathonHeaderCover {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 38px;
  color: #19181b;
  position: absolute;
  top: 9rem;
}
.stickyCard {
  position: fixed;
}

.MuiCardActions-root {
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: space-around;
}
.centerAlign {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.cardHeader {
  font-family: Poppins;
  font-style: normal;
  color: #323036;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.cardPara {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #63606c;
  text-align: center;
}
.hackathonListSelected {
  width: 60rem;
  border: 1px solid 794df5;
}
.hackathonList {
  width: 60rem;
}
.cardMobile {
  padding-left: 150px;
}
.rootCard {
  width: 67rem;
}
.rootCardMobile {
  width: 24rem;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.prizeCard {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.prizeCardMobile {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}
.prizeMobile {
  width: 20rem;
}
.registeredInfo {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #96939f;
}
.manageForm {
  background-color: #fff;
  border: 0px solid #f2f1f3;
  border-radius: 5px;
  padding: 1.5rem 2rem;
}

.searchBox {
  max-width: 15px;
  min-width: 10px;
}
