.dashboard {
  background-color: #fff;
  border: 1px solid #eeeeee;
  display: grid;
  gap: 44px 32px;
  margin-bottom: 44px;
  position: relative;
  width: 100%;
  justify-items: center;
  align-items: center;
  & > * {
    min-width: auto;
    overflow: hidden;
    width: 100%;
  }
  @media screen and (min-width: 320px) and (max-width: 639px) {
    grid-template-columns: 1fr;
    padding: 16px 24px;
  }
  @media screen and (min-width: 640px) and (max-width: 899px) {
    grid-template-columns: 1fr 1fr;
    padding: 24px;
  }
  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 24px 48px 24px 24px;
  }
  &::before {
    background-color: #1ba94c;
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }
}
.dashboardSection1 {
  .dashboardIconBox {
    margin-right: 12px;
  }
}
.dashboardIconBox {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  &.blue {
    background-color: #6b3cee;
  }
  &.red {
    background-color: #ffe6e6;
  }
  &.gray {
    background-color: #47466c;
  }
  &.lightBlue {
    background-color: #e6f3ff;
  }
}
.assessmentNameContainer {
  align-items: center;
  display: inline-flex;
}
.assessmentName {
  color: #000000;
  font-size: 16px;
}
.assessmentInfoGrid {
  display: grid;
  gap: 8px 16px;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  div {
    text-align: left;
    white-space: nowrap;
    span {
      font-size: 14px;
      &:nth-child(1) {
        color: #6f6f6f;
        font-weight: 400;
      }
      &:nth-child(2) {
        color: #000;
        font-weight: 600;
      }
    }
  }
}
.dashboardBox {
  align-items: center;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(189, 189, 189, 0.12);
  box-sizing: border-box;
  display: flex;
  height: 80px;
  justify-content: space-between;
  &:not(.date) {
    padding: 12px 32px 12px 24px;
  }
  &.date {
    padding: 12px 16px;
  }
}

.dashboardDateBox {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(189, 189, 189, 0.12);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 11rem;
  justify-content: space-between;
}
.dashboardBoxValuesContainer {
  display: flex;
}
.dashboardBoxValueFlex {
  display: flex;
  flex-direction: column;
  height: 51px;
  padding: 0 20px;
  position: relative;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
}
.dashboardSection2 {
  .dashboardBox {
    &:first-of-type {
      .dashboardBoxValueFlex {
        align-items: center;
        &:first-of-type {
          &::after {
            background: #e1e1e1;
            content: " ";
            display: block;
            height: 100%;
            position: absolute;
            right: 0;
            width: 1px;
          }
        }
      }
    }
  }
}
.dashboardBoxValue {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.35;
}
.dashboardBoxText {
  color: #3a3a3a;
  font-size: 16px;
  line-height: 1.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboardSection2,
.dashboardSection3 {
  display: grid;
  gap: 16px;
}
.dateTime {
  align-items: center;
  background: #fafafa;
  border-radius: 3px;
  color: #20222f;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 16px;
  height: 56px;
  justify-content: center;
  line-height: 24px;
  &.start {
    border: 1px solid #1ba94c;
  }
  &.end {
    border: 1px solid #d42216;
  }
}
.overAllPerformanceIconContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.completedProgressContainer {
  margin-top: 20px;
  padding-right: 32px;
}
.completedProgressText {
  color: #6f6f6f;
  font-size: 12px;
  line-height: 18px;
  margin-top: 12px;
  text-align: center;
}
.tag {
  font-weight: 500;
  border-radius: 5px;
  max-width: 105px;
  color: #fff;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 25px;
}

.tagAP {
  background-color: #ff3f3f;
}

.tagDF {
  background-color:#089A79;
}

.tagPN {
  background-color: #D99229;
}

.tag2 {
  font-weight: 500;
  border-radius: 5px;
  max-width: 185px;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 30px;
}

.tagCard {
  font-weight: 500;
  border-radius: 5px;
  max-width: 185px;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 30px;
}

.tagRAP {
  background-color: rgba(255, 255, 255, 0.8);
  color: #323036;
  white-space: nowrap;
}

.startDate {  
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding-left: 1rem;
}
.startDateHack {  
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding-left: 1rem;
  padding-bottom: 1rem;
}
.dashboardPrizeBox{
  display:grid;
}
.dashboardGridBox{
  display:grid;
}