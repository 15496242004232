/** @format */

@import "../../assets/scss/mixins.scss";

.appbar {
  padding: 0;
}

.toolbar {
  @media screen and (max-width: 419px) {
    @include applyNotchProperty(padding-left, left, 16px, 16px, true);
    @include applyNotchProperty(padding-right, right, 16px, 16px, true);
  }
  @media screen and (min-width: 420px) and (max-width: 767px) {
    @include applyNotchProperty(padding-left, left, 24px, 24px, true);
    @include applyNotchProperty(padding-right, right, 24px, 24px, true);
  }
  @media screen and (min-width: 768px) {
    @include applyNotchProperty(padding-left, left, 32px, 32px, true);
    @include applyNotchProperty(padding-right, right, 32px, 32px, true);
  }
}

.rightSection {
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: auto;
}

.customerLogo {
  border-radius: 4px;
  height: 60px;
  margin-right: 16px;
  max-width: 160px;
  object-fit: contain;
  object-position: center;
}
.customerLogoTwo {
  border-radius: 4px;
  height: 60px;
  margin-right: 16px;
  max-width: 160px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 6px;
}

.linkButton {
  // background-color: #fff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  padding: 5px 10px;
  padding-bottom: 15px;
  text-decoration: none;
  @media screen and (max-width: 896px) {
    margin: 4px 0;
    padding-bottom: 10px;
    padding-top: 10px;
    @include applyNotchProperty(padding-left, left, 24px, 24px, true);
    @include applyNotchProperty(padding-right, right, 24px, 24px, true);
  }

  @media screen and (min-width: 897px) {
    margin: 0 8px;
  }

  &:hover {
    color: #784df5;
  }

  &.active {
    // background-color: #784df5;
    // color: #fff;
    border-bottom: 6px solid #784df5;
  }

  &:last-of-type {
    margin-right: 26px;
  }
}

.headerItemList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.2625rem;
  margin: 0 auto;
}

.hideHeaderList {
  display: none;
}

.headerItem {
  display: flex;
  align-items: center;
  gap: 0.5125rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4375;
  // color: #323036;
  color: #fff;
  cursor: pointer;
}

// .headerItem:hover {
//   height: 60px;
//   border-bottom: 4px solid #794df5;
// }

.compactNavMenu {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  font-weight: 500;
  font-size: 1rem;
  width: 12.5rem;
  span {
    color: #323036;
  }
}

.menuBlob {
  position: absolute;
  top: 33px;
  grid-column: 1/4;
  grid-row: 11/12;
  transform: rotate(180deg);
}

.fullMenuSummary {
  grid-column: 2/4;
  grid-row: 2/12;
}

.fullMenuTitleContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.fullMenuTitle {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.375rem;
  color: #19181b;
}

.fullMenuDesc {
  font-size: 1.125rem;
  line-height: 1.8125rem;
  color: #63606c;
}

// .menuItemHeaderOne,
.menuItemHeaderTwo,
.menuItemHeaderThree,
.menuItemHeaderOne {
  font-weight: 500;
  font-size: 1rem;
  // line-height: 1.625rem;
  text-align: center;
  color: #19181b;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.menuItemHeaderListOne,
.menuItemHeaderListTwo,
.menuItemHeaderListThree,
.menuItemHeaderListFour {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 9%;
font-size: 18px;
font-weight: 400;
line-height: 20px;
text-align: left;

}

.menuItemHeaderListItem {
  font-size: 0.875rem;
  line-height: 1.4375rem;
  color: #19181b;
  margin: 0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.menuItemHeaderLists {
  display: flex;
  gap: 2.5rem;
}
.selectEmpty {
  color:"#fff";
  & > div:first-child {
    padding: 10px 16px;
    font-size: 14px;
    color:"#fff";
    font-weight: 600;
    padding-right: 32px;
  }
  & > div:first-child:focus {
    background-color: transparent;
    color:"#fff";
  }
  & > fieldset {
    border: rgba(0, 0, 0, 0.08);
    color:"#fff";
  }
  padding: 0 !important;
  color:"#fff";
}
.menuOne {
  grid-column: 5/8;
  grid-row: 2/12;
  border-right: 1px solid #d8d6db;
}

.menuTwo {
  grid-column: 8/12;
  grid-row: 2/12;
  margin-left: 2.5rem;
}

.menuBlob1 {
  grid-column: 12/13;
  grid-row: 1/12;
}

.mobileMenuItemHeader {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #63606c;
}

.mobileMenuItemHeaderList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: #323036;
}
