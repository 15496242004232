.header {
  background-color: #000;
  padding: 6px 0 !important;
  position: relative !important;
  &,
  * {
    color: #fff !important;
    font-size: 16px !important;
    text-align: center;
  }
  font-family: Poppins;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    transform: translateY(-50%) scale(1.5);
  }
}

.buttons {
  font-size: 14px !important;
  font: weight 500 !important;
}

.content {
  width: 30vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentText {
  font-size: 20px;
  font-weight: 600;
}
