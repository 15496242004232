.form {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 2rem;

  display: flex;
  gap: 4rem;
}

.privBox {
  background-color: #f2f1f3;
  padding: 24px 40px;
  margin: -2rem -2rem -2rem 0;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  & h4 {
    color: #3a3a3a;
    font-size: 1.125rem;
  }

  & p {
    color: #63606c;
    font-size: 0.875rem;
  }
}

.privAccordions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.accordion {
  box-shadow: none !important;
  border: 1px solid #d8d6db !important;
  width: 26.25rem !important;
}

.selectedItems {
  color: #794df5;
  font-size: 0.875rem;
  font-weight: 500;
}

.checkboxPrimary {
  color: #794df5 !important;
}

.accordionSumm {
  justify-content: space-between !important;
  & p {
    padding: 0;
    margin: 0;
    color: #323036;
    font-weight: 500;
    font-size: 1rem;
  }

  & div {
    display: flex;
    gap: 1em;
    align-items: center;
  }
}

.checkboxLabel {
  color: #4a4851 !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}

.accordionSummRoot {
  flex-direction: row-reverse !important;
  gap: 1rem !important;
}

.accordionDet {
  flex-direction: column !important;
  padding: 0 1.75rem !important;
}

.submitButton {
  background-color: #794df5 !important;
  font-family: "Poppins", "sans-serif" !important;
  width: 9.5625rem !important;
  height: 3.125rem !important;
  font-size: 1.125rem !important;
  text-transform: none !important;
  margin-right: auto !important;
}
