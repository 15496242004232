.tabContent {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.headerContainer {
    padding: 20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid lavender;
    background-color: #ffffff;
}

.composeButton {
    background-color: #794DF5;
    border-radius: 3px;
    border: 1px solid #794DF5;
    position: absolute;
    top: 19.5rem;
    right: 3rem;
    width: 143px;
    height: 40px;
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px
}

.infoContainer {
    height: 3.5rem;
    background-color: #F4F7FA;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #63606C;
}
.tempWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;
    padding: 10px 20px;
    width: 100%;
    & div h2 {
        font-weight: 600;
        font-size: 18px;
        color: #19181B;
    }
    & div span {
        font-weight: normal;
        font-size: 14px;
        color: #63606C;
    }
    & div button {
        border: 1px solid #1BA94C;
        color: #1BA94C;
        background-color: transparent;
        font-weight: 600;
        font-size: 16px;
        height: 40px;
        padding: 0 15px;
    }
}

.headFont {
    font-weight: 600;
    font-size: 16px;
    color: #19181B;
}
.dropdownWrap {
    background-color: #EDF9FF;
    padding: 20px;
    display: flex;
    align-items: center;
    & label {
        display: inline !important;
        font-weight: 500;
        font-size: 16px;
        color: #323036;
    }
}
.custBtn {
    color: #63606C !important;
    background: #F2F1F3 !important;
    border: none !important;
    width: 106px;
    margin-left: 30px;
}

.xAlertIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.alertMsg {
    font-weight: 600;
    font-size: 18px;
    color: #323036;
    text-align: center;
}

.reasonBox {
    background: #FFF8F8;
    border-left: 4px solid #DE5445;
    overflow: hidden;
    & p {
        padding: 15px;
        font-size: 16px;
        font-weight: 500;
    }
}