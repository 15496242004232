//
// _footer.scss
//

.footer {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: relative;
  margin-top: auto;
  right: 0;
  color: $footer-color;
  left: 0;
  height: $footer-height;
  background-color: $footer-bg;
}

@media (max-width: 992px) {
  .footer {
    left: 0;
  }
}

.container-termsAndCondition {
  margin-left: 80px;
  font-size: 16x;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.container-ellipse {
  width: 18px;
  height: 18px;
  color: #96939f;
  margin-bottom: 13px;
  margin-left: 20px;
}

.container-policy {
  margin-left: 20px;
  font-size: 16x;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.container-legal {
  margin-left: 20px;
  font-size: 16x;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.container-tervMark {
  margin-bottom: 15px;
  margin-left: 560px;
  font-size: 16x;
  font-weight: 550;
  font-family: "Poppins", sans-serif;
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 100 !important;
  }
}
