@import "../../../../../../assets/scss/mixins.scss";

.container {
  border-radius: 3px !important;
  height: 40px !important;
  overflow: hidden !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  position: relative !important;

  &,
  .selector {
    height: 40px !important;
  }

  fieldset {
    border: none !important;
  }
}

.selector {
  padding-bottom: 12px !important;
  padding-top: 7px !important;
}

.smallScreenSelector {
  cursor: pointer;
  height: 36px !important;
  padding: 5px 34px 5px 10px !important;
  position: relative;

  &::after {
    color: #444;
    content: '▼';
    font-size: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.smallScreenSelector {
  background-color: #eee !important;
  border: 1px solid #eee !important;
  border-radius: 3px !important;
}

.selector {
  background-color: #F5FAFF !important;
  border: 1px solid #D8D6DB !important;
  border-radius: 3px !important;
}

.menuBackdrop {
  background-color: #0005 !important;
}