.studentPointsChart {
  svg {
    text {
      font-size: 15px !important;
    }
  }
}

.studentPointsViewChooser {
  background: #f0f6ff;
  border-radius: 5px;
  color: #000000;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 6px;
  line-height: 18px;
  padding: 10px 14px;
  position: absolute;
  right: 0;
  top: -12px;
  z-index: 10;
}

.studentPointsViewButton {
  background-color: transparent;
  border: none;
  display: block;
  margin-left: 14px;
  position: relative;
  text-align: left;

  &.active {
    &::before {
      background-color: #794df5;
      border: 1px solid #794df5;
    }

    &::after {
      border: 2px solid #f0f6ff;
      border-radius: 6px;
      content: " ";
      display: block;
      height: 10px;
      left: -13px;
      position: absolute;
      top: 4px;
      width: 10px;
      z-index: 2;
    }
  }

  &::before {
    border: 1px solid #000;
    border-radius: 6px;
    content: " ";
    display: block;
    height: 12px;
    left: -14px;
    position: absolute;
    top: 3px;
    width: 12px;
  }
}