.headerBox {
    padding: 1rem;
}

.selectWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
}
.selectWrapper1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 0;
}

.selectBox {
    width: 250px;
    margin-right: 30px;
}
.selectBox1 {
    width: 250px;
    margin-right: 10;
}

.mainBox {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 1.5rem;
}

.nameOfUser {
    text-align: center;
}

.dashboardBoxWrapper {
    background: #F8FAFF;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin: 30px 15px;
}

.dashMainBox {
    display: flex;
    align-items: center;
}

.dashboardBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.dashboardBox .dashTitle {
    font-size: 14px;
    font-weight: 400;
    color: #323036;
    padding-bottom: 15px;
}

.dashboardBox .dashValue {
    font-size: 20px;
    font-weight: 600;
    color: #323036;
}

.divLiner {
    width: 1px;
    height: 40px;
    background: #D8D6DB;
    margin: 0 30px;
}

.sectionTab {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(53, 52, 52, 0.2);
    border-radius: 5px;
    margin: 30px auto;
    width: fit-content;
    display: flex;
  }

.sectionTabBtn {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }

.optionEmptySec {
    min-height: 50vh;
    margin: 30px 0;
}

.bluebg {
    background-color: #C5EAFC;
    height: 40px;
}

.optionCenter {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    padding-top: 50px;
}

.loadingPosition {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
}

.resetLink {
    color: #0030DA;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
}