@import "../../../../../../assets/scss/mixins.scss";

.submitButton {
  flex-shrink: 0;

  &.smallScreen {
    height: 36px !important;
  }

  &:not(.smallScreen) {
    height: 40px !important;
  }
}