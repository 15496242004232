.page {
  position: relative;
  margin: 0 auto !important;
  max-width: 1440px !important;
  padding: 32px 64px !important;
}
.pageMobile {
  position: relative;
  margin: 0 auto !important;
  max-width: 1440px !important;
  padding: 9px 8px !important;
}