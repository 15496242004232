@import "../../../../../assets/scss/mixins.scss";

.header {
  box-shadow: none !important;
}

.toolbar {
  @include applyNotchProperty(padding-left, left, 20px, 20px, true);
  @include applyNotchProperty(padding-right, right, 20px, 20px, true);
}

.paginationList {

  &,
  * {
    color: #fff !important;
  }

  :global(.MuiPaginationItem-root) {
    &:hover {
      background-color: #fff1 !important;
    }
  }

  :global(.Mui-selected) {
    background-color: #fff3 !important;
  }
}

.headerContent {
  align-items: center;
  display: flex;
}

.spacer {
  margin: auto;
  position: relative;
  width: 1px;
}

.completedQuestion {
  height: 36px;

  &::before {
    background-color: #1ba94c;
    border-radius: 3px;
    content: " ";
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    top: 3px;
    transform: translateX(-50%);
    width: 4px;
  }
}

.explantionModal {
  border: 2px solid #8888;
  min-width: 640px;
}

.explantionModalTitle {
  &.lightMode {
    color: #444 !important;
  }

  &:not(.lightMode) {
    color: #fff !important;
  }
}

.explantionModalContent {
  padding-bottom: 24px !important;
}