@mixin hasNotch {
  @supports (padding: unquote("max(0px)")) {
    @content;
  }
}

@mixin noNotch {
  @supports not (padding: unquote("max(0px)")) {
    @content;
  }
}

@mixin applyNotchProperty($property, $edge, $notchValue, $fallbackValue, $is-important) {
  $value: unquote("max(#{$notchValue}, env(safe-area-inset-#{$edge}))");

  @include hasNotch {
    #{$property}: if($is-important, $value !important, $value);
  }

  @include noNotch {
    #{$property}: if($is-important, $fallbackValue !important, $fallbackValue);
  }
}
