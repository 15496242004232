.clearButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #0377D1;
    cursor: pointer;
    margin-left: 5rem;
    margin-top: 1rem;
  }

  .accItem {
    font-family: "Poppins" !important;
  }