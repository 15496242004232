.tableContainer {
  max-height: 526px;
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
}
.table {
  margin: 0 !important;
  position: relative;
  width: 100%;
  thead {
    tr {
      background-color: #c5eafc;
      th {
        background-color: #c5eafc;
        color: #000000;
        font-size: 15px;
        font-weight: 600;
        height: 46px;
        line-height: 24px;
        position: sticky;
        text-align: center;
        top: 0;
        white-space: nowrap;
        &:nth-child(2) {
          text-align: left;
          width: 64px;
        }
      }
    }
  }
  tbody {
    tr {
      background-color: #fff;
      &.highlight {
        background-color: #e9fffa;
        td {
          border-bottom: 1px solid #d2d2d2;
          border-top: 1px solid #d2d2d2;
          font-weight: 500 !important;
        }
      }
      &:last-of-type {
        td {
          border-bottom: none !important;
        }
      }
      td {
        color: #000000;
        font-size: 15px;
        font-weight: 400;
        height: 48px;
        line-height: 24px;
        text-align: center;
        &:nth-child(2) {
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 64px;
        }
        &:nth-child(4),
        &:nth-child(5) {
          text-transform: capitalize;
        }
      }
    }
  }
}
