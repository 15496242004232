.topSection {
  grid-column: 1/10;
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.flexOne,
.flexTwo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 2rem;
}

.flexOne {
  padding-top: 1rem;
}

.flexTwo {
  padding-bottom: 1rem;
}
.title {
  font-size: 1.2rem;
  color: #1a1b22;
}
.sub {
  font-size: 1rem;
  color: #7a7981;
}

.pending {
  background: #fff8e1;
  border: 1px solid #ffca99;
  box-sizing: border-box;
  border-radius: 3px;
  color: #886e1d;
  font-weight: 500;
  font-size: 13px;
  padding: 2px 5px;
}
.published {
  background: #deffe9;
  border: 1px solid #03832f;
  box-sizing: border-box;
  border-radius: 3px;
  color: #03832f;
  font-weight: 500;
  font-size: 13px;
  padding: 2px 5px;
}
.completed {
  background: #deffe9;
  border: 1px solid #03832f;
  box-sizing: border-box;
  border-radius: 3px;
  color: #03832f;
  font-weight: 500;
  font-size: 13px;
  padding: 2px 5px;
}
.avatar {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.name {
  font-weight: 500;
  font-size: 16px;
  color: #1a1b22;
  padding: 10px;
}
.contents {
  font-weight: normal;
  font-size: 15px;
  color: #1a1b22;
  padding: 10px;
}
.rightTop {
  background: #c5eafc;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.questionsTitle {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.qTitle {
  font-weight: 600;
  font-size: 16px;
  color: #3a3a3a;
  padding: 0 10px;
  min-width: fit-content;
}
.centerSection {
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  padding: 15px;
  z-index: 1;
  margin-top: 150px;
  width: 71%;
}
.navButton {
  border: none;
  background-color: transparent;
}
.navButton:hover {
  opacity: 0.5;
}
.questionSectionTabs {
  border-bottom: 1px solid #eeeeee;
  margin: 20px auto;
}
.sectionBtn {
  border: none;
  background: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #504f51;
  padding: 5px 10px;
}
.sectionBtn:hover {
  background: #deffe9;
  border-bottom: 2px solid #1ba94c;
  color: #03832f;
}
.questionList {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 15px 2px;
}
.questionCheckBox {
  width: 20px;
  height: 20px;
  // background: #1BA94C;
  // background: #676767;
  background: transparent;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  color: #504f51;
  // border: none;
  border: 1px solid #20222f;
  box-sizing: border-box;
  margin: 0 auto;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.questionCheckBox:hover {
  background: #1ba94c;
  color: #ffffff;
  border: none;
}
.activeTract,
.gradedTract,
.notGradedTract {
  width: 20px;
  height: 20px;
  background: #676767;
  border-radius: 3px;
}
.gradedTract {
  background: #1ba94c;
}
.notGradedTract {
  background: transparent;
  border: 1px solid #20222f;
}
.colorTrack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px;
}
.textFont {
  font-weight: 500;
  font-size: 12px;
  color: #3a3a3a;
  padding-left: 3px;
}
.radioButton {
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  max-width: 170px;
  margin-bottom: 10px;
}
.radioLabel {
  font-weight: 500;
  font-size: 15px;
  color: #1a1b22;
  padding: 5px;
}
.radioMainGroup {
  margin: 0 auto;
  padding: 10px;
}
.radioGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.markInput {
  width: 48px !important;
  height: 40px !important;
  background: #fafbfc !important;
  border: 1px solid #a6a6aa;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 5px;
  margin-left: 5px;
}
.marksGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.marksText {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}
.navButtonBottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chooseAnsBox {
  width: 577px;
  height: 41px;
  background: #a3ffc2;
  border: 1px solid #1ba94c;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}
.chooseAnsText,
.correctAns {
  font-weight: 500;
  font-size: 14px;
  color: #111111;
}
.chooseAns {
  padding: 10px;
}
.correctAns {
  font-style: italic;
  padding-left: 10px;
  padding-top: 10px;
}

.gradingContainer {
  display: grid;
  grid-template: 8rem min-content / repeat(12, 1fr);
  padding: 0 !important;
  margin-bottom: 1rem;
}
