.headerBox {
    padding: 1rem;
}
.tabContent {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.selectWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
}
.selectWrapper1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectBox {
    width: 250px;
    margin-right: 80px;
}
.selectBox1 {
    width: 250px;
    margin-right: 10;
}

.mainBox {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 1.5rem;
}

.nameOfUser {
    text-align: center;
}


.dashboardBoxWrapper {
    background: #F8FAFF;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin: 30px 15px;
}

.dashMainBox {
    display: flex;
    align-items: center;
}

.dashboardBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.dashboardBox .dashTitle {
    font-size: 14px;
    font-weight: 400;
    color: #323036;
    padding-bottom: 15px;
}

.dashboardBox .dashValue {
    font-size: 20px;
    font-weight: 600;
    color: #323036;
}

.divLiner {
    width: 1px;
    height: 40px;
    background: #D8D6DB;
    margin: 0 30px;
}

.sectionTab {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(53, 52, 52, 0.2);
    border-radius: 5px;
    margin: 30px auto;
    width: fit-content;
    display: flex;
  }

.sectionTabBtn {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }

.optionEmptySec {
    min-height: 50vh;
    margin: 30px 0;
}

.bluebg {
    background-color: #C5EAFC;
    height: 40px;
}

.optionCenter {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    padding-top: 50px;
}

.loadingPosition {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
}

.resetLink {
    color: #0030DA;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
}

.AdminPage{
    background-color: #FFFFFF;
    padding:10px
}
.cardgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  padding-bottom: 20px;
}
.cardStudentGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  padding-bottom: 20px;  
}
.cardStudentGridMobile {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  padding-bottom: 20px;
}
.cardRow {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  }
  .cardRowAdmin {
    display: grid;
    grid-template-rows: 1fr auto 1fr 1fr 1fr;
  }

.cardButtons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    
    div {
      padding: 0.3rem;
      border: 1px solid #784df4;
      border-radius: 5px;
      cursor: pointer;
      display: grid;
      place-items: center;
    }
}

.studentMeetingList {
    max-width:300px;
    max-height:275px;
    height:275px;
    padding-left:5px;
    padding: "10px";
  
}
.para {
    display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}
.heading {
    display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}
.searchInput {
    width: 250px;
    height: 40px;
    margin-top: 20px;
    border: 1px solid #bababa !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    overflow: hidden;
    padding: 0;
    margin-bottom: 20px;
}

.viewAll{
    width: 100%;
    height: 40px;
    border: 1px solid #1BA94C !important;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 120px;
    color: #1BA94C !important;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-transform: none !important;
  }
.wrappedCards {
    padding-left:30px;
    background-color: #fff;
}
.wrappedCardsMobile {
    padding-left:0px;
}