.saveButtonDiv {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.saveButton {
  font-size: 15px !important;
}

.cardBody {
  padding-bottom: 20px !important;
  border: 1px solid rgb(242, 241, 243);
}
.fieldRow {
  margin-bottom: 15px;
}
