.main {
    background-color: white;
}

.sectionOne {
    background: #1BA94C;
    height: 200px;
    width: 100%;
}

.container {
    max-width: 1224px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        color: white;
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
    }
    p {
        color: white;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
    }
}

.containerTwo {
    max-width: 1224px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 40px 0;
    .contentBox {
        padding-left: 56px;
        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 38px;
            color: #000000;
        }
        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #323036;
        }
    }
}

.containerThree {
    max-width: 1224px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 40px 0;
    .contentBox {
        padding-right: 56px;
        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 38px;
            color: #000000;
        }
        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #323036;
        }
    }
}

@media (max-width: 960px) {
    .container {
        text-align: center;
        padding: 20px;
    }
    .containerTwo {
        flex-direction: column;
        padding: 40px 20px;
        .contentBox {
            padding-left: 0;
            padding-top: 30px;
        }
    }
    .containerThree {
        flex-direction: column;
        padding: 40px 20px;
        .contentBox {
            padding-right: 0;
            padding-top: 30px;
        }
    }
}