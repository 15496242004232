@import "../../../../../../assets/scss/mixins.scss";

.smallHeader {
  background-color: #F4F7FA !important;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 4px 6px -4px rgba(24, 39, 75, 0.12) !important;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 50 !important;
}

.smallHeaderContent {
  background-color: #fff;
  height: 56px;
  @include applyNotchProperty(padding-left, left, 16px, 16px, true);
  @include applyNotchProperty(padding-right, right, 16px, 16px, true);
}