@import "../../assets/scss/mixins.scss";

$largeScreen-padding: 20px;

.backOnline {
  align-items: center !important;
  background-color: #007aff !important;
  color: #fff !important;
  display: flex !important;
  font-size: 16px !important;
  min-height: 0 !important;
  padding: 8px 16px 8px 8px !important;

  button {
    align-items: center !important;
    color: #fff !important;
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    margin: auto !important;
    position: relative !important;
  }
}

.lastFewMinsWarning {
  align-items: center !important;
  background-color: orange !important;
  color: #000 !important;
  display: flex !important;
  font-size: 16px !important;
  min-height: 0 !important;
  padding: 8px 16px 8px 8px !important;

  button {
    align-items: center !important;
    color: #000 !important;
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    margin: auto !important;
    position: relative !important;
  }
}

.restrictedAction {
  align-items: center !important;
  background-color: #ff0000 !important;
  color: #fff !important;
  display: flex !important;
  font-size: 16px !important;
  min-height: 0 !important;
  padding: 8px 16px 8px 8px !important;

  button {
    align-items: center !important;
    color: #fff !important;
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    margin: auto !important;
    position: relative !important;
  }
}

.page {
  display: flex;
  flex: 1;
  min-height: 100%;
  position: relative;
  user-select: none;
  width: 100%;

  &,
  * {
    box-sizing: border-box !important;
  }

  &.lightTheme {
    background-color: #f4f7fa;
  }

  &.darkTheme {
    background-color: #444;
  }

  &:global(.medium-screen),
  &:global(.large-screen) {
    display: grid;
    gap: 16px;
    padding-top: 20px;
    @include applyNotchProperty(padding-bottom, bottom, 20px, 20px, true);
    @include applyNotchProperty(padding-left, left, 20px, 20px, true);
    @include applyNotchProperty(padding-right, right, 20px, 20px, true);
  }

  &:global(.small-screen) {
    .summaryFooter {
      flex-direction: column !important;
    }
  }
}

.body {
  min-height: 100%;
  position: relative;
  width: 100%;

  &:not(.mediumScreen) {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &.noFreeScroll {
    height: 100vh;
    overflow: hidden !important;
  }
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.sliderContainer {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;

  :global(.MuiSlider-rail),
  :global(.MuiSlider-track) {
    opacity: 0 !important;
  }

  :global(.MuiSlider-thumb) {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    cursor: col-resize !important;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    height: 48px !important;
    justify-content: center;
    margin-top: -23px;
    padding: 4px !important;
    position: relative;
    transition-duration: 300ms;

    &::before {
      border-radius: 10px;
      content: " ";
      display: block;
      height: calc(100% + 4px);
      margin-left: -2px;
      margin-top: -2px;
      position: absolute;
      width: calc(100% + 4px);
    }

    &:hover {
      &::before {
        border: 2px solid #aaa;
      }

      .widthSliderDot {
        background-color: #aaa !important;
      }
    }

    &:global(.MuiSlider-active) {
      &::before {
        background-color: #888 !important;
        border: 2px solid #888 !important;
      }

      .widthSliderDot {
        background-color: #fff !important;
      }
    }

    &::after {
      display: none !important;
    }
  }

  .widthSliderDot {
    background-color: #aaa;
    border-radius: 5px;
    height: 6px;
    margin: auto;
    position: relative;
    transition-duration: 300ms;
    width: 6px;
    z-index: 2;
  }
}

.questionSection {
  margin-right: auto;
  position: relative;
}

.solutionSection {
  margin-left: auto;
  position: relative;
}

:global(.small-screen) {
  .content {
    display: block;
  }
}

:global(.medium-screen) {
  .content {
    display: grid;
    gap: $largeScreen-padding;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    & > * {
      width: 100% !important;
    }
  }
}

:global(.large-screen) {
  .body {
    grid-template-rows: 120px 1fr;
    height: calc(100vh - #{$largeScreen-padding * 2});
  }
}

:global(.medium-screen),
:global(.large-screen) {
  padding: $largeScreen-padding;
}

:global(.large-screen) {
  .body {
    display: grid;
    gap: $largeScreen-padding 0;
    grid-template-columns: 1fr;
  }

  .content {
    grid-template-rows: 1fr;
    height: 100%;
  }
}

.fontStyles {
  font-family: "Poppins";
}
