.title {
  align-items: flex-start;
  flex-direction: column !important;
  height: auto;
}

.options {
  margin: 0 auto !important;
  margin-top: 28px !important;
}

.chartContainer {
  padding: 20px 24px 24px 24px;
  width: 100%;
}

.chart {
  display: flex;
  width: 100%;
}

.point {
  color: #302929;
  flex-shrink: 0;
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
  margin-left: 32px;
  position: relative;

  &::before {
    background-color: #1ba94c;
    border-radius: 20px;
    content: " ";
    display: block;
    height: 8px;
    left: -22px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.chartContent {
  flex-grow: 1;
  width: 100%;
}

.pieChart {
  align-items: center;
  display: flex;
  flex-direction: column;
}