/** @format */

body {
  overscroll-behavior-y: none;
}

.smooth-dnd-draggable-wrapper {
  display: block !important;
  overflow: visible !important;
}
:root{--animate-duration:0.3s;}
.animate__animated{-webkit-animation-duration:0.3s;animation-duration:0.3s;-webkit-animation-duration:var(--animate-duration);animation-duration:var(--animate-duration);-webkit-animation-fill-mode:both;animation-fill-mode:both}
@-webkit-keyframes zoomIn{0%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}50%{opacity:1}}
@keyframes zoomIn{0%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}50%{opacity:1}}
@-webkit-keyframes zoomOut{0%{opacity:1}50%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}to{opacity:0}}
@keyframes zoomOut{0%{opacity:1}50%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}to{opacity:0}}
.animate__zoomIn{-webkit-animation-name:zoomIn;animation-name:zoomIn}
.animate__zoomOut{-webkit-animation-name:zoomOut;animation-name:zoomOut}