.addImageContainer {
  border: 1px dashed #a6a6aa;
  border-radius: 3px;
  width: 17rem;
  padding: 1rem;
  text-align: center;
}

.paraOne {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #a6a6aa;
}

.paraTwo {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #cbcbd4;
}

.mediaContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f2f1f3;
  overflow: hidden;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  border-radius: 5px;
  max-width: 25rem;
  max-height: 9.375rem;
  padding: 0.625rem 1rem;
  & img {
    width: 9.375rem;
  }
  margin: 0 auto;
}

.upload {
  color: #794df5 !important;
  border: 1px solid #794df5 !important;
  font-family: "Poppins" !important;
  font-size: 1rem !important;
  text-transform: none !important;
}
