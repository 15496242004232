.timer {
  align-items: center;
  color: #fff;
  display: flex;
  flex-shrink: 0;
  position: relative;

  .number {
    font-family: monospace !important;
  }

  &.regular {
    font-size: 16px;
    width: 78px;

    .separator {
      margin: 0 3px 3px 3px;
    }
  }

  &.compact {
    color: inherit;
    font-size: 24px;
    line-height: 1;
    padding: 8px 16px;
    position: relative;
    
    &::before {
      background-color: currentColor;
      border-radius: 4px;
      content: ' ';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.1;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .separator {
      margin: 0 3px 5px 3px;
    }
  }
}