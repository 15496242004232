#print-resume {
    .flex-box{display: flex; }
    .flex-col{flex-direction: column; }
    .flex-split{border-left: 1px solid #DFE1E6; min-height: 32px; margin: 0 8px 0 4px; }
    .flex-gap-1{gap: 8px; }
    .flex-gap-2{gap: 16px; }
    .flex-gap-3{gap: 24px; }
    .flex-gap-4{gap: 32px; }
    .flex-wrap{flex-wrap: wrap; }
    .flex-100{width: 100%; }
    .flex-50{width: 50%; }
    .flex-60{width: 60%; }
    .flex-1{flex: 1 1 0%; }
    .flex-2{flex: 2 1 0%; }
    .flex-bottom{align-items: flex-end; }
    .flex-middle{align-items: center; }
    .flex-center{justify-content: center; }
    .flex-right{justify-content: flex-end; }
    .flex-self-center{justify-self: center; }
    .flex-self-right{justify-self: flex-end; }
    .w-100 { width: 100%; }
    .h-100 { height: 100%; }
    .absolute {position:absolute; left: 0; top:0;}
    .bg-dim {background-color: rgba(0, 0, 0, 0.16);}
    &.printResume{
        .MuiPaper-root{ max-width: 824px; }
    }
    .MuiPaper-root{ max-width: 780px; width: 100%; }
    .export{
        padding: 18px;
        h3 {font-size: 16px; font-weight: 500; margin-bottom: 16px;}
        .MuiFormGroup-root{margin-bottom: 24px;}
        .favouriteList{
            min-width: 150px;
            margin-bottom: 4px;
            .MuiFormControlLabel-root{margin:0}
            .MuiCheckbox-colorPrimary{padding:0 8px 0 0}
            .MuiCheckbox-colorPrimary.Mui-checked {color: #1BA94C;}
            .MuiFormControlLabel-label{font-size: 14px; font-weight: 500;}
        }
    }
    .header{ background-color: #000000; padding: 12px 16px;
        &.white{background-color: white; color: #000000; border-bottom: 1px solid #DEDDDD;
            h2 {font-size: 20px; color: #000000; }
            button {color: #000000; }
        }
        h2 {font-size: 22px; color: #FFFFFF; margin: 0; text-align: center;}
        button{background-color: transparent; border: 0px solid transparent; padding: 0; color: #FFFFFF; height: 36px;
            &:hover{outline: none; color: red;}
            .bx{font-size: 36px;}
        }
    }
    .buttonGary{background-color: #f8f8f8; border: 1px solid #D6D6D6;padding: 8px 16px; font-size: 14px; border-radius: 3px;text-transform: none;
        &:hover{outline: none; background-color: #f2f2f2; border: 1px solid #D2D2D2;}
        .bx{font-size: 21px;}
    }
    .buttonPrimary{background-color: #794DF5;border: 1px solid #6b3aef;color:#FFFFFF;padding: 8px 16px; font-size: 14px; border-radius: 3px;text-transform: none;
        &:hover{outline: none; background-color: #6b3aef;border: 1px solid #6b3aef;color:#FFFFFF;}
        .bx{font-size: 21px;}
        &.Mui-disabled{opacity: 0.6;}
    }
    .buttonOutlineSuccess{background-color: transparent;border: 1px solid #1BA94C;color:#1BA94C;padding: 8px 16px; font-size: 14px; border-radius: 3px; text-transform: none;line-height: 20px;
        &:hover{outline: none; }
        .bx{font-size: 21px;}
    }
    .buttonOutlineError{background-color: transparent;border: 1px solid #d76255;color:#d76255;padding: 8px 16px; font-size: 14px; border-radius: 3px; text-transform: none;line-height: 20px;
        &:hover{outline: none; }
        .bx{font-size: 21px;}
    }
    .buttonDefault{padding: 8px 16px; font-size: 14px; border-radius: 3px; text-transform: none; line-height: 20px;
        .bx{font-size: 21px;}
    }
    .inputBox{padding: 0px 2px; line-height: 20px; margin: 0;
        input{padding: 6px 12px; 
            &.error{ border-color: #ffbcbc; box-shadow:0 0 2px -1px #ffbcbc; }
        }
        .input{padding: 6px 12px;
            &.error{ border-color: #ffbcbc; box-shadow:0 0 2px -1px #ffbcbc; }
        }
    }
    .inputTextBox{background-color: #f8f8f8; border: 1px solid #D6D6D6; border-radius: 4px; cursor:text; padding: 10px 12px; line-height: 20px;
        &.error{ border-color: #ffbcbc; box-shadow:0 0 2px -1px #ffbcbc; }
        &:hover{outline: none; }
        &:active{outline: none; }
        &:focus{outline: none; }
    }
    .red { color: #E50000; font-weight: 400;}
    .errorTxt { color: #E50000; font-weight: 400; font-size: 12px; margin: 0;}
    .errorTxtAlt { color: #E50000; font-weight: 400; font-size: 12px; margin: 0;}
    .noErrorTxt { font-weight: 400; font-size: 12px; margin: 0;}

    .MuiDialogContent-root {padding: 0; width: 100%;
        .content-container {width:100%; max-width: 580px; margin: 32px auto; background-color: #F6F2FF;
            &.fullpage{ max-width: 100%; margin: 0; }
            .content-bar{width: 100%; padding: 4px; background-color: #794DF5;}
            .resume{padding: 16px; max-width: 100%; width: 100%; margin: 0 auto;
                .section {margin: 24px 0;
                    &.top{margin-top: 0;}
                    &.bottom{margin-bottom: 0;}
                }
                .group {margin: 12px 0;
                    &.top{margin-top: 0;}
                    &.bottom{margin-bottom: 0;}
                }
                .list{
                    .list-item{margin:4px 0;}
                    .list-item-2{margin:8px 0;}
                    &:first-child{margin-top: 0;}
                    &:last-child{margin-bottom: 0;}
                }
                .b {font-weight:bold}
                .br { border-top: 1px solid #D8D5E0; height: 1px; width: 100%;}
                .mb-1{margin-bottom: 8px;}
                .profile-image { width:72px; height:72px; border: 2px solid #794DF5; border-radius: 50%; overflow: hidden; background-color: #f8f8fb; box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;}
                .profile-image img { width:100%; height:100%; background-position: center; }
                .txt-1{font-size: 8px;}
                .txt-2{font-size: 10px;}
                .txt-3{font-size: 12px;}
                .txt-4{font-size: 13px;}
                .txt-5{font-size: 14px;}
                .txt-6{font-size: 15px;}
                .txt-7{font-size: 16px;}
                .txt-8{font-size: 18px;}
                .txt-center{text-align: center;}
                .txt-primary{color: #794DF5;}
                .txt-gray{color: #686868;}
                .txt-black{color: #000000;}
            }
        }
    }
}
#filter-menu {
    .flex-box{display: flex; }
    .flex-col{flex-direction: column; }
    .flex-split{border-left: 1px solid #DFE1E6; min-height: 32px; margin: 0 8px 0 4px; }
    .flex-split.inner{margin: 0 4px 0 4px; }
    .flex-gap-1{gap: 8px; }
    .flex-gap-2{gap: 16px; }
    .flex-gap-3{gap: 24px; }
    .flex-gap-4{gap: 32px; }
    .flex-wrap{flex-wrap: wrap; }
    .flex-100{width: 100%; }
    .flex-50{width: 50%; }
    .flex-60{width: 60%; }
    .flex-1{flex: 1 1 0%; }
    .flex-2{flex: 2 1 0%; }
    .flex-bottom{align-items: flex-end; }
    .flex-middle{align-items: center; }
    .flex-center{justify-content: center; }
    .flex-right{justify-content: flex-end; }
    .flex-self-center{justify-self: center; }
    .flex-self-right{justify-self: flex-end; }
    .available {display: inline-block; width:10px; height: 10px; border-radius: 100%; background-color: limegreen;}
    .MuiPaper-root{max-width: 240px;}
    & > div{background-color: rgba($color: #00235e, $alpha: 0.08) !important;
        &.MuiPaper-root{background-color: #FFFFFF !important;}
    }
    .MuiAccordionSummary-content.Mui-expanded {margin: 12px 0;}
    .MuiAccordionSummary-root { min-height: 44px;
        &.Mui-expanded {min-height: 40px;}
        .MuiButtonBase-root {padding: 10px 12px}
    }
    .MuiAccordion-root.Mui-expanded {margin: 0;border-bottom: 1px solid #dcdcdc;
        &:before {opacity: 1;}
        .MuiCollapse-root {background-color: #dcf3ff; padding-top: 8px;}
    }
    .MuiAccordionSummary-content {font-size: 14px; font-weight: 600;}
    .MuiAccordionDetails-root {padding: 0 16px 0 20px;}
    .checkbox {
        .MuiCheckbox-colorPrimary.Mui-checked {color: #1BA94C;}
        .MuiAccordionSummary-content{font-size: 16px; font-weight: 600;}
        .MuiButtonBase-root{padding: 4px;}
        .MuiSvgIcon-root{width: 20px;height: 18px;}
        .MuiTypography-root{font-size: 14px; color: #3A3A3A;}
    }
    .MuiButton-root { text-transform: none;
        &.MuiButton-text { color: #0377D1;}
        &.MuiButton-contained {background-color: #794DF5; color: #FFFFFF;}
    }
    .head{padding-bottom: 8px; border-bottom: 1px solid rgba(0, 0, 0, 0.12);}
    .foot{padding-top: 8px; border-top: 1px solid rgba(0, 0, 0, 0.12);}
    .count{display:flex;font-size: 13px; width: 24px; height: 24px; line-height: 1; justify-content: center; align-items: center; background-color: #F1F1F5; color: #4F4F4F; border-radius: 3px;}
}
#sims-page {
	width: 100%; height: 100%; position: relative;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    .flex-box{display: flex; }
    .flex-col{flex-direction: column; }
    .flex-split{border-left: 1px solid #DFE1E6; min-height: 32px; margin: 0 8px 0 4px; }
    .flex-gap-1{gap: 8px; }
    .flex-gap-2{gap: 16px; }
    .flex-gap-3{gap: 24px; }
    .flex-gap-4{gap: 32px; }
    .flex-wrap{flex-wrap: wrap; }
    .flex-100{width: 100%; }
    .flex-50{width: 50%; }
    .flex-1{flex: 1 1 0%; }
    .flex-2{flex: 2 1 0%; }
    .flex-bottom{align-items: flex-end; }
    .flex-middle{align-items: center; }
    .flex-center{justify-content: center; }
    .flex-right{justify-content: flex-end; }
    .flex-self-center{justify-self: center; }
    .center{text-align: center;}
    .auto{margin: 0 auto;}
    .br{border-top: 1px solid #E3F2FF; margin: 0 0 16px 0;}
    .search-box{ background-color: #f8f8f8; border: 1px solid #D6D6D6; border-radius: 4px;
        button{border-width: 0; background-color: transparent;display: flex;color: #63606C;padding: 6px 4px 6px 16px;}
        input{border-width: 0; background-color: transparent;outline: none;padding: 10px 15px;}
        input:hover{outline: none;}
        .bx{font-size: 21px; line-height: 1;}
    }
    .buttonGary{background-color: #f8f8f8; border: 1px solid #D6D6D6;padding: 8px 16px; font-size: 14px; border-radius: 3px;
        &:hover{outline: none; background-color: #f2f2f2; border: 1px solid #D2D2D2;}
        .bx{font-size: 21px;}
    }
    .header{
        padding: 20px 16px 16px 16px;
        .headerbox { padding: 24px; background: #FFFFFF; border-radius: 6px; box-shadow: rgba(158, 158, 158, 0.08) 0px 6px 12px -6px, 0px 0px 0px 1px rgba(158, 158, 158, 0.08);
            h1 {font-size: 18px; font-weight: 500; color: #1A1B22;}
        }
    }
    .sims-content{
        padding:0 16px 0 16px; margin-bottom: 16px;
        .contentbox { width: 100%; height: 100%; background: #FFFFFF; border-radius: 6px; box-shadow: rgba(158, 158, 158, 0.08) 0px 6px 12px -6px, 0px 0px 0px 1px rgba(158, 158, 158, 0.08); overflow: hidden;
            .pad {padding: 12px 24px; }
        }
    }
    .tablearea{ width: 100%; max-width: 100%; overflow:auto hidden; background: #FFFFFF;
        table { width:100%; border-collapse:collapse;
            thead { 
                tr { background-color:#C5EAFC;
                    th { padding: 8px 8px; font-size: 13px; font-weight: 500; color: #000000; }
                }
            }
            tbody { 
                tr {
                    td { padding: 12px 8px; font-size: 14px; font-weight: 500; color: #20222F; border-bottom: 1px solid #ECECEC; vertical-align: middle;
                        .rel { min-height: 21px; }
                        .highlight {padding: 4px 8px; border-radius: 2px; line-height: 1; min-width: 64px; text-align: center; border: 1px solid transparent;
                            &.green {background-color: #b9ffe6; border-color: #87f6dc;color: #375253; font-size: 13px;}
                        }
                    }
                    .row-button {border: 0 solid transparent;background-color: transparent; position: absolute; width: 100%; height: 100%; top:0; left: 0;}
                    &:hover {
                        td {background-color: #F6F2FF;}
                    }
                }
            }
        }
    }
    .rel{position: relative };
    .ellips{position: absolute; max-width: 100%; overflow: hidden; white-space: nowrap;  text-overflow: ellipsis };
}

.tableHeader {
    background-color: #C5EAFC;
}
.tableHeaderColor {
    color: #000000;
}
.subTableHead {
    background-color: #F5F9FF;
    display: flex;
    align-items: center;
    padding: 2rem 0.75rem;
    position: relative;
}