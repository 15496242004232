.publicAssessmentTabSwitcher {
    width: 100%; 
    height: 80px;
    display: flex;
    align-items: left;
    justify-content: left;
    background-color: #ffffff;
    border-bottom: 1px solid #D8D6DB;
}
.publicAssessmentTabSwitcher-two {
    width: 100%; 
    height: 144px;
    display: flex;
    align-items: left;
    justify-content: left;
    background-color: #ffffff;
    border-bottom: 1px solid #D8D6DB;
}

.container-tervLogo {
    margin-left: 50px;
    margin-top: 0px;
}

.publicAssessmentTabContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
}

.publicAssessmentTab1 {
    margin-top: 30px;
    width: 550px;
    max-height: auto;
    background-color: #ffffff;
    margin-bottom: 50px;
    padding-bottom: 50px;
}

.tab1Content1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab1Content2 {
    display: flex;
    flex-direction: column;
    margin: 30px 60px 0px 35px;
}

.welcomeContainer {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.assessmentNameContainer {
    margin-left: 5px;
}

.followSteps {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.tab1Content3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 20px 40px 0px 40px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.tab1Content4 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 40px 0px 40px;
}
 
.tab1OR {
    margin: 15px 30px 0px 30px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.tab1Button1 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
}

.publicAssessmentTab2 {
    margin-top: 20px;
    width: 700px;
    max-height: auto;
    background-color: #ffffff;
    margin-bottom: 50px;
    padding-bottom: 50px;
}

.tab2Content1 {
    margin: 0px 100px 0px 100px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tab2HeaderContent {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #4A4851;
}

.container-alreadyHaveAccount {
    display: flex;
}

.container-already {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #19181B;
}

.container-login {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #1BA94C;
}

.container-OrDivision {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.line1 {
    height: 1px;
    width: 290px;
    background: #e2e0e0;
}

.OR {
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #63606C;
}
  
.line2 {
    height: 1px;
    width: 290px;
    background: #e2e0e0;
}

.container-noteForLogin {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 496px;
    height: 256px;
    background-color: #FFF9CC;
    border-radius: 5px;
    margin-left: 100px;
}

.container-noteHeader {
    padding-top: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    font-family: "Poppins", sans-serif;
    color: #3A3A3A;
}

.container-steps {
    display: flex;
}

.container-noteContent {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.01rem;
    font-family: "Poppins", sans-serif;
    color: #686565;
    margin-left: 10px;
    margin-top: 2px;
    margin-right: 20px;
}

.container-infoIcon {
    margin-right: 5px;
    margin-left: 15px;
}

.container-tickIcon {
    margin-left: 20px;
}

.container-defaultPassword {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.01rem;
    font-family: "Poppins", sans-serif;
    color: #3A3A3A;
}

.labelName {
    font-size: 15px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #504F51;
}

.inputField1 .input {
    padding-left: 10px;
}

.inputField1 {
    width: 280px;
    height: 40px;
    background-color: #FAFBFC;
    border: 1px solid #DFE1E6;
    box-sizing: border-box;
    border-radius: 3px;
}

.inputField1:focus {
    outline: none !important;
    border:1px solid #794df5;
}

.inputField2:focus {
    outline: none !important;
    border:1px solid #794df5;
    // box-shadow: 0 0 10px #719ECE;
}

.inputField3:focus {
    outline: none !important;
    border:1px solid #794df5;
    // box-shadow: 0 0 10px #719ECE;
}

.inputField2 {
    width: 600px;
    height: 40px;
    background-color: #FAFBFC;
    border: 1px solid #DFE1E6;
    box-sizing: border-box;
    border-radius: 3px;
}

.inputField3 {
    width: 185px;
    height: 40px;
    background-color: #FAFBFC;
    border: 1px solid #DFE1E6;
    box-sizing: border-box;
    border-radius: 3px;
}

.tab2Content2 {
    margin-top: 20px;
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 40px;
    margin-left: 50px;
    margin-right: 50px;
    max-height: 85px;
}

.tab2ContentDynamic {
    margin-top: 20px;
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 40px;
    margin-left: 50px;
    margin-right: 50px;
    max-height: auto;
}

.tab2Content3 {
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 40px;
    max-height: 85px;
}

.tab2Content4 {
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 20px;
    max-height: 85px;
}

.container-Fields {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.container-Field-DOB {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.container-Field-Gender {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.container-inputAndError {
    display: flex;
    flex-direction: column;
}

.nextButtonContainer {
    margin-top: 50px;
    margin-left: 50px;
    display: flex;
}

.stepInfo {
    margin-top: 7px;
    margin-left: 15px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #707070;
}

.dialogTitle {
    border-top: 4px solid #794df5;
    text-align: center;
    height: 90px;
  }
  
  .dialogTitleHeader {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  
  .dialogSubTitle {
    text-align: center;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
  
  .dialogContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 500px;
    height: 300px;
  }
  
  .dialogContent::-webkit-scrollbar {
    display: none;
  }
  
  .signInPwdContainer {
    margin-top: 10px;
  }
  
  .forgotPassword {
    margin-left: 270px;
    text-decoration: underline;
    color: #0377d1;
    cursor: pointer;
  }
  
  .dialogAction {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .textField {
    input {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .signInButton {
    width: 400px;
    height: 50px;
  }
  
  .publicAssessmentTab3 {
    margin-top: 30px;
    width: 993px;
    height: 718px;
    background-color: #ffffff;
    margin-bottom: 50px;
}
 .customerSide {
    display: flex;
    flex-direction: column;
    padding-top: 20%;
    align-items: center;
 }
 .titlePara {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #63606C;
 }
 .customerTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #19181B;
 }
 .customerContent {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #63606C;
 }

.flex-container {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    
    .flex-item {
      flex: 1 1 0%;
      height: 100%;
      box-sizing: border-box; 
    }
}
.newDetails {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #19181B;
}