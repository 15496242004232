.page {
  display: flex;
  flex-direction: column;
  padding: 44px 32px 60px 32px;
}

.pageTitle {
  color: #20222f;
  font-size: 24px;
  margin-bottom: 24px;
  margin-top: 0;
}

.pageContent {
  background-color: #fff;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 4px 6px -4px rgba(24, 39, 75, 0.12);
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  padding: 48px 0;
  position: relative;
  width: 100%;
}

.packagesDetails {
  border-right: 1px solid #eeeeee;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  padding: 0 32px;
  width: 62.5%;
}

.card {
  margin: 0 auto;
}

.summaryPracticeCard {
  display: flex;
  margin-bottom: 28px;

  img {
    border-radius: 8px;
    height: 80px;
    margin-right: 20px;
    object-fit: cover;
    object-position: center;
    width: 100px;
  }
}

.summaryPracticeCardTexts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px 0;
  width: calc(100% - 120px);
}

.summaryPracticeCardName {
  color: #20222f;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.summaryPracticeCardPrice {
  align-items: center;
  display: flex;
}

.summaryPracticeCardOriginalPrice {
  color: #0c0b0d;
  font-size: 20px;
  font-weight: 600;
}

.summaryPracticeCardStrikingPrice {
  color: #3a3a3a;
  font-size: 16px;
  line-height: 24px;
  text-decoration: line-through;
}

.orderDetails {
  padding: 0 32px;
  position: sticky;
  top: 0;
  width: 37.5%;
}

.orderDetailsContent {
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
}

.title {
  color: #262626;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
}

.description {
  color: #504f51;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
  margin-top: 8px;
}

.overview {
  align-items: center;
  border-top: 1px solid #eeeeee;
  display: flex;
  margin-top: 32px;
  padding: 24px 0 0 0;
}

.overviewTotalText {
  color: #000000;
  font-size: 18px;
}

.overviewTotal {
  color: #0c0b0d;
  font-size: 24px;
  font-weight: 500;
}

.noPackagesContainer {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.noPackages {
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(83, 80, 80, 0.15);
  color: #7d7a7a;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 220px;
  justify-content: center;
  line-height: 22px;
  text-align: center;
  width: 220px;
}