/** @format */

.main {
  width: 100%;
}
.joinGroupContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.75rem;
  // background: url("../../../assets/join-group-bg.png") no-repeat center;
  // background-size: cover;
  width: 100%;
  height: auto;
  padding: 3rem 2rem;
}

.socialMediaItem {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  // place-items: center;
  align-items: center;
  justify-content: center;
  background-color: #794DF5;
  // gap: 1%;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.socialMediaItem:hover {
  background-color: #541BF5;
}

@media (max-width: 960px) {
  .joinGroupContainer {
    flex-direction: column;
  }
  .joinGroupActions {
    min-width: 60%;
  }
}

.joinGroupList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: 0;
}
.listItem {
  & > span {
    font-weight: 500;
    font-size: 1rem;
    color: #4a4851;
    margin-left: 0.8125rem;
    display: flex;
  }
  list-style-type: none;
  display: flex;
}

.joinGroupHeader {
  font-weight: 600;
  font-size: 1.25rem;
  color: #323036;
  margin-bottom: 2rem;
}

.joinGroupActions {
  background: #ffffff;
  border: 1px solid #f2f1f3;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1.875rem 1.872rem;
}

.joinGroupBoxDesc {
  font-weight: 500;
  font-size: 1rem;
  color: #7c7887;
  margin: 0;
  text-align: center;
}

.buttonDiv {
  display: flex;
  gap: 1rem;
}

@media (max-width: 580px) {
  .joinGroupContainer {
    padding: 2rem 1rem;
  }
}
