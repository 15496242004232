.linkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.linkHeader {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #323036;
    margin-left: 1rem;
}
.linkButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-decoration-line: underline;
    color: #794DF5;
    cursor: pointer;
    // margin-left: 12rem;
}
.comingSoonContainer {
    width: 158px;
    height: 48px;
    background: #7C7887;
    border-radius: 5px;
    text-align: center;
    padding: 6px;
    margin-top: 5rem;
    align-items: center;
}
.comingSoonText {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
}