/* .course-continue{
  background: #000;
  height: 600px !important;
}

@media (max-width: 480px) {
  .course-continue{
    background: #000;
    height: 200px !important;
  }
  .plyr--video{
    height: 200px !important;
  }
  
}

.plyr--video{
  height: 600px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
} */

.subTitleMenu-asdf h2 {
  margin: 0;
  font-size: 14px;
}
.sidebar-asdf {
  position: relative;
  flex: 1 1 0%;
  overflow: hidden;
  height: 100%;
}

.sidebar-asdf .video-asdf {
  position: relative;
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  height: 100%;
  margin-right: 0;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 600ms;
}

.sidebar-asdf.open .video-asdf {
  margin-right: 370px;
}
.controls-side-asdf {
  height: 100%;
  position: absolute;
  right: -370px;
  top: 0;
  width: 370px;
  overflow: hidden;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 600ms;
}

.controls-side-asdf .MuiContainer-root {
  padding: 0;
}
.controls-side-asdf .MuiTabs-root .MuiButtonBase-root {
  text-transform: none;
  min-width: 120px;
}
.sidebar-asdf.open .controls-side-asdf {
  right: 0;
}

.course-continue .plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  /* height: 0; */
  padding-bottom: 36.25% !important;
}

.course-continue {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  flex: 1 1 0%;
}
.course-continueAudio {
  background-color: rgba(121, 77, 245, 0.8);
}

.card-bodys {
  background: #f6f6f7 !important;
  border: 1px solid #d8d6db !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  margin-top: 5px;
  padding: 10px;
}
.cards {
  max-width: 700px;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .cards {
    width: 700px;
  }
}

.collapse {
  margin-top: 20px;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  color: green;
  min-width: 50px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
}

.menu {
  position: relative;
  border-left: 3px solid #c4c4c4;
  bottom: 1px;
}
.content-search {
  width: 100%;
  height: 40px;
  position: relative;
}

/* .card{
  width: 700px;
} */

.discussion-accordion {
  width: 600px;
  padding: 0px !important;
}

/* .discussion-accordion .list-group-item {
  padding: 0px !important;
} */

@media (min-width: 1025px) and (max-width: 1280px) {
  #wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
    background-color: #000;
  }
  #wrapper .plyr__video-wrapper {
    height: 100%;
  }
  #wrapper .plyr__video-wrapper iframe {
    width: 100%;
    height: 100%;
  }
}

.add-note-button {
  width: 400px;
  height: 49px;
  border: 2px solid #d1f5dd !important;
  border-radius: 3px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 29px;
  color: #1ba94c !important;
  text-transform: none !important;
}
.add-discussion-button {
  width: 400px;
  height: 49px;
  border: 2px solid #d1f5dd !important;
  border-radius: 3px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 29px;
  color: #1ba94c !important;
  text-transform: none !important;
}
.show-note-button {
  width: 400px;
  height: 49px;
  border-radius: 3px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 29px;
  color: #ffff !important;
  background: #794df5 !important;
  text-transform: none !important;
}
.show-discussion-button {
  width: 400px;
  height: 49px;
  border-radius: 3px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 29px;
  color: #f6f6f8 !important;
  border: 2px solid #794df5 !important;
  background: #794df5 !important;
  text-transform: none !important;
}

.back-button {
  width: 400px;
  height: 49px;
  border-radius: 3px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 29px;
  color: #63606c !important;
  border: 2px solid #63606c !important;
  border-radius: 3px;
  text-transform: none !important;
}

.note-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #323036;
  position: relative;
  left: 10px;
  height: 23px;
}

.drag-button {
  position: relative;
  text-align: end;
  background: black;
  color: #ffff;
}
#overlay {
  position: absolute;
  top: 30px;
  bottom: 0px;
  font-size: 20px;
  background-color: #794df5;
  padding: 12px 30px;
  height: 50px;
  /* z-index: 2147483647; */
  color: #ffff;
  right: 0px;
}

#overlay {
  cursor: pointer;
}

#v {
  z-index: 1;
}

/* .MuiDrawer-paper {
  max-width: 400px !important;
} */

.hide-overlay {
  position: relative;
  /* text-align: end;
  top: 20px;
  color: #FFF; */
  font-size: 20px;
  background-color: #794df5;
  padding: 10px 0;
  z-index: 2147483647;
  color: #ffff;
  cursor: pointer;
}

.tabpanel {
  height: 100%;
  overflow: hidden;
}
.tabpanel .MuiListItemAvatar-root {
  min-width: 32px;
  overflow: hidden;
}
.tabpanel > div {
  padding: 0;
  height: 100%;
  overflow: hidden;
}
.tabpanel > div > div {
  height: 100%;
  overflow: hidden;
}

.tabpanel .MuiPaper-root {
  box-shadow: none;
}
.tabpanel .MuiPaper-root {
  box-shadow: none;
}

.tabpanel .discussionItemTitle h4 {
  font-size: 14px;
  font-weight: 500;
  color: #323036;
}
.tabpanel .discussionItemTitle p {
  font-size: 12px;
  color: #96939f;
}
.tabpanel .discussionItemFooter > * {
  text-transform: none;
}
.tabpanel .discussionItemBody {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.75;
}
.tabpanel .discussionItemBody h5 {
  font-size: 12px;
}

.drawer {
  position: relative;
  width: 370px;
  height: 100%;
  overflow: hidden;
  background: #fff;

  /* left: 996px;
  top: 60px;
  overflow-y: scroll; */
}

.card-img-overlay {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0px !important;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 0);
}

.discussion-color {
  color: #794df5 !important;
  text-decoration: underline !important;
}
.card-style {
  width: 700px;
}
.rdw-editor-main {
  border: 1px solid #f6f6f6;
  height: 100px !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .note-sub {
    display: inline-block;
  }
  .add-note-button {
    width: 262px;
    height: auto;
  }
  .show-note-button {
    width: 262px;
    height: auto;
  }
  #overlay {
    position: absolute;
    font-size: 14px;
    background-color: #794df5;
    padding: 12px 15px;
    height: 35px;
    color: #ffff;
    /* top: 68px; */
    right: 20px;
  }
  .drawer {
    max-width: 350px;
  }
  .show-discussion-button {
    width: 262px;
    height: auto;
  }
  .add-discussion-button {
    width: 262px;
    height: auto;
  }
  .back-button {
    width: 262px;
    height: auto;
  }
  .card-style {
    width: auto;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  #overlay {
    position: absolute;
    font-size: 14px;
    background-color: #794df5;
    padding: 12px 15px;
    height: 35px;
    color: #ffff;
    right: 16px;
    top: 12px;
  }
  #v {
    z-index: 1;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  #wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
    background-color: #000;
  }
  #wrapper .plyr__video-wrapper {
    height: 100%;
  }
  #wrapper .plyr__video-wrapper iframe {
    width: 100%;
    height: 100%;
  }
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.breadcrumbs-style {
  background: #000000;
}
.header-Style {
  background: #20242d;
}
.breadcrumbs-style .MuiBreadcrumbs-separator {
  display: flex;
  margin-left: 8px;
  user-select: none;
  margin-right: 8px;
  color: white !important;
}

/* body {
  background-image: url("https://subtlepatterns.com/patterns/crissXcross.png");
  margin: 0px;
  padding: 0px;
} */

#fakeHeight {
  height: 6000px;
  width: 1px;
}

#scrollPercentLabel {
  font-family: Impact;
  font-size: 50px;
  color: #2b2b2b;
  background: rgba(255, 255, 255, 0.5);
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 8px 8px 5px rgba(20, 20, 20, 1);
  border-radius: 15px;
}

.detail-coursecontent .MuiTabs-root {
  background: white !important;
  border-bottom: 1px solid #d9d9d9;
}

.detail-coursecontent .PrivateTabIndicator-root-52 {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: relative;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.detail-coursecontent .MuiTab-textColorPrimary.Mui-selected {
  color: #1ba94c !important;
  background: #dbffe7 !important;
}
.detail-coursecontent button:focus {
  outline: none !important;
  outline: none !important;
}
.detail-coursecontent .MuiTabs-indicator {
  background-color: #1ba94c !important;
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
  height: 4px !important;
}
.detail-coursecontent .PrivateTabIndicator-colorPrimary-35 {
  background-color: #1ba94c !important;
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
  height: 4px !important;
}
.detail-coursecontent .PrivateTabIndicator-colorPrimary-41 {
  background-color: #1ba94c !important;
}
.detail-coursecontent .PrivateTabIndicator-root-34 {
  width: 100%;
  bottom: 0px;
  height: 4px !important;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.detail-coursecontent .MuiTab-wrapper {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.discussion-accordion .list-group-item {
  padding: 0 !important;
}
audio::-webkit-media-controls-panel {
  background-color: #ffffff;
}

.contentHeighlight {
  background-color: #c5eafc;
}

/* .view180 .MuiAccordionDetails-root{
  padding: 0px !important;
}
.view180 .MuiAccordionSummary-root{
  height: 60px !important;
} */
.avatar {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  height: 40px;
  object-fit: cover;
  object-position: center;
  width: 40px;
}

.headerCourse {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.audioContainer {
  width: 900px;
  height: 400px;
}
.audioContainerresponsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  padding-left: 15%;
}
.audioplayerSize {
  padding-left: 50px;
  width: 770px;
}
.audioplayerSizeMobile {
  width: 330px;
}
.contentHeader {
  color: #ffffff;
  font-size: 20px;
}
.contentHeaderMobile {
  color: #ffffff;
  font-size: 14px;
}
@media screen and (min-width: 1080px) and (max-width: 1250px){
  .audioContainerresponsive{
    padding-left: 0%; 
    align-content: center;
    justify-content: center;
    align-items: center;
  } 
}