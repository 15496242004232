@import "../../../../assets/scss/mixins.scss";

.content {
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
}

.solutionSection {
  flex: 1 !important;
  position: relative;

  &.smallScreen {
    overflow: hidden !important;
  }

  &.mediumScreen {
    height: 640px;
  }
}

.sectionTitle {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 8px !important;
  margin-top: 24px !important;
}

.inputControl {
  background-color: #ECEFFA;
  border: 2px solid #ECEFFA;
  border-radius: 6px;
  margin-bottom: 16px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 8px 16px 8px 8px;
  width: 100%;

  &.selected {
    border-color: #6788fd !important;
  }

  &:hover {
    background-color: #d1dbff;
    border-color: #d1dbff;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
}

.inputControlInput {
  margin-right: 6px;
}

.inputControlLabel {
  font-weight: 500 !important;
  overflow: hidden;
  padding: 10px 0px;
  text-overflow: ellipsis;
  word-wrap: break-word;

  * {
    background-color: transparent !important;
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: normal !important;
    font-weight: inherit !important;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  img {
    border-radius: 4px !important;
    max-height: 72px !important;
    max-width: 80% !important;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
    position: relative;
  }
}

.noPadding {
  padding: 0 !important;
}

.codeSolution,
.descriptionAnswer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.codeSolution {
  .codeEditorWrapper {
    height: calc(100% - 50px);
  }
}

.descriptionAnswer {
  .codeEditorWrapper {
    height: 100%;
  }
}

.Passed {
width: 253px;
height: 20px;
padding-left: 15px;
position: absolute;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
color: green;
}

.Failed{
  width: 253px;
  height: 20px;
  padding-left: 15px;
  position: absolute;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: red;
}

.codeEditorWrapper {
  position: relative;

  &.outputVisible {
    height: calc(100% - 240px) !important;
  }
}

.outputEditorWrapper {
  background-color: #F2F1F3;
  border: 1px solid #D8D6DB;
  height: 50px;
  position: relative;

  &.outputVisible {
    height: 240px !important;
  }
}

.outputEditorWrapper,
.codeEditorWrapper {
  transition-duration: 300ms;
  transition-property: height;
}

.outputEditorContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 50px);
  padding: 20px;
  position: relative;
}

.languageSelectorBackdrop {
  background-color: #0005;
}

.submissionMessage {
  background-color: white;
  padding-left: 8px;
padding-right:  8px;
  padding-bottom: 8px; 
}

.testCase-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: scroll;
}