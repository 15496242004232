.questionTitle {
    background-color: #F9F9F9;
    width: 150px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    height: 43px;
    text-align: center;
    border-bottom: 1px solid #D9D9D9;
    cursor: pointer;
}
.questionCodeSec {
    margin-top: 40px;
    margin-bottom: 40px;
}
.questionSection {
    background: #F7F9FF;
    padding: 10px;
    font-size: 16px !important;
    color: #323036 !important;
}
.questionBox {
    background-color: #F7F9FF;
    height: 437px;
    padding: 10px;
    font-size: 16px;
    color: #323036;
    overflow: auto;
}
.questionBoxTwo {
    background-color: #F7F9FF;
    padding: 20px 10px 10px 10px;
    font-size: 16px;
    color: #323036;
}
.activeTab {
    background-color: #DEFFE9;
    border-bottom: 3px solid #03832F;
    color: #03832F;
    width: 150px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    height: 43px;
    text-align: center;
    cursor: pointer;
}
.wrong {
    background: #FED9D4;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 147px;
    height: 68px;
    font-size: 16px;
    & .bold {
        font-weight: 600;
    }
}
.right {
    background: #D4FCC4;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 147px;
    height: 68px;
    font-size: 16px;
    & .bold {
        font-weight: 600;
    }
}
.unAttempted {
    background: #e6e7e5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: fit-content;
    height: 68px;
    font-size: 16px;
    & .bold {
        font-weight: 600;
    }
}

.option {
    width: 677px;
    min-height: 50px;
    background: #EEEEEE;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 20px;
    & p {
        color: #111111;
        font-size: 14px;
        font-weight: 500;
        width: 500px;
        margin-bottom: 0;
    }
    & span {
        color: #111111;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
    }
    & p img {
        max-width: 100px;
        max-height: 100px;
    }
}
.optionsRight {
    width: 677px;
    min-height: 50px;
    background: #A3FFC2;
    border: 1px solid #1BA94C;
    box-sizing: border-box;
    }
.optionsWrong {
    width: 677px;
    min-height: 50px;
    background: #FFC5C5;
    border: 1px solid #D42216;
    box-sizing: border-box;
    }
.optionRow {
    display: flex;
    align-items: center;
    & span {
    color: #1A1B22;
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    margin-left: 20px;
    }
}
.mcQuestions {
    margin-top: 30px;
}