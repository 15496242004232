/** @format */

@import "./Home.scss";

html {
  width: 100%;
}
.home__main {
  background-color: $HomeBgWhite;
}

.content__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: $HomeBgWhite;
  // margin: 0 6.875rem;
}

.cookieContainer {
  background: #0b2239;
  padding: 0.5rem 1rem;
  margin: 2rem;
  font-weight: 400;
}

.cookieButtonStyle {
  background-color: #1ba94c;
  color: #fff;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 3px;
}
