.page {
    display: flex;
    flex-direction: column;
  }
  .tabContent {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .userInfo {
    align-items: baseline;
    display: flex;
    justify-content: flex-start;
  }
  .userInfoName {
    color: #1a1b22;
    font-size: 18px;
  }
  .userInfoDot {
    background-color: #c4c4c4;
    border-radius: 12px;
    height: 8px;
    margin: 0 8px 4px 8px;
    width: 8px;
  }
  .userInfoDepartment {
    color: #7a7981;
    font-size: 16px;
  }
  .completedStatus,
  .inProgressStatus,
  .incompleteStatus {
    display: flex;
    position: relative;
    &::before {
      border-radius: 12px;
      content: " ";
      display: block;
      height: 8px;
      margin-right: 6px;
      margin-top: 5px;
      position: relative;
      width: 8px;
    }
  }
  .completedStatus {
    &::before {
      background-color: #1ba94c;
    }
  }
  .inProgressStatus {
    &::before {
      background-color: #eeb60c;
    }
  }
  .incompleteStatus {
    &::before {
      background-color: #d42216;
    }
  }
  .dashboard {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 40px;
  }
  .dashboardInfoBox {
    align-items: center;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
    display: flex;
    justify-content: space-between;
    padding: 24px;
    &:nth-child(1) {
      .dashboardInfoBoxIcon {
        background: #eceffa;
      }
    }
    &:nth-child(2) {
      .dashboardInfoBoxIcon {
        background: #faefec;
      }
    }
  }
  .dashboardInfoBoxTitle {
    color: #20222f;
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
  }
  .dashboardInfoBoxDescription {
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .dashboardHeader {
    color: #1A1B22;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
  .dashboardInfoBoxIcon {
    align-items: center;
    border-radius: 5px;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
  .customerSelectContainer {
    background-color: #fff;
    // display: flex;
    // justify-content: flex-start;
    padding: 16px;
    z-index: 100;
  }
  