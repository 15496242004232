.container {
  display: flex;
  padding: 0 12px;
}

.childOne {
  flex: 2;

  & > h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.15;
    color: #323036;
    margin-bottom: 12px;
  }

  & > p {
    font-size: 14px;
    line-height: 1.15;
    color: #7c7887;
    margin: 0;
    max-width: 16.625rem;
  }
}

.childTwo {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.divider {
  background-color: #e3f2ff;
  opacity: 0.4;
}
