/** @format */

.spacing {
  min-width: 16px;
}
.safePayment {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.8125rem 6.875rem;
  background-color: 
  #261B4E;
}
.flex {
  display: flex;
  align-items: center;
}
.fields {
  display: flex;
  align-items: center;
  flex: 1 1 0%;
}
.spaceAround {
  justify-content: space-around;
}
.spaceBetween {
  justify-content: space-between;
}
.start {
  justify-content: start;
}
.center {
  justify-content: center;
}
.end {
  justify-content: end;
}
.btn {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: #1ba94c;
  color: #fff;
  padding: 0.625rem 2rem;
  border-radius: 2rem;
}

@media (max-width: 1330px) {
  .safePayment {
    padding: 0.8rem 2rem;
  }
}

@media (max-width: 980px) {
  .safePayment {
    display: block;
  }
  .flex {
    margin: 1rem 0 2rem 0;
    justify-content: center;
  }
  .fields {
    flex: 0 0 0%;
  }
}

@media (max-width: 580px) {
  .safePayment {
    padding: 0 1rem;
  }
  .safePayment {
    display: block;
  }
  .flex {
    margin: 1rem 0;
    justify-content: center;
  }
  .xsFlex {
    margin: 1rem 0;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }
  .xsNoFlex {
    display: block !important;
  }
  .fields {
    margin: 1rem 0;
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    flex-wrap: wrap;
    & > svg {
      margin: 1rem 0;
    }
  }
  .xsHide {
    display: none;
  }
}
.footerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 71%;
  img {
    height: auto;
    max-height: 32px;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
  }
}
