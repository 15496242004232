$colorBlack: #19181b;

.stepContainer {
  display: grid;
  place-items: center;

  svg {
    margin-bottom: 1.5rem;
  }
}

.introTitle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.625rem;
  color: $colorBlack;
}

.introDesc {
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: $colorBlack;
}

.actionItems {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.skipButton {
  border: 0;
  background-color: transparent;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #63606c;
}
