.contentTitle {
    color: #323036;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
}
.practiceContainer {
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
}
.customerLogo {
    border-radius: 4px;
    height: 45px;
    margin-right: 16px;
    max-width: 160px;
    object-fit: contain;
    object-position: center;
  }

.practiceInfoContainer {
    // width: 800px;
    // height: 287px;
    height: auto;
    background: #F6F6F7;
    border: 1px solid #F2F1F3;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin: 3rem;
    margin-top: 1rem;
    padding: 2rem;
}

.practiceTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #19181B;
}

.practiceDesc {
    //width: 742px;
    //height: 69px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #4A4851;   
}

.practiceQuesStyle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #19181B;
    margin-top: 5px;
    margin-left: 1rem;
}

.completedSuccessContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 225px;
    background: rgba(8, 154, 121, 0.07);
    border-radius: 3px;
    margin-top: 1rem;
}

.completedSuccessText {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #089A79;
}

.failureContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 653px;
    background: rgba(222, 84, 69, 0.05);
    margin-top: 0.5rem;
}

.failureText {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DE5445;
}

.meetingScheduledContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 225px;
    background: rgba(217, 146, 41, 0.07);;
    border-radius: 3px;
    margin-top: 1rem;
}

.meetingScheduledText {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #D99229;
}
.pdfSize {
    
    margin-top: 5px;
    padding-bottom: 1%;
    margin-bottom: 2px;
    display:flex;
    justify-content: center;
    // margin: 0 auto;
    // width: 80% !important;
    height: 100%;
    max-height: 700px;
    overflow: auto;
    scrollbar-color: #6969dd #e0e0e0;
    scrollbar-width: thin;
}

.pdfAlign {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    height: 100%;
}