.main {
  padding: 1rem 1.5rem;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  border-radius: 10px;
  width: 65rem;
  background-color: #fff;
  display: flex;
  margin: 4rem auto;
  // margin-top: 2rem;

}
.mainMobile {
  padding: 1rem 1.5rem;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.boxOne {
  border-right: 1px solid #eeeeee;
  padding-right: 1.5rem;
  h4 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #19181b;
    max-height: 9.5rem;
  }

  h4,
  p {
    width: 640px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.4375rem;
    color: #5c5f65;
    margin-top: 1rem;
    max-height: 9rem;
  }
}

.boxTwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding-left: 1.5rem;
}
.boxTwoMobile {
  width: 100%;
  padding-top: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  align-items: center;
}

.iconsWrapper {
  display: flex;
  gap: 2.5rem;
  margin-top: 1.5rem;
  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
.iconsWrapperMobile {
  gap: 2.5rem;
  margin-top: 1.5rem;
  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-top: 10px;
  }
}

.iconBox {
  background: #f2f1f3;
  border-radius: 5px;
  padding: 0.4375rem;
}

.descBox,
.strikedPrice,
.originalPrice,
.savePercent,
.priceBox {
  white-space: nowrap;
}

.descBox {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  color: #19181b;
}

.strikedPrice {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #0c0b0d;
}

.originalPrice {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration-line: line-through;
  color: #f91818;
}

.savePercent {
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.9375rem;
  color: #545260;
}

.buttonBox {
  display: flex;
}

.buyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.progressBoxWraper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
