.main {
  display: grid;
  place-items: center;
  height: 70vh;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 4rem;
    background: #fffdeb;
    border: 1px solid #eeeeee;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);

    .notFound {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.625rem;
      color: #d42216;
    }

    .linkNotFound {
      font-size: 1.25rem;
      line-height: 2rem;
      color: #63606c;
    }

    h4 {
      font-weight: 600;
      font-size: 3rem;
      line-height: 4.5rem;
      text-align: center;
      color: #323036;
    }
  }
}
