.leaderboard {
  background-color: #fff;
  height: 100%;
  padding: 16px;
  width: 100%;
}

.leaderboardTitle {
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 16px;
  font-weight: 500;
}

.leaderboardTop3 {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 0 16px;
  width: 100%;
}

.leaderboardViewAllButton {
  background-color: transparent;
  border: none;
  color: #0039ff;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}

.leaderboardDescription1 {
  color: #000;
  display: flex;
  font-size: 17px;
  font-weight: 400;
  justify-content: center;
  line-height: 1.75;
}

.leaderboardDescription2 {
  color: #858585;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: 1.5;
}

.leaderboardFooter {
  align-items: center;
  display: flex;
  justify-content: center;
}