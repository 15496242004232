.wrappedCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  align-items: center;
  min-height: 50vh;
}

.courseCard {
  width: 260px;
  height: 320px;
  background: #ffffff;
  // box-shadow: 0px 0px 25px rgba(170, 170, 170, 0.25);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08),
    0 -1px 4px -1px rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  transition: transform 0.2s ease-out;
  position: relative;
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.08);
    .cardButtons {
      div {
        transform: scale(0.97);
      }
    }
  }
}

.courseCardImg {
  width: 100%;
  height: 140px;
  border-radius: 6px 6px 0 0;
  position: relative;
  overflow: hidden;
}

.courseCardBgImg {
  position: absolute;
  width: 100%;
  height: 100%;
  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.courseCardContent {
  padding: 12px 20px 16px 20px;
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 6px 6px;
}

.courseCardDetails {
  display: flex;
  gap: 12px;
  & > span {
    display: flex;
    align-items: center;
    line-height: 1;
  }
}

.courseCardName {
  font-weight: 600;
  font-size: 16px;
  color: #323036;
  margin: 0;
  margin-bottom: 0.625rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  div {
    padding: 0.3rem;
    border: 1px solid #784df4;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 40px;
    opacity: 0.75;
    &:hover {
      border: 1px solid #784df4;
      opacity: 1;
    }
  }
}

.tag {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 500;
  border-radius: 0px 5px 0 5px;
  max-width: 105px;
  color: #fff;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 25px;
  z-index: 1;
}

.tagAP {
  background-color: #039618;
}

.tagDF {
  background-color: #784df5;
}

.tagPN {
  background-color: #ed700d;
}
.tagRJ {
  background-color: #da3208;
}
