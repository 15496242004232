$TextDark: #323036;

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem;
  & > * {
    width: 100%;
  }
}

.changeBlock {
  background-color: #edf9ff;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.625rem;
  width: 100%;
  color: #323036;
  margin: 0;
  & :nth-of-type(2) {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
    text-decoration-line: underline;
    color: #3a15a4;
    cursor: pointer;
    margin-left: 2.5rem;
  }
}

.nameInput {
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  outline: none;
  border-radius: 3px;
  height: 2.5rem;
  width: 100%;
  padding: 0 0.5em;
  &:focus {
    border: 1px solid #794df5;
  }
}

.packageContainer {
  width: 31rem;
  height: 83px;
  border: 2px dashed #a6a6aa;
  box-sizing: border-box;
  border-radius: 3px;
}

.packageBox {
  width: 400px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  margin: 15px 43px;
  padding: 1rem;
  display: flex;
}

.packageNameStyle {
  width: 250px;
  height: 23px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: #323036;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.editButton {
  cursor: pointer;
  margin-left: 3rem;
}

.deleteButton {
  cursor: pointer;
  margin-left: 1rem;
}

.orDivision {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  // width: 32rem;
  // font-size: 14px;
  // font-family: Poppins;
  // font-style: normal;
  // font-weight: normal;
}

.line1 {
  height: 1px;
  width: 235px !important;
  background: #e2e0e0;
}

.line2 {
  height: 1px;
  width: 230px !important;
  background: #e2e0e0;
}

.innerform__Group {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}
.fCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexVCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
}
.addNewQuiz {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  & h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    margin: 0;
  }
  & p {
    font-size: 14px;
    line-height: 1;
    margin: 0 0 16px 0;
  }
}
.form__Group {
  background: #f6f6f7;
  border: 1px solid #f2f1f3;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1rem 0;
  padding: 1rem;
}
.sec__no {
  flex-shrink: 1;
  border-radius: 4px 0 0 4px;
  padding: 0.5rem 1.0625rem;
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
  color: $TextDark;
  text-transform: uppercase;
  background-color: #ffffff;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec__name {
  flex-shrink: 1;
  background: rgba(121, 77, 245, 0.2);
  border-radius: 4px 0 0 4px;
  padding: 0.5rem 1.0625rem;
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
  color: $TextDark;
  text-transform: uppercase;
  white-space: nowrap;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec__duration {
  padding: 4px 8px;
  color: #7c7887;
  display: flex;
  min-width: 120px;
  justify-content: center;
  border-width: 0;
  background-color: transparent;
  white-space: nowrap;
  font-size: 14px;
}
.sec__quizCount {
  background: #ecfff2;
  border-radius: 4px;
  padding: 4px 8px;
  color: #1ba94c;
  display: flex;
  min-width: 120px;
  white-space: nowrap;
  justify-content: center;
  font-size: 14px;
}
.sec__quesCount {
  background: #fff6e5;
  border-radius: 4px;
  padding: 4px 8px;
  color: #eeb60c;
  display: flex;
  min-width: 120px;
  white-space: nowrap;
  justify-content: center;
  font-size: 14px;
}
.button__Group {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.sec__sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 980px;
  & > div {
    width: 100%;
  }
}
.sec__innerSectionItem {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  flex-direction: column;
  & > span {
    display: block;
    width: 100%;
    margin: -0.5rem 0 0.5rem 0;
  }
}
.sec__sectionItem {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.sec__sectionItemEditableInput {
  width: 100%;
  display: flex;
  flex: 1 1 0%;
  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1 1 0%;
    border-radius: 3px;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    & > input {
      width: 100%;
      height: 100%;
      border: 0;
      background-color: #fff;
      padding: 0 0.875rem;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.625rem;
      color: $TextDark;
      border: 1px solid #ffffff;
      border-radius: 0 4px 4px 0;
      outline: none;
      &:focus {
        border: 1px solid #ddd4f7;
        outline: none;
      }
      &:active {
        border: 1px solid #ddd4f7;
        outline: none;
      }
    }
  }
}
.sec__sectionItemEditableWithCtrl {
  width: 100%;
  display: flex;
  gap: 1rem;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  background-color: #fff;
  padding-right: 1rem;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}
.sec__sectionItemEditable {
  width: 100%;
  display: flex;
  gap: 1rem;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  background-color: #fff;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}
.sec__ctrls {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.videoQuiz__popup{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.sec__sectionItemEditIcon {
  cursor: pointer;
  min-width: 48px;
  text-align: center;
}
.videoQuiz__container {
  width: 100%;
  height: 100%;
  padding-bottom:13px;
}
.fullSize {
  width: 100%;
  height: 100%;
}
.vQuizBody {
  flex: 1 1 0%;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  overflow: auto;
  background-color: #ffffff;
  & > * {
    background-color: #ffffff;
  }
}
.vQuizFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  background: #f6f6f7;
  border-top: 1px solid #dee0e3;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
}
