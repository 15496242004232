.submitButton {
    background-color: #794df5 !important;
    font-family: "Poppins", "sans-serif" !important;
    text-transform: capitalize !important;
  }

.cancelButton {
    background-color: #F2F1F3 !important;
    font-family: "Poppins", "sans-serif" !important;
    color: #63606C !important;
    margin-left: 20px !important;
    text-transform: capitalize !important;
}

.contents {
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #63606C;
    width: 366px;
}

