.quill {
    position: relative !important;
    min-height: 245px !important;
}
.ql-container {
    border: 1px solid #ced4da !important;
    border-bottom: none !important;
}
.ql-editor {
    max-height: 157px;
    min-height: 157px !important;
    overflow: auto;
}
.ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: 1px solid #ced4da !important;
}
.ql-toolbar.ql-snow .ql-picker-label{
    border: 1px solid #EAEAEA !important;
    box-sizing: border-box;
    border-radius: 3px;
    color: #000000;
}
.ql-snow .ql-stroke {
    stroke: #000000;
}
.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button,
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 5px;
    margin-bottom: 5px;
}