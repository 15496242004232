$TextDark: #323036;

.sec__main {
  background-color: #fff;
  border: 1px solid #f2f1f3;
  border-radius: 5px;
  padding: 1.875rem 1.5rem;
}

.sec__header,
.sec__desc {
  text-align: center;
  color: $TextDark;
}

.sec__header {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
}

.sec__desc {
  font-size: 0.875rem;
  line-height: 1.4375rem;
  margin-bottom: 2.5rem;
}

.sec__sectionGroup {
  background: #f6f6f7;
  border: 1px solid #f2f1f3;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2.5rem 0;
  padding: 1.5rem;
}

.sec__sectionItem {
  display: flex;
  gap: 1.5rem;
}

.section__expanded {
  margin: 1.5rem 0 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 980px;
  width: 100%;
}

.lessonIndex {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #63606c;
}

.lessonIcon {
  margin-left: 1.5rem;
  margin-right: 1.125rem;
}

.lessonName {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  color: #323036;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lessonDuration {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  color: #7c7887;
}
.lessonItemInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:flex-start;
  gap: 8px;
  width: 100%;
}
.lessonItemCtrl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.lessonQuizCtrl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
}
.quizChip {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background: #FFFFFF;
  border: 1px solid #DFE1E6;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 8px;
}
.quizChip .name{
  color: #323036;
  font-weight: 500;
}
.quizChip .time{
  color: #7C7887;
}
.draggableItem {
  flex: 1 1 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 5px;
  padding: 0.625rem 1rem;

  & :nth-of-type(1) {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  & :nth-of-type(2) {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
}
.lessonItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50rem;
  background-color: #fff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 5px;
  padding: 0.625rem 1rem;

  & :nth-of-type(1) {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  & :nth-of-type(2) {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
}

.expandableSection {
  & > svg {
    transition: transform 0.4s;
  }
}

.expandedSection {
  & > svg {
    transform: rotate(180deg);
  }
}

.sec__sectionItemEditable {
  width: 38.75rem;
  display: flex;
  & > span {
    flex-shrink: 1;
    background: rgba(121, 77, 245, 0.2);
    border-radius: 3px;
    padding: 0.5rem 1.0625rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-align: center;
    color: $TextDark;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-grow: 1;
    border-radius: 3px;
    padding-right: 0.5rem;
    background-color: #fff;
    overflow: hidden;
    & > input {
      width: 100%;
      height: 100%;
      border: 0;
      background-color: #fff;
      padding: 0 0.875rem;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.625rem;
      color: $TextDark;

      & :focus {
        border: 1px solid #794df5;
      }
    }
  }
}

.dragHandle {
  cursor: grab !important;
}

.sec__sectionItemEditIcon {
  cursor: pointer;
}

.sectionIconGroup {
  display: flex;
  align-items: center;
  gap: 2.5rem;

  & > span {
    cursor: pointer;
  }
}

@media (max-width: 800px) {
  .ContentBox {
    display: block;
    margin-top: 20px;
  }
  .sec__sectionItem {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sec__sectionItem > div {
    margin-top: 10px;
  }
}

@media (max-width: 700px) {
  .sec__sectionItemEditable {
    width: 100%; 
    flex-direction: column; 
    align-items: flex-start; 
  }
}
