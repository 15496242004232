.customBatch__form {
    background-color: #fff;
    border: 1px solid #d8d6db;
    border-radius: 5px;
    width: 49.375rem;
    padding: 2rem 4rem 2.5rem;
    margin: 0 auto;
  }
  
  .customBatch_RegFilter {
    display: flex;
    gap: 1rem;
  }
  
  .custom__batch__back {
    width: 49.375rem;
    margin: 0 auto;
    margin-top: 1.5rem;
  }

  .closeButton {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      transform: translateY(-50%) scale(1.5);
    }
  }

  .MuiIconButton-root:hover, .MuiIconButton-root:active {
    background-color: transparent !important;
    background: transparent !important;
  }

  