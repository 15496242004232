.page {
  display: flex;
  flex-direction: column;
  :global(.MuiTableHead-root) {
    :global(.MuiTableCell-root) {
      &:last-of-type {
        color: transparent;
      }
    }
  }
}
.dashboard {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 40px;
}
.customerSelectContainer {
  // align-items: center;
  background-color: #fff;
  // color: #20222f;
  display: flex;
  // font-size: 16px;
  justify-content: flex-start;
  padding: 16px;
  // & > :global(.MuiInputBase-root) {
  //   &::after,
  //   &::before {
  //     content: none;
  //   }
  // }
}
.customerSelect {
  background-color: #f5f5f5 !important;
  border: 1px solid #dadada !important;
  border-radius: 3px !important;
  padding-left: 12px !important;
  white-space: nowrap;
}
.dashboardInfoBox {
  align-items: center;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  display: flex;
  justify-content: space-between;
  padding: 24px;
  &:nth-child(1) {
    .dashboardInfoBoxIcon {
      background: #eceffa;
    }
  }
  &:nth-child(2) {
    .dashboardInfoBoxIcon {
      background: #faefec;
    }
  }
}
.dashboardInfoBoxTitle {
  color: #20222f;
  font-size: 28px;
  font-weight: 500;
  line-height: 42px;
}
.dashboardInfoBoxDescription {
  color: #3a3a3a;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.dashboardInfoBoxIcon {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}
