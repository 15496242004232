@import "./icons.scss";

// RTL Mode
@import "./bootstrap.scss";
@import "./app.scss";

#root > .main-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
}

body,
#root,
#root > .main-content {
  .assessment-question {
    &,
    * {
      background-color: transparent !important;
      color: #000 !important;
      font-family: Poppins !important;
      font-size: 15px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      list-style: none !important;
      margin: 0px !important;
      margin-right: 10px !important;
      padding: 0px !important;
      text-decoration: none !important;
    }

    img {
      max-height: 100px;
      max-width: 160px;
    }
  }

  .assessment-option {
    margin-bottom: 15px !important;

    * {
      background-color: transparent !important;
      color: #000 !important;
      font-family: Poppins !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      list-style: none !important;
      margin: 0px !important;
      padding: 0px !important;
      text-decoration: none !important;
    }

    img {
      max-height: 75px;
      max-width: 160px;
    }
  }

  #sidebar-menu {
    ul {
      li {
        a {
          i {
            min-width: 2rem;
          }
        }
      }
    }
  }

  .noti-icon .badge {
    left: 23px;
  }

  .dataTables_filter,
  .dataTables_paginate {
    float: right;
  }

  .rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
  }

  .dz-message {
    padding: 30px;
    text-align: center;
  }

  // kanban board
  .fcYNFc,
  .sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
  }

  .task-box {
    border: 1px solid $border-color;
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }

  .ReactModal__Overlay {
    z-index: 1001 !important;
  }

  .chat-conversation .right .conversation-list {
    margin-right: 15px;
  }

  .external-event {
    &:hover {
      cursor: pointer;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .rating-container {
    background-color: transparent !important;
  }

  .input-group-append {
    z-index: 0;
  }

  .input-color {
    color: $input-color !important;
  }

  .sketch-picker {
    position: absolute;
    z-index: 1;
  }

  .rangeslider__fill {
    background-color: $primary !important;
  }

  .form-control {
    &:focus {
      border: 1px solid #784df5 !important;
    }
  }

  .flatpickr-months .flatpickr-month {
    background: $primary !important;
  }

  .flatpickr-weekdays {
    background: $primary !important;
  }

  span.flatpickr-weekday {
    background: $primary !important;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $primary !important;
  }

  span.flatpickr-weekday {
    color: $white !important;
  }

  .flatpickr-day.selected {
    background: $primary !important;
  }

  .bs-select select {
    width: auto !important;
  }

  .disableCopyPaste {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .monaco-editor .lines-content,
  .monaco-editor .view-line,
  .monaco-editor .view-lines {
    -webkit-user-select: text !important;
    -ms-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -o-user-select: text !important;
    user-select: text !important;
  }
}

.terv-row {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 1.5rem;
  width: 32rem;
}

.editorWraper {
  min-width: 32.5rem;
  max-width: 37.5rem;
}
.editorWraperPP {
  min-width: 32.5rem;
  max-width: 57.5rem;
}
.editorWraperMobile {
  min-width: 20rem;
  max-width: 22rem;
}

.terv-row-lg {
  width: 42rem;
}

.terv-form-sm {
  width: 5.75rem;
}

.terv-form-smd {
  width: 19.6875rem;
}

.terv-form-md {
  width: 22rem;
}

.terv-form-lg {
  width: 600px;
}
.terv-form-full {
  width: 100%;
  *width: calc(100% - 24px);
}
.terv-formHelperTextBlock {
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
}
.terv-formHelperTextNew {
  display: block;
  margin-top: -8px;
  margin-bottom: 16px;
}
.terv-formNew {
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  height: 40px;
  outline: none;
  padding: 12px 12px;
  margin-bottom: 16px;
  &:active {
    border: 1px solid #794df5;
  }
  &:focus {
    border: 1px solid #794df5;
  }
}

.terv-form {
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  height: 2.5rem;
  outline: none;
  padding: 0 0.5em;

  &:focus {
    border: 1px solid #794df5;
  }
}

.terv-password-icon {
  color: #a6a6aa !important;
  cursor: pointer !important;
}

@mixin TervInput($w: 15rem) {
  background: #fafbfc !important;
  font-family: "Poppins" !important;
  font-size: 0.8125rem !important;
  font-weight: 500 !important;
  height: 2.5rem !important;
  width: $w !important;

  & fieldset {
    border: 1px solid #dfe1e6 !important;
  }

  &.Mui-focused fieldset {
    border: 1px solid #794df5 !important;
  }

  & input {
    height: 0.2rem !important;
  }
}

.terv-form-with-icon-sm {
  & .MuiOutlinedInput-root {
    @include TervInput(9.3rem);
  }
}

.terv-form-with-icon {
  & .MuiOutlinedInput-root {
    @include TervInput;
  }
}

.terv-form-header {
  color: #19181b;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 12px;
}

.terv-label {
  color: #262626;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.terv-sublabel {
  color: #504f51;
  font-size: 13px;
  line-height: 19px;
  margin: 0 0 0.5625rem 0;
  padding: 0;
}

.terv-divider {
  background-color: #e3f2ff;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  width: 100%;
}

.terv-back {
  color: #3a3a3a;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  display: block;

  &:hover {
    color: #3a3a3a;
  }
}

.textStriked {
  text-decoration: line-through;
}

.terv-pt-6 {
  padding-top: 6px;
}
