@import "../../practice-lab.scss";

.solution {
  font-weight: 400;
  overflow: hidden;
  padding: 10px 0px;
  text-overflow: ellipsis;
  word-wrap: break-word;

  * {
    background-color: transparent !important;
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: normal !important;
    font-weight: inherit !important;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  img {
    border-radius: 4px !important;
    max-height: 72px !important;
    max-width: 80% !important;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
    position: relative;
  }
}