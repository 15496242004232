.option {
  padding: 0.8rem;
  color: #000;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeeee;
  overflow: hidden;
}

.option img {
  max-height: 100px;
  width: auto;
  object-fit: cover;
}

.correctOption {
  background-color: #a3ffc2;
}

.wrongOption {
  background-color: #ffc5c5;
  border: 1px solid #d42216;
}
