.main {
  padding: 1rem 0;
  padding-left: 2rem;
  background: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 10px;
  max-height: 9.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -8px;
  margin-bottom: 1rem;
  overflow: hidden;
  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: #323036;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #63606c;
    margin: 0;
  }
  &.smallScreen {
    padding: 1rem 0;
    padding-left: 1rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    h4 {
      font-size: 16px;
      line-height: 1.5;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
