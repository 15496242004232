.stopwatch {
  align-items: center;
  border-radius: 4px;
  display: flex;

  & * {
    color: #fff;
  }
}

.controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    border-style: solid;
    border-width: 2px;

    &:disabled {
      color: inherit;
      opacity: 0.5;
    }
  }
}