/** @format */
.cover {
  background: linear-gradient(#1B1834, #381571, #794DFD);
  width: 100%;
  height: auto;
}
.carouselSlider {
  padding-bottom: 5%;
  width: 95%;
  display: flex;
  align-items: center;
  margin: 0 auto; 

  // @media (max-width: 1020px) {
  //   // height: 574px;
  //   height: 700px;
  // }
}
.container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // grid-gap: 6.9375rem;
  // background: url("../../../assets//book-call-bg.png") no-repeat center;
  // background: linear-gradient(#1B1834, #381571, #794DFD);
  // height: auto;
  // background-size: cover;
  // padding: 3rem 2rem;
  // width: 100% !important;
  // margin: 0 !important;

  display: flex;
  align-items: center;
  justify-content: space-around;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(19, 15, 37, 0.4);
  background-color: rgba(19, 15, 37, 0.4);
  border-radius: 25px;
  // gap: 7.75rem;
  // background: url("../../../assets/join-group-bg.png") no-repeat center;
  // background-size: cover;
  width: 100%;
  height: auto;
  padding: 3rem 2rem;
}

@media (max-width: 960px) {
  .container {
    flex-direction: column;
  }
}

.containerItemOne {
  & > p {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.1em;
    color: #794df5;
  }

  & > h4 {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.625rem;
    color: #19181b;
  }

  & > ul {
    padding-left: 0;
    & > li {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.625rem;
      color: #4a4851;
      margin-top: 1.5rem;
      display: flex;
      gap: 0.75rem;
      align-items: center;
    }
  }
}

.MobcontainerItemTwo {
  background: #ffffff;
  border: 1px solid #f2f1f3;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 10px;
  // width: 20rem;
  & > form {
    padding: 0 3.125rem;
    padding-bottom: 2.3125rem;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > input {
        background: #fafbfc;
        border: 1px solid #dfe1e6;
        box-sizing: border-box;
        border-radius: 3px;
        width: 100%;
        margin: 0 0 0 -25px;
        padding: 0.8125rem 1rem;
        &::placeholder {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          letter-spacing: 0.01em;
          color: #63606c;
        }
      }
    }
    & > p {
      font-size: 0.75rem;
      line-height: 1.4375rem;
      color: #4a4851;
      margin-top: 0.75rem;
      margin-bottom: 0.5625rem;
      text-align: center;
    }
  }
}

.containerItemTwo {
  background: #ffffff;
  border: 1px solid #f2f1f3;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 10px;
  width: 31.25rem;
  & > form {
    padding: 0 3.125rem;
    padding-bottom: 2.3125rem;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > input {
        background: #fafbfc;
        border: 1px solid #dfe1e6;
        box-sizing: border-box;
        border-radius: 3px;
        width: 100%;
        padding: 0.8125rem 1rem;
        &::placeholder {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          letter-spacing: 0.01em;
          color: #63606c;
        }
      }
    }
    & > p {
      font-size: 0.75rem;
      line-height: 1.4375rem;
      color: #4a4851;
      margin-top: 0.75rem;
      margin-bottom: 0.5625rem;
      text-align: center;
    }
  }
}

.terms {
  font-size: 0.25rem;
  color: #7c7887;
  margin-top: 1rem;
  margin-bottom: 0.625rem;
}
