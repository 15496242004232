.cards {
  grid-row: 1/1;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(4, 17.6875rem);
  gap: 2rem;
  place-content: center;
}

@media (max-width: 960px) {
  .cards {
    grid-template-columns: repeat(2, 10.4375rem);
  }
}

@media (min-width: 960px) and (max-width: 1000px) {
  .cards {
   gap: 0.5rem;
   grid-template-columns: repeat(4, 12.8rem);
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .cards {
   gap: 0.5rem;
   grid-template-columns: repeat(4, 13.5rem);
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .cards {
   grid-template-columns: repeat(4, 15rem);
   gap: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1340px) {
  .cards {
   grid-template-columns: repeat(4, 16.5rem);
   gap: 0.5rem;
  }
}
