.loadDataMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    
    
    width: 375px;
    height: 40px;
    left: 255px;
    
    
    background: rgba(217, 146, 41, 0.1);
    border-radius: 3px;
  }
  
  .loadDataText {
    display: flex;
    gap: 0.5rem;
    > p {
        font-family: 'Poppins';  
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #63606c;
      margin: 0;
      position: static;
width: 319px;
height: 24px;
left: 40px;
top: 8px;
    }
  }

.add {
  position: static;
width: 265px;
height: 26px;
left: 18px;
top: 0px;


font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 26px;
/* identical to box height, or 162% */


/* Grey/Grey 1 */

color: #19181B;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 8px 0px;
}  
  
.add2 {
  position: static;
width: 301px;
height: 24px;
left: 0px;
top: 34px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
justify-content: center;
align-items: center;
/* identical to box height, or 171% */


/* Grey/Grey 4 */

color: #63606C;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 8px 0px;
}

.submit{
  position: static;
width: 400px;
height: 20px;
left: 0px;
top: 38px;

/* Medium/12px */

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 167% */


/* Grey/Grey 4 */

color: #63606C;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 6px 0px;
}
  