.page {
    display: flex;
    flex-direction: column;
  }
  .tabContent {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .userInfo {
    align-items: baseline;
    display: flex;
    justify-content: flex-start;
  }
  .userInfoName {
    color: #1a1b22;
    font-size: 18px;
  }
  .userInfoDot {
    background-color: #c4c4c4;
    border-radius: 12px;
    height: 8px;
    margin: 0 8px 4px 8px;
    width: 8px;
  }
  .userInfoDepartment {
    color: #7a7981;
    font-size: 16px;
  }
  .completedStatus,
  .inProgressStatus,
  .incompleteStatus {
    display: flex;
    position: relative;
    &::before {
      border-radius: 12px;
      content: " ";
      display: block;
      height: 8px;
      margin-right: 6px;
      margin-top: 5px;
      position: relative;
      width: 8px;
    }
  }
  .completedStatus {
    &::before {
      background-color: #1ba94c;
    }
  }
  .inProgressStatus {
    &::before {
      background-color: #eeb60c;
    }
  }
  .incompleteStatus {
    &::before {
      background-color: #d42216;
    }
  }
  .dashboard1 {
    display: flex;
    margin-bottom: 40px;
  }
  .dashboardInfoBox {
    background-color: #fff;
    display: flex;
    padding: 16px;
    height: 141px;
    z-index: 100;
  }
  .dashboardInfoBoxTitle {
    color: #20222f;
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
  }
  .dashboardSearchBoxContainer {
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 15px;
    display: flex;
  }
  .dashboardExportButtonContainer {
    position: absolute;
    right: 3rem;
  }
  .customerSelectContainer {
    background-color: #fff;
    display: flex;
    padding: 16px;
    height: 103px;
    z-index: 100;
    margin-top: 40px;
  }
  .chooseOptionsContainer {
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #323036;
  }
  .dashboardExportButton {
    width: 114px;
    height: 39px;
    background: #F9F9F9;
    border: 1px solid #D7D7D7;
    box-sizing: border-box;
    border-radius: 3px;
  }
  .chooseCustomerAndUserContainer {
    display: flex;
  }
  .chooseRangeContainer {
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #323036;
    margin-left: 30px;
  }
  .chooseStartDateContainer {
      display: flex;
  }
  .date {
    display: block;
    width: 100%;
    height: calc( 1.5em + 0.94rem + 2px );
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .resetFilter {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #0030DA;
    padding: 5px 0px 0px 20px;
    cursor: pointer;
  }
  .resetFilter:hover {
    text-decoration: underline;
  }
  
  