.level {
  align-items: center;
  background-color: #fff;
  color: #24262e;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  justify-content: space-between;
  line-height: 24px;
  padding: 16px 20px 16px 20px;
  text-align: center;
  width: 100%;
}

.levelIcon {
  height: 165px;
  margin-top: 10px;
}