.qb_cards:hover {
  transform: scale(1.03);
}

.details_restrict {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.modal-dialog{
  overflow-y:'hidden';
}

.content_restrict {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.name_restrict {
  max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.totalWidth {
  width: 65%
}

.qb_title {
  opacity: 1;
  display: block;
  backface-visibility: hidden;
  color: #784df5;
  min-height: 30px;
}

.qb_img {
  object-fit: contain !important;
}

.qb_button,
.qb_button_edit {
  margin-right: 3px;
  margin-bottom: 6px;
  background-color: #51b960 !important;
  border: 1px solid #51b960 !important;
}

.qb_footer {
  text-align: center;
  text-decoration: underline;
}

.MuiTooltip-tooltipArrow {
  background: #333333 !important;
  font-size: 12px !important;
}
.MuiTooltip-arrow {
  color: #333333 !important;
}

.pageFirst,
.pageLast,
.pagePrev,
.pageNext {
  background-color: #f3f3f3 !important;
  border: 1px solid #bdbdbd;
  padding: 5px 8px 5px 8px;
}

.pageLast {
  border-radius: 0px 5px 5px 0px;
}
.pageFirst {
  border-radius: 5px 0px 0px 5px;
}

.pagination > .active {
  background: #157f1f;
  padding: 5px 10px;
}

.activePage {
  color: #fff !important;
  font-weight: 600;
}

.defaultPageLink {
  color: #000;
  font-weight: 600;
}

.defaultPageContainer {
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #bdbdbd;
}

.pageLinkFirst,
.pageLinkLast,
.pageLinkPrev,
.pageLinkNext {
  color: #157f1f;
  font-weight: 600 !important;
}

.activeBadge {
  background-color: #784df5 !important;
}

.disabledBadge {
  background-color: #c6c5c5 !important;
}
