.page {
  display: flex;
  flex-direction: column;
}
.tabContent {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.userInfo {
  align-items: baseline;
  display: flex;
  justify-content: flex-start;
}
.userInfoName {
  color: #1a1b22;
  font-size: 18px;
}
.userInfoDot {
  background-color: #c4c4c4;
  border-radius: 12px;
  height: 8px;
  margin: 0 8px 4px 8px;
  width: 8px;
}
.userInfoDepartment {
  color: #7a7981;
  font-size: 16px;
}
.completedStatus,
.inProgressStatus,
.incompleteStatus {
  display: flex;
  position: relative;
  &::before {
    border-radius: 12px;
    content: " ";
    display: block;
    height: 8px;
    margin-right: 6px;
    margin-top: 5px;
    position: relative;
    width: 8px;
  }
}
.completedStatus {
  &::before {
    background-color: #1ba94c;
  }
}
.inProgressStatus {
  &::before {
    background-color: #eeb60c;
  }
}
.incompleteStatus {
  &::before {
    background-color: #d42216;
  }
}
