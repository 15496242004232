.carouselSlider {
    padding-bottom: 5%;
    width: 95%;
    display: flex;
    align-items: center;
    margin: 0 auto; 
  
    // @media (max-width: 1020px) {
    //   // height: 574px;
    //   height: 700px;
    // }
  }

  .carouselProvider {
    background: linear-gradient(#1B1834, #381571, #794DFD);
    width: 100%;
    height: auto;
  }

  .Container {
    display: flex;
    // align-items: center;
    justify-content: space-around;
    width: 100%;
    height: auto;
    padding: 3rem 2rem;
    @media (max-width: 1120px) {
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }
  }

  .certificate {
    box-shadow: 0px 0px 50px -10px #0000001A;
    width: 250px;
    height: 640px;
    gap: 0px;
    border-radius: 4px;
    @media (max-width: 1120px) {
        width: auto;
        height: auto;
    }
  }

  .folderBox {
    width: 830px;
    height: 132px;
    top: 146px;
    left: 37px;
    padding: 2%;
    gap: 0px;
    border-radius: 4px;
    display: flex;    
    justify-content: space-between;
    align-items: center;
    background-color: #EEF0F1;

    @media (max-width: 1120px) {
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
    }
  }

  .RightBorder {
    box-shadow: 0px 0px 50px -10px #0000001A;
    width: auto;
    height: auto;
    padding: 25px;
    gap: 0px;
    border-radius: 4px;
    @media (max-width: 1120px) {
        padding: 5px;
    }
  }

  .head {
font-size: 24px;
font-weight: 400;
line-height: 31.2px;
text-align: left;
color: #02203C;
  }

  .title1 {
    font-size: 20px;
font-weight: 400;
line-height: 26px;
text-align: left;
color: #02203C;
  }

 .sub {
font-size: 16px;
font-weight: 700;
line-height: 22.86px;
text-align: left;
 }
  .joinGroupContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(19, 15, 37, 0.4);
    background-color: rgba(19, 15, 37, 0.4);
    border-radius: 25px;
    // gap: 7.75rem;
    // background: url("../../../assets/join-group-bg.png") no-repeat center;
    // background-size: cover;
    width: 100%;
    height: auto;
    padding: 3rem 2rem;
  }

  @media (max-width: 1020px) {
    .joinGroupContainer {
      flex-direction: column;
    }
    .joinGroupActions {
      min-width: 60%;
    }
  }

  .topic {
    // font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: #2FFF4B;
  }
  .main {
    width: 100%;
  }

  .joinGroupHeader {
    color: #fff;
    font-size: 56px;
    font-weight: 600;
    line-height: 79.88px;
    text-align: left;
  }

  .joinGroupList {
    font-size: 32px;
    font-weight: 400;
    line-height: 46px;
    text-align: left;
    color: #fff;
    // width: 620px;
    @media (min-width: 960px) {
        width: 600px;
    }
  }

  .joinGroupList2 {
    font-size: 32px;
    font-weight: 400;
    // line-height: 46px;
    text-align: left;
    color: #2FFF4B;
    // border: 1px solid #2fff4b;
    // width: 620px;
    @media (min-width: 960px) {
        width: 600px;
    }
  }