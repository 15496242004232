@import "../../assets/scss/mixins.scss";

@mixin smallScreen {
  @media screen and (max-width: 640px) {
    @content;
  }
}

@mixin mediumScreen {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin largeScreen {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

.page {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1400px;

  &,
  * {
    font-family: "Poppins", sans-serif;
  }

  @include applyNotchProperty(padding-left, left, -1px, -1px, true);
  @include applyNotchProperty(padding-right, left, -1px, -1px, true);
}

.headerContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  position: relative;
}
.headerContainerMobile {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 0.5px;
  position: relative;
}

.header {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
  display: inline-flex;
  margin: 0 auto;
  width: 100%;

  &.paidPackage {
    max-width: 720px;

    .paymentContainer {
      border-left: none;
      border-top: 1px solid #eeeeee;
      width: 100%;
    }

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;

  @media screen and (max-width: 400px) {
    width: 100%;
  }
}

.textContainer {
  padding: 20px;
  width: calc(100% - 140px);

  @media screen and (max-width: 400px) {
    flex-direction: column;
    width: 100%;
  }
}

.paymentContainer {
  align-items: center;
  border-left: 1px solid #eeeeee !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
}

.paymentContainer {
  min-width: 256px;
}

.headingContainer {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.headingIcon {
  align-items: center;
  background: #47466c;
  border-radius: 5px;
  display: flex;
  flex-shrink: 0;
  height: 30px;
  justify-content: center;
  margin-right: 15px;
  width: 30px;
}

.headingText {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.25 !important;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerListItem {
  color: #5c5f65;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  margin-left: 16px;
  margin-top: 12px;
  position: relative;

  &::before {
    background-color: #1ba94c;
    border-radius: 20px;
    content: " ";
    display: block;
    height: 8px;
    left: -16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
  }
}

.percentageText {
  color: #242222;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 18px;
  margin: 10px 0 0 5px;
}

.availableQuestions {
  background: rgba(255, 249, 204, 0.7);
  border: 1px solid #ffe8b1;
  border-radius: 5px;
  box-sizing: border-box;
  color: #dc9c00;
  font-size: 14px;
  font-weight: 600;
  left: calc(100% + 32px);
  line-height: 22px;
  padding: 5px 16px;
  position: absolute;
  white-space: nowrap;
}

.optionsContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.groupingMethodsContainer {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}

.tabsRoot {
  width: 65rem !important;
}
.tabsRootMobile {
  width: 21rem !important;
}

.tabsFlexRoot {
  justify-content: flex-start;
}

.groupingMethodController {
  height: 100%;

  & :global(.MuiTabs-flexContainer) {
    height: 100%;
  }
}

.groupingMethodButton {
  background-color: transparent;
  border: none;
  color: #282c31;
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px;
  position: relative;

  &.activeMethod {
    &::after {
      background-color: #794df5;
      content: " ";
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      top: calc(100% - 1px);
      width: 100%;
    }
  }
}

.viewOptions {
  border-radius: 3px;
  filter: drop-shadow(0px 4px 16px rgba(89, 88, 96, 0.2));
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @include mediumScreen {
    display: none;
  }
}

.viewOptionsButton {
  border: none;
  height: 40px;
  padding: 0;
  width: 40px;

  &:not(.active) {
    background: #fff;
    color: #000;
  }

  &.active {
    background: #1ba94c;
    color: #fff;
  }
}

.insights {
  display: inline-grid;
  flex-grow: 0;
  gap: 22px;
  grid-auto-flow: column;
  margin: 0 auto 44px auto;
  max-width: 1024px;
  padding: 16px;
  width: 100%;

  @media screen and (max-width: 840px) {
    grid-auto-flow: unset;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 400px) {
    grid-auto-flow: unset;
    grid-template-columns: repeat(2, 1fr);
  }
}

.insightCard {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: auto;
  overflow: hidden;
  padding: 20px 12px 12px 12px;
  position: relative;
  width: 100%;

  &::before {
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }

  &.yellow {
    box-shadow: 0px 4px 16px rgba(253, 188, 31, 0.2);

    &::before {
      background-color: #fdbc1f;
    }

    .insightCardIconContainer {
      background: rgba(252, 244, 217, 0.5);
      color: #ffb400;
    }
  }

  &.green {
    box-shadow: 0px 4px 16px rgba(81, 185, 96, 0.2);

    &::before {
      background-color: #1ba94c;
    }

    .insightCardIconContainer {
      background: rgba(212, 241, 239, 0.5);
      color: #0a8d1d;
    }
  }

  &.orange {
    box-shadow: 0px 4px 16px rgba(254, 134, 104, 0.2);

    &::before {
      background-color: #fe8668;
    }

    .insightCardIconContainer {
      background: rgba(246, 227, 219, 0.5);
      color: #ff4c00;
    }
  }

  &.blue {
    box-shadow: 0px 4px 16px rgba(66, 105, 242, 0.2);

    &::before {
      background-color: #4269f2;
    }

    .insightCardIconContainer {
      background: rgba(224, 231, 255, 0.5);
      color: #0039fd;
    }
  }
}

.insightCardIconContainer {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.insightCardIcon {
  display: display;
  height: 40px;
  width: 40px;
}

.insightCardTitle {
  color: #3a3a3a;
  font-size: 13px;
  font-weight: 500;
  margin-top: 14px;
}

.insightCardValue {
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  margin-top: -6px;
}

.cardGroupsContainer {
  background-color: #fff;
  margin-bottom: 32px;
  width: 100%;

  &:not(.groupedByCategory) {
    .topicTag {
      display: none !important;
    }
  }

  &.list {
    padding: 32px 3em 44px 3em;

    .cardGroupContent {
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr;
    }

    .cardGroupHeading {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: 100%;

      &::after {
        left: 50%;
        transform: translateX(-50%);
        width: 32px;
      }
    }

    .question {
      align-items: center;
      gap: 40px;
      grid-template-rows: auto;
      padding: 24px 40px;
    }

    .question {
      &:not(.assessment) {
        grid-template-columns: 30px 1fr 1.25fr 0.5fr auto;

        .questionDescription {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
        }

        .questionSolveButtonLink {
          grid-column: 5 / 6;
          grid-row: 1 / 2;
        }

        .questionHeading {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }

        .tags {
          grid-column: 4 / 5;
          grid-row: 1 / 2;
        }
      }

      &.assessment {
        grid-template-columns: 1fr 1.25fr 80px 0.5fr auto;

        .questionDescription {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }

        .questionSolveButtonLink {
          grid-column: 5 / 6;
          grid-row: 1 / 2;
        }

        .questionHeading {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

        .tags {
          grid-column: 4 / 5;
          grid-row: 1 / 2;
        }
      }
    }

    .questionHeading {
      color: #000000;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.01em;
    }

    .questionComplexity {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    .assessmentDuration {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }

    .questionComplexity {
      height: 30px;
      width: 30px;
    }

    .questionSolveButton {
      height: 34px;
      margin: auto;
    }

    .completedTag {
      margin-left: 12px;
    }

    .tags {
      margin-left: auto;
    }
  }

  &.grid {
    padding: 32px 32px 44px 32px;

    @include mediumScreen {
      padding: 20px 20px 44px 20px;
    }

    @include smallScreen {
      padding: 16px 16px 24px 16px;
    }

    .cardGroupContent {
      display: grid;
      gap: 32px;
      grid-template-columns: 1fr 1fr 1fr;

      @include mediumScreen {
        gap: 28px;
        grid-template-columns: 1fr 1fr;
      }

      @include smallScreen {
        gap: 24px;
        grid-template-columns: 1fr;
      }
    }

    .cardGroupHeading {
      &::after {
        left: 0;
        width: 38px;
      }
    }

    .question {
      &:not(.assessment) {
        grid-template-columns: 1fr auto 30px;
        grid-template-rows: 30px auto 40px;
      }

      &.assessment {
        grid-template-columns: 1fr auto 80px;
        grid-template-rows: 30px auto 40px;
      }
    }

    .assessment,
    .question {
      padding: 14px 14px 16px 14px;
    }

    .questionHeading {
      color: #000000;
      font-size: 15px;
      font-weight: 500;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      letter-spacing: 0.01em;
      line-height: 24px;
    }

    .assessmentDuration,
    .questionComplexity {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }

    .questionDescription {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
      padding: 10px 0 16px 0;
    }

    .questionSolveButtonLink {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }

    .questionSolveButton {
      height: 40px;
    }

    .tags {
      grid-column: 2 / 4;
      grid-row: 3 / 4;
      margin-left: auto;
      margin-top: auto;
    }

    .completedTag {
      margin-Top: 12px;
    }
  }
}

.cardGroup {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 44px;
  }
}

.cardGroupHeading {
  color: #000000;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 27px;
  margin-bottom: 24px;
  position: relative;

  &::after {
    background-color: #794df5;
    content: " ";
    height: 2px;
    position: absolute;
    top: calc(100% - 2px);
  }
}

.question {
  background-color: #fff;
  border: 1px solid #f2f1f3;
  border-radius: 5px;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 4px 6px -4px rgba(24, 39, 75, 0.12);
  // display: grid;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.questionHeading {
  display: inline-block;
  margin: auto 0;
  overflow: hidden;
  padding-right: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assessmentDuration {
  align-items: center;
  background: #f2f1f3;
  border-radius: 3px;
  color: #323036;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 0 8px;
  white-space: nowrap;
}

.questionComplexity {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.5;
  text-align: center;
  text-transform: capitalize;

  &.easy {
    background-color: #38dc10;
  }

  &.medium {
    background-color: #fdbc1f;
  }

  &.hard {
    background-color: #fe8668;
  }

  max-width: max-content;
  padding: 0.3125rem 0.75rem;
}

.questionDescription {
  color: #929298;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 161.1%;
}

.questionSolveButton {
  color: #ffffff;
  font-size: 16px !important;
  font-weight: 600;
  min-width: 80px;
  min-height: 35px;
  padding-bottom: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 0 !important;

  &:disabled {
    background-color: #d8d6db !important;
    border: none !important;
    color: #63606c !important;
  }
}

// .tags {
//   align-items: center;
//   display: flex;
// }
.tagsMobile {
  align-items: center;
}

.completedTag,
.topicTag {
  align-items: center;
  border-radius: 3px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 26px;
  justify-content: left;
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topicTag {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
  line-height: 2.25;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.completedTag {
  background-color: rgba(0, 255, 37, 0.1);
  color: #149f28;
  line-height: 2;
  max-width: 120px;
}

.loader {
  * {
    stroke: #888 !important;
  }
}

.learningPathContainer {
  background-color: #fff;
  padding: 44px 0 0 0;

  @media screen and (max-width: 568px) {
    padding: 22px 0 0 0 !important;
  }
}

.learningPathTitle {
  padding: 24px;
  padding-top: 0px;
}

.learningPathTitle,
.learningPath {
  margin: auto;
  max-width: 800px;
  width: 100%;
}

.learningPath {
  display: block;
  padding: 16px;
  position: relative;

  &::before {
    background-color: #f2f1f3;
    border-radius: 10px;
    content: " ";
    display: block;
    height: calc(100% - 200px);
    left: 44px;
    position: absolute;
    top: 80px;
    width: 8px;
    z-index: 1;

    @media screen and (max-width: 560px) {
      height: calc(100% - 80px) !important;
      left: 50%;
      top: 24px;
      transform: translateX(-50%);
    }
  }
}

.learningPathItemWrapper {
  align-items: center;
  display: grid;
  gap: 32px;
  grid-template-columns: 64px 1fr;
  max-width: 100%;

  @media screen and (max-width: 560px) {
    display: flex !important;
    flex-direction: column !important;
  }
}

.learningPathItem {
  border-radius: 5px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08) !important;
  margin-bottom: 32px;
  max-width: 100%;
  padding: 24px !important;
  position: relative;
  width: 100%;
  z-index: 4;

  @media screen and (max-width: 560px) {
    padding: 12px 12px 12px 18px !important;
  }

  &.practice,
  &.test {
    &::before {
      content: " ";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 5px;
    }
  }

  &.practice {
    &::before {
      background-color: #1ba94c;
    }
  }

  &.test {
    &::before {
      background-color: #eeb60c;
    }
  }

  .type {
    background: #f2f1f3;
    border-radius: 5px;
    color: #4a4851;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1;
    padding: 6px 10px;
    text-transform: uppercase;

    @media screen and (max-width: 560px) {
      margin-bottom: 8px !important;
    }
  }

  .name {
    color: #323036;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }

  .info {
    color: #63606c;
    font-size: 16px;
    line-height: 26px;
  }
}

.learningPathHeader {
  display: grid;
  gap: 12px;
  grid-template-columns: auto 1fr;
  margin-bottom: 16px;

  @media screen and (max-width: 560px) {
    display: block;
    margin-bottom: 8px;
  }
}

.learningPathItemContent {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto;

  @media screen and (max-width: 560px) {
    display: flex;
    flex-direction: column !important;

    & > div {
      width: 100%;
    }

    & > button {
      margin: 8px auto auto 0 !important;
    }
  }

  & > button {
    height: 40px !important;
    margin: auto 0 auto auto;
    width: 100px;
  }
}

.paidPackage {
  .pathNumber {
    background-color: #a6f1d8;
  }
}

.pathNumberUnlocked,
.pathNumberLocked,
.pathNumber,
.pathNumberCompleted {
  align-items: center;
  border-radius: 50px;
  display: flex;
  flex-shrink: 0;
  font-size: 24px !important;
  height: 64px;
  justify-content: center;
  margin-bottom: 32px;
  width: 64px;
  z-index: 8;

  @media screen and (max-width: 560px) {
    font-size: 16px !important;
    height: 44px;
    margin-bottom: -24px !important;
    margin-right: 0px;
    width: 44px;
  }
}

.pathNumber {
  background-color: #a6f1d8;
  color: #4a4851;
}

.pathNumberLocked {
  background-color: #f2f1f3;
  color: #4a4851;
}

.pathNumberUnlocked {
  background-color: #defff4;
  color: #278601;
}

.pathNumberCompleted {
  background-color: #defff4;
  color: #5ac62f;
}

.helpIcon {
  cursor: pointer;
  position: absolute;
  right: 14%;
  top: 0;
  color: #2C5DE5;
  &:hover {
    text-decoration: underline;
  }
}
.demoIcon {
  cursor: pointer;
  position: absolute;
  right: 20%;
  top: 0;
  color: #2C5DE5;
  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 560px) {
  .helpIcon {
    top: -23px;
  }
  .demoIcon {
    top: -23px;
    right: 27%;
  }
}