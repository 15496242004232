.wrappedCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  min-height: 60vh;
}

.courseCard {
  width: 290px;
  height: 340px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(170, 170, 170, 0.25);
  border-radius: 5px;
  transition: transform 0.5s ease-out;
  position: relative;
  & > img {
    object-fit: fill;
    width: 100%;
    height: 8.75rem;
    border-radius: 5px 5px 0 0;
  }

  &:hover {
    transform: scale(1.03);
  }
}

.courseCardContent {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.courseCardDetails {
  display: flex;
  gap: 0.25rem;
  & > span {
    display: flex;
    align-items: center;
  }
}
.courseCardDetailsrow{
  display: flex;
  gap: 2.25rem;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.courseCardName {
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #323036;
  margin: 0;
  margin-bottom: 0.625rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cardButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  div {
    padding: 0.3rem;
    border: 1px solid #784df4;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
  }
}

.tag {
  position: absolute;
  top: 3px;
  right: 3px;
  font-weight: 500;
  border-radius: 5px;
  max-width: 105px;
  color: #fff;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 25px;
}

.tagAP {
  background-color: #ff3f3f;
}

.tagDF {
  background-color:#089A79;
}

.tagPN {
  background-color: #D99229;
}
