.main {
  padding: 1.5rem 0;
  background: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 10px;
  margin-bottom: 2rem;
  max-height: 9.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #323036;
    position: absolute;
    width: 109px;
    height: 26px;
    left: 24px;
    top: 116px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    width: 227px;
    height: 80px;
    left: 21px;
    top: 150px;
  }
}


.card {
  // padding: 1.5rem 0;
  background: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 10px;
  margin-bottom: 2rem;
  max-height: 9.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #323036;
    position: absolute;
    width: 109px;
    height: 26px;
    left: 24px;
    top: 116px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    width: 227px;
    height: 80px;
    left: 21px;
    top: 150px;
  }
}
