.upload {
  width: 7.75rem;
  padding: 0.25rem 1rem;
  margin: 0 auto;
  border: 1px solid #794df5;
  border-radius: 3px;
  color: #794df5;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
