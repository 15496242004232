@import "../../../../assets/scss/mixins.scss";

$largeScreen-padding: 24px;

.content {
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
}

.sliderContainer {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;

  :global(.MuiSlider-rail),
  :global(.MuiSlider-track) {
    opacity: 0 !important;
  }

  :global(.MuiSlider-thumb) {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    cursor: col-resize !important;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    height: 48px !important;
    justify-content: center;
    margin-top: -23px;
    padding: 4px !important;
    position: relative;
    transition-duration: 300ms;

    &::before {
      border-radius: 10px;
      content: ' ';
      display: block;
      height: calc(100% + 4px);
      margin-left: -2px;
      margin-top: -2px;
      position: absolute;
      width: calc(100% + 4px);
    }

    &:hover {
      &::before {
        border: 2px solid #aaa;
      }

      .widthSliderDot {
        background-color: #aaa !important;
      }
    }

    &:global(.MuiSlider-active) {
      &::before {
        background-color: #888 !important;
        border: 2px solid #888 !important;
      }

      .widthSliderDot {
        background-color: #fff !important;
      }
    }

    &::after {
      display: none !important;
    }
  }

  .widthSliderDot {
    background-color: #aaa;
    border-radius: 5px;
    height: 6px;
    margin: auto;
    position: relative;
    transition-duration: 300ms;
    width: 6px;
    z-index: 2;
  }
}

.questionSection {
  position: relative;

  &.mediumScreen {
    margin-top: 24px !important;
  }

  &.largeScreen {
    margin-right: 20px !important;
  }
}

:global(.small-screen) {
  .content {
    display: block;
  }
}

:global(.medium-screen) {
  .content {
    display: grid;
    gap: $largeScreen-padding;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    &>* {
      width: 100% !important;
    }
  }
}

:global(.medium-screen),
:global(.large-screen) {
  .content {
    grid-template-rows: 1fr;
    height: 100%;
  }
}

.flagged,
.answered,
.unanswered {
  &::before {
    border-radius: 0px;
    content: " ";
    display: block;
    height: 34px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
  }
}

.flagged {
  &::before {
    background-color: #EEB60C;
  }
}

.answered {
  &::before {
    background-color: #43B224;
  }
}

.unanswered {
  &::before {
    background-color: #96939F;
  }
}

.questionContainer {

  &,
  * {
    background-color: transparent !important;
    color: #323036 !important;
    color: inherit !important;
    font-family: inherit !important;
    font-style: normal !important;
    font-weight: 500 !important;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-decoration: none;

    &:not(sub):not(sup) {
      font-size: 14px !important;
    }
  }
}

.sectionTitle {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 8px !important;
  margin-top: 24px !important;
}

.sampleInput,
.sampleOutput {
  background-color: #ECEFFA;
  border-radius: 5px !important;
  overflow-x: auto;
  padding: 16px !important;
  white-space: pre;
}

.inputFormat,
.outputFormat {
  background-color: #ECEFFA;
  border-radius: 5px !important;
  overflow-x: auto;
  padding: 16px !important;
  white-space: pre-line;
}