/** @format */

@import "./Home.scss";

.footerContainer {
  display: grid;
  grid-template: repeat(3, min-content) / repeat(12, 1fr);
  // margin-top: 3.5rem;
  width: 100%;
  background-color: #261B4E;
  padding: 2.5rem 6.875rem;
}

.footerP1,
.footerP2,
.footerContactAddressP {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.8125rem;
  color: 
  #FFFFFF;
}

.listGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blockHeader {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.8125rem;
  color: #FFFFFF;
  margin-bottom: 1.5rem;
}

.listGroupItem {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #FFFFFF;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.listGroupItemSp {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #FFFFFF;
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
    color: #FFFFFF;
  }
}

.listGroupSp {
  padding-top: 20px;
}

.footerP1 {
  grid-column: 1/3;
  grid-row: 1/2;
}

.footerP2 {
  grid-column: 6/8;
  grid-row: 1/2;
}

.footerBlock1 {
  grid-column: 1/3;
  grid-row: 2/3;
}

.footerBlock2 {
  grid-column: 3/5;
  grid-row: 2/5;
  border-right: 1px solid #d8d6db;
}

.footerBlock3 {
  grid-column: 6/8;
  grid-row: 2/3;
}

.footerBlock4 {
  grid-column: 8/10;
  grid-row: 2/3;
}

.footerBlock5 {
  grid-column: 10/12;
  grid-row: 2/3;
}

.footerContact,
.footerContactAddress {
  margin-top: -6rem;
}

.footerContact {
  grid-column: 1/3;
  grid-row: 3/4;
}

.mobileFooterContact {
  padding-top: 30px;
  grid-column: 3/6;
  grid-row: 7/9;
}

.footerContactAddress {
  grid-column: 3/5;
  grid-row: 3/4;
}

@media (max-width: 960px) {
  .footerContainer {
    display: grid;
    grid-template: repeat(3, min-content) / repeat(6, 1fr);
    grid-gap: 2rem;
    gap: 0px;
    height: 1100px;
    padding: 2.5rem 0.875rem;
  }
  .footerP2 {
    padding-top: 30px;
    grid-column: 1/-1;
    grid-row: 3/4;
  }

  .footerBlock2 {
    padding-top: 30px;
    grid-column: 5/7;
    border: 0;
  }

  .footerBlock3 {
    padding-top: 30px;
    grid-column: 6/1;
    grid-row: 4/5;
  }

  .footerBlock4 {
    padding-top: 30px;
    grid-column: 5/7;
    grid-row: 4/5;
  }

  .footerBlock5 {
    padding-top: 30px;
    grid-column: 1/5;
    grid-row: 5/6;
  }

  .footerContact {
    padding-top: 30px;
    grid-column: 5/6;
    grid-row: 8/9;
  }
  .footerContactAddress {
    padding-top: 110px;
    grid-column: 6/1;
    grid-row: 6/6;
  }
}

.footerContactUs {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: left;
  color: #FFFFFF;
  margin: 0;
  margin-bottom: 1.5rem;
}

.footerContactUsContainer {
  display: flex;
  gap: 1rem;
}

.socialMediaItem {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  // place-items: center;
  align-items: center;
  justify-content: center;
  background-color: #794DF5;
  // gap: 1%;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.socialMediaItem:hover {
  background-color: #541BF5;
}

.footerAddressStyle {
  font-size: 0.9rem;
  line-height: 1.625rem;
  color: #FFFFFF;
  margin-right: 0.5rem;
}

@media (max-width: 1330px) {
  .footerContainer {
    padding: 2.5rem 2rem;
  }
}

@media (max-width: 580px) {
  .footerContainer {
    padding: 1.5rem 1rem;
  }
}
