/** @format */

.tandC {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 6.875rem;
  background-color: #261B4E;
  position: relative;
  & > div {
    flex: 1 1 0%;
  }
}
.flex {
  flex: 1 1 0%;
}
.madeMob {
  padding: 4px 0;
  display: flex;
  align-items: center;
  gap: 4px;
}
.made {
  padding: 4px 0;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  gap: 4px;
}
.tandC > div {
  flex: 1 1 0%;
}
.footerFlex {
  display: flex;
  align-items: flex-end;
  // justify-content: center;
  position: absolute;
  left: 71%;
}
.footerFlexMob {
  display: flex;
  align-items: flex-end;
  // justify-content: center;
  // position: absolute;
  // left: 71%;
  
}
.list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  & > li > a {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
    }
  }
}
.list li {
  margin-right: 16px;
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.list li:before {
  content: "";
  background-color: #bdbbc3;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 16px;
  border-radius: 50%;
  overflow: hidden;
}
.list li:first-of-type::before {
  display: none;
}
.copyRight {
  font-size: 10px;
  margin: 1rem 0;
  color:#FFFFFF;
}
.tandC img {
  height: auto;
  max-height: 32px;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

@media (max-width: 1330px) {
  .tandC {
    padding: 0.8rem 2rem;
  }
}

@media (max-width: 800px) {
  .tandC {
    display: block;
    padding: 1rem !important;
  }
  .footer,
  .footerFlex {
    margin: 1rem 0;
  }
}

@media (max-width: 580px) {
  .tandC {
    display: block;
    padding: 0 1rem;
  }
}
