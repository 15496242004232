.calculator {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding: 24px;
  :global(.calculator) {
    border-radius: 8px !important;
    box-shadow: none;
    height: 356px !important;
    overflow: hidden;
  }
  :global(.calculator-display) {
    flex: none !important;
    flex-shrink: 0 !important;
    height: 96px !important;
  }
  :global(.auto-scaling-text) {
    font-size: 48px;
    height: 100%;
    line-height: 2;
  }
  :global(.calculator-keypad) {
    flex-shrink: 0 !important;
    height: 260px !important;
  }
  :global(.calculator-key) {
    font-size: 28px !important;
    height: 52px !important;
    line-height: 1.75 !important;
    padding: 0 !important;
  }
  :global(.key-0) {
    padding-left: 30px !important;
  }
  :global(.key-dot) {
    font-size: 16px !important;
  }
}
.content {
  background-color: #777;
  border-radius: 12px;
  overflow: hidden;
  padding: 6px;
}
