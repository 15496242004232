.executionInfo {
  font-size: 16px;
}

.codeExecResult {
  border-radius: 5px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  margin-bottom: 24px;
  min-height: 50px !important;
  overflow: hidden;
  position: relative;

  &::before {
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 6px;
  }

  &.success {
    &::before {
      background-color: #39da0b;
    }
  }

  &.error {
    &::before {
      background-color: #ff0000;
    }
  }

  &.dark {
    background-color: #323036;
    color: #ddd;

    &.customInput {
      &::before {
        background-color: #fff !important;
      }
    }
  }

  &.light {
    background-color: #fff;
    color: #000;

    &.customInput {
      &::before {
        background-color: #000 !important;
      }
    }
  }
}

.codeExecResultHeading {
  cursor: pointer;
  font-size: 18px;
  padding: 12px 16px;
}

.codeExecResultContent {
  margin-top: 16px;
  padding: 12px 16px;

  .title {
    font-size: 16px;
  }

  .description {
    font-size: 16px;
    margin-bottom: 14px;
    opacity: 0.5;
    white-space: pre-wrap;
  }
}

.noHintText {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  margin: auto;
  opacity: 0.35;
  padding: 20px;
  width: 100%;
}
