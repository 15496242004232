.page {
  display: flex;
  flex-direction: column;
}
.tabContent {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.sectionsTab {
  border: 1px solid #ddd;
  margin: 32px auto 0 auto;
  width: auto;
}
