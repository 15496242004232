.manage-prepare__header--main {
  background-color: #fff;
  height: 12.5rem;
  margin: -1.875rem -1.0625rem 1.5rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.manage-prepare__header {
  margin-bottom: 0.5rem;
}

.manage-prepare__header,
.manage-prepare__header-sub {
  text-align: center;
}

.manage-prepare__header-sub {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #4a4851;
  margin: 0;
}
