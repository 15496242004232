.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.pagination {
  background-color: #f3f3f3 !important;
  border: 1px solid #bdbdbd;
  border-radius: 5px !important;
  cursor: pointer;
  display: inline-flex !important;
  font-family: "Poppins", sans-serif;
  list-style-type: none !important;
  margin: 0 auto !important;
  overflow: hidden !important;
  padding: 0;
  &.hideNext {
    .item {
      &:nth-last-child(2) {
        display: none !important;
      }
    }
  }
  &.hideLast {
    .item {
      &:nth-last-child(1) {
        display: none !important;
      }
    }
  }
}

.item {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 24px;
  min-width: 40px;
  padding: 0;
  padding: 3px 10px !important;
  text-align: center;
  user-select: none;
  * {
    color: #000;
  }
  &:hover {
    background-color: #ccc !important;
  }
  // &:nth-child(2) {
  //   display: none !important;
  // }
  &:first-of-type {
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
  }
  &:last-of-type {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  &:first-of-type,
  &:last-of-type,
  &:nth-child(2),
  &:nth-last-child(2) {
    background-color: transparent;
    * {
      color: #157f1f !important;
    }
  }
  &.active {
    background-color: #157f1f !important;
    border-color: #157f1f !important;
    * {
      color: #fff !important;
    }
  }
}

.info {
  color: #272727;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 27px;
  margin-top: 24px;
}
