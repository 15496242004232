@import "../../../../../../assets/scss/mixins.scss";

.smallFooter {
  position: fixed;
  z-index: 50 !important;

  &Dummy {
    position: relative;
    order: 4;
    opacity: 0;
    z-index: -1;
  }

  &,
  &Dummy {
    background-color: #F4F7FA !important;
    border-top: 1px solid #D8D6DB;
    bottom: 0;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 4px 6px -4px rgba(24, 39, 75, 0.12) !important;
    width: 100%;
  }
}

.smallFooterContent {
  background-color: #fff;
  border-bottom: 1px solid #F2F1F3;
  height: 56px;
  @include applyNotchProperty(padding-left, left, 16px, 16px, true);
  @include applyNotchProperty(padding-right, right, 16px, 16px, true);
}