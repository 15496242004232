.leaderboard,
.leaderboardBg {
  height: 100%;
  width: 100%;
}

.leaderboard {
  background-color: #201b2c;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1px 1fr;
  padding: 24px;
  position: relative;
}

.leaderboardDivider {
  height: calc(100% - 16px);
  margin: auto;
  width: 100%;
  background-color: #6d6d6d;
}

.leaderboardBg {
  object-fit: cover;
  object-position: center;
  position: absolute;
}

.leaderboardSection {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  z-index: 10;
}

.leaderboardTitle {
  color: #fff;
  font-size: 18px;
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: center;
}

.leaderboardTop7 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 64px;
}

.leaderboardTop3 {
  margin-top: 32px;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 16px;
  width: 100%;
}

.leaderboardViewAllButton {
  background-color: transparent;
  color: #FFF;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}

.leaderboardBody {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.leaderboardFooter {
  align-items: center;
  display: flex;
  justify-content: center;
}