@import "../../../../assets/scss/mixins.scss";

.container {
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 16px 0px !important;
  max-width: 100%;
  min-width: auto !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-wrap: break-word;

  @at-root :global(.practice-small-screen) & {
    min-height: 180px;
  }

  @at-root :global(.practice-medium-screen) & {
    min-height: 200px;
  }

  &.noShadow {
    box-shadow: none !important;
  }

  &.lightMode {
    .content {
      --unset: unset;
      // @include scrollbarLight;
    }
  }

  &:not(.lightMode) {
    .content {
      --unset: unset;
      // @include scrollbarDark;
    }
  }

  &.smallScreen {
    border-radius: 0px !important;
    flex: 1;
    overflow: hidden;
  }
}

.content {
  display: block;
  flex: 1;
  height: calc(100% - 42px);
  left: 0;
  overflow-y: auto !important;
  padding: 20px;
  position: relative;
  top: 0;
  width: 100%;

  &.smallScreen {
    padding: 16px;
  }

  @at-root :global(.practice-small-screen) & {
    height: calc(100% - 48px) !important;
  }
}