.headerBox {
    padding: 20px;
    text-align: center;
    margin-top: 20px;
}

.selectWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
}

.selectBox {
    margin-right: 30px;
    width: 100%;
}
