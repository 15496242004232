.container {
    background-color: white;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 25px;
}

.leftBorderLine {
    height: 100%;
    width: 4px;
    background: #1BA94C;
    position: absolute;
    left: 0;
    top: 0;
}

.packHeadWrap {
    display: flex;
    align-items: center;
}

.paidTag {
    width: 43px;
    height: 24px;
    background: #1E63CB;
    border-radius: 3px;
    color: white;
    padding: 2px;
}

.freeTag {
    width: 43px;
    height: 24px;
    background: #0BC196;
    border-radius: 3px;
    color: white;
    padding: 2px;
}

.titleFont {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-right: 20px;
}

.infoFont {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #63606C;
}

.ratingWrap, .infoWrap {
    display: flex;
    align-items: center;
}

.infoWrap {
    & a {
        padding-left: 10px;
        text-decoration: underline;
        color: #2170D8;
    }
}

.fontRat {
    font-weight: 600;
    font-size: 14px;
    color: #575656;
    padding-left: 10px;
}

.medFont {
    padding: 20px 0;
    & span {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #63606C;
    }
}

.dot {
    width: 6px;
    height: 6px;
    background: #C4C4C4;
    display: inline-block;
    border-radius: 50%;
    margin: 0 10px;
}

.starRating {
    padding-left: 10px;
}

.learnFont {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #63606C;
}

.totalFont {
    & span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #6F6F6F;
    padding-right: 10px;
    & b {
        color: black;
    }
    }
}

.statusCard {
    display: flex;
    align-items: center;
    height: 80px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(189, 189, 189, 0.12);
    border-radius: 5px;
    padding: 8px;
    &:first-child {
        margin-bottom: 20px;
    }
}

.vertiLine {
    width: 1px;
    height: 51px;
    background: #E1E1E1;
    margin: 0 10px;
}

.statusCardIn {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    & span:first-child {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    & span:last-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3A3A3A;
    }
}

.gColor {
    color: #1BA94C;
}

.yColor {
    color: #EEB60C;
}

.rColor {
    color: #D42216;
}

.liveWrap {
    display: flex;
    flex-direction: column;
    & span:first-child {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #6F6F6F;
    }
    & span:last-child {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #DE5445;
        text-transform: uppercase;
    }
}

.dateWrap {
    width: 111px;
    height: 56px;
    border: 1px solid #1BA94C;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    background: #FAFAFA;
    & span {
        font-weight: 500;
        font-size: 16px;
        color: #20222F;
    }
}

.timeWrap {
    width: 111px;
    height: 56px;
    border: 1px solid #D42216;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    background: #FAFAFA;
    & span {
        font-weight: 500;
        font-size: 16px;
        color: #20222F;
    }
}

.toAlign {
    padding-left: 10px;
    font-weight: normal;
    font-size: 12px;
    color: #3A3A3A;
}

.progressWrap {
    text-align: center;
}