.packagePurchaseContainer {
  align-items: center;
  background-color: #fd691e;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 26px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  padding: 12px;
  position: relative;
  text-align: center;
  width: 100%;
}

.packagePurchaseText {
  text-align: center;
  z-index: 10;
}

.packagePurchaseButton {
  background-color: #794df5;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
  padding: 12px 20px;
  z-index: 10;

  svg {
    height: 20px;
  }
}

.packagePurchaseContainerDots {
  height: 64px;
  position: absolute;

  &1 {
    left: 12px;
    top: 10px;
  }

  &2 {
    bottom: 10px;
    right: 12px;
  }
}