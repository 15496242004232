.TabPane {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  justify-content: center;
  margin-top: 20px;
}

.tabContent {
  padding: 10;
  background-color: rgba(255, 255, 255, 1);
  min-height: 60vh;
}

.question_count {
  border-radius: 1.5rem;
  border: 1px solid #51b960;
  display: inline-block;
  padding: 3px 5px 3px 5px;
  text-align: center;
  color: #1ba94c;
  font-weight: 600;
  overflow: hidden;
  .fileIcon {
    float: left;
    margin-top: -2px;
  }
}

.bankName {
  color: #000;
  font-weight: medium;
}

.cardViewButton {
  position: absolute;
  top: 20px;
  right: 10px;
  border-radius: 5px;
  font-size: 13;
  font-weight: 500;
}

.tag {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 500;
  border-radius: 0px 5px 0 5px;
  max-width: 105px;
  color: #fff;
  text-align: center;
  padding: 8px;
  padding-top: 3px;
  height: 25px;
}

.tagAP {
  background-color: #70b678;
}

.tagDF {
  background-color: #500cdf;
}

.tagPN {
  background-color: #d9bc00;
}
.tagRJ {
  background-color: #784df5;
}

.cardDescription {
  overflow: hidden !important;
  max-height: 70px !important;
  color: #a6a6aa !important;
  font-size: 15px !important;
}

.cardButtons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.paginationWrap {
  display: flex;
  justify-content: center;
  margin-top: 15;
  margin-right: 10;
}

.cardOverall {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19) !important;
}
