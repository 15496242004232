.learningPathList {
  padding: 0 !important;
  position: relative;

  &.notEmpty {
    &::before {
      background-color: #ddd;
      border-radius: 10px;
      content: ' ';
      display: block;
      height: calc(100% - 16px);
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 8px;
    }
  }
}
.descSpace {
  position: absolute;
  right: 4rem;
  top: 25rem;
}
@media only screen and (max-width: 1290px) {
  .descSpace{
    position: absolute;
    right: 3rem;
    top: 25rem;
  }
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.card {
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 4px 6px -4px rgba(24, 39, 75, 0.12) !important;
  position: relative;

  &,
  * {
    user-select: none;
  }

  &.practice,
  &:not(.practice) {
    &::before {
      content: ' ';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 5px;
    }
  }

  &.practice {
    &::before {
      background-color: #1BA94C;
    }
  }

  &:not(.practice) {
    &::before {
      background-color: #EEB60C;
    }
  }
}

.listItem {
  height: 60px !important;
  padding: 0 10px 0 24px;
}

.pathNumber {
  align-items: center;
  background-color: #FFEED7;
  border-radius: 5px;
  color: '#4A4851';
  display: inline-flex;
  font-size: 12px !important;
  font-weight: 600 !important;
  height: 28px;
  justify-content: center;
  min-width: 60px;
  padding: 4px 8px;
  text-transform: uppercase;
}

.promptContainer {
  width: 100%;
  max-width: 600px;
}