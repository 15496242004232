.carouselWrapper {
  display: flex;
  gap: 3rem;
  justify-content: center;
  transition: 1s;
}

.carouselContainer {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.iconButton {
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08) !important;
  border: 1px solid #f2f1f3 !important;
  font-size: 2rem !important;
  padding: 0.01em !important;
}
