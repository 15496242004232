.dialog {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #784df5;
    border-radius: 10px;
    margin: 0 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
}

.dialogTitle {
  border-top: 4px solid #794df5;
  text-align: center;
  height: 90px;
}

.mobileDialogTitle {
  border-top: 4px solid #794df5;
  text-align: center;
  height: 90px;
  // width: 22.5rem;
}

.dialogTitleHeader {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.dialogSubTitle {
  text-align: center;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.dialogAction {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 500px;
  // height: 450px;
}

.mobileDialogContent {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // gap: 15px;

  width: 100%;
  padding: 0 !important;
  margin-top: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.dialogContent::-webkit-scrollbar {
  display: none;
}

.signInPwdContainer {
  margin-top: 10px;
}

.signInInput1 {
  height: 40px;
  border: 0;
  width: 400px;
  height: 50px;
  outline: none !important;
}

.signInInput2 {
  height: 40px;
  border: 0;
  width: 400px;
  height: 50px;
  margin-top: 20px;
  outline: none !important;
}

.forgotPassword {
  margin-left: 270px;
  text-decoration: underline;
  color: #0377d1;
  cursor: pointer;
}

.mobileForgotPassword {
  // margin-left: 28rem;
  text-align: right;
  text-decoration: underline;
  color: #0377d1;
  cursor: pointer;
  width: 343px;
}

.fieldIcon {
  padding: 5px;
  float: left;
  height: 40px;
  border: 0px;
  margin-top: 4px;
  width: 32;
}

.forgotPwd {
  text-align: right;
  text-decoration: underline;
  color: #0377d1;
  cursor: pointer;
}

.signInButton {
  width: 400px;
  height: 50px;
}

.mobileSignInButton {
  width: 343px;
  height: 50px;
}

.inputFieldValidation {
  display: flex;
  flex-direction: column;
}

.signInButton,
.mobileSignInButton,
.createNewbutton {
  font-weight: 600 !important;
  font-size: medium !important;
}

.orDivision {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 400px;
  font-size: 20px;
}

.line1 {
  height: 1px;
  width: 180px;
  background: #e2e0e0;
}

.line2 {
  height: 1px;
  width: 180px;
  background: #e2e0e0;
}

.dialogContent1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 500px;
  height: 500px;
}

.mobileDialogContent1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 360px;
  height: 500px;
}

.userName {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 400px;
  margin-top: 0px;
}

.signInputContainer {
  margin-top: px;
}

.textField {
  input {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.formControl {
  select {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.dobAndGender {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 400px;
  margin-top: 0px;
}

.acceptTermsAndCondition {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.mobileAcceptTermsAndCondition {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 21rem;
}

.acceptTermText {
  margin-right: 150px;
  margin-top: 15px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.mobileAcceptTermText {
  margin-right: 55px;
  margin-top: 15px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.acceptTermText:hover {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #1c3ba7;
  text-decoration-line: underline;
}

.alreadyHaveAnAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.alreadyText {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #20222f;
}

.loginPageButton {
  font-size: 16px;
  margin-left: 10px;
  font-weight: bold;
  color: #794df5;
  cursor: pointer;
}

.newSignUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dontHaveAccount {
  color: #3a3a3a;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.signUpButton {
  width: 400px;
  height: 50px;
}

.mobileSignUpButton {
  width: 343px;
  height: 50px;
}

.signUpButton,
.mobileSignUpButton,
.createNewbutton {
  font-weight: 600 !important;
  font-size: medium !important;
}

.createNew {
  margin-bottom: 20px;
}

.createPrompt {
  text-align: center;
}

.createNewbutton {
  width: 80%;
}

.createNewWrapper {
  text-align: center;
}
