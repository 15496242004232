.searchForm {
  width: 30vw;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.searchFormPrimary {
  background-color: #fff;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 4px 6px -4px rgba(24, 39, 75, 0.12);
  border: 1px solid #bababa;
  height: 2.5rem;
}

.searchFormSecondary {
  background-color: #f5f5f5;
  border: 1px solid #dadada;
  height: 2.3rem;
}

.searchInput {
  border: 0;
  outline: none;
  width: 25rem;
  font-size: 1rem;
}

.secondaryInput {
  background-color: #f5f5f5;
}

.searchInput::placeholder {
  color: #a6a6a6;
}

.searchIcon {
  color: #a6a6a6;
  margin-right: 8px;
}

.searchForm::after {
  content: "";
  clear: both;
}
