@import "../../practice-lab.scss";

.name {

  &,
  * {
    color: inherit !important;
    font-family: inherit !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    white-space: unset !important;
  }
}

.question {
  margin-top: 22px !important;

  * {
    background-color: transparent !important;
    color: inherit !important;
    font-family: inherit !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-decoration: none;
    white-space: unset !important;
  }

  img {
    border-radius: 4px !important;
    max-height: 72px !important;
    max-width: 80% !important;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
    position: relative;
  }

  &.isLightMode {
    img {
      border: 1px solid #fff2;
    }
  }

  &:not(.isLightMode) {
    img {
      border: 1px solid #0001;
    }
  }

  img {
    border-radius: 4px;
    max-width: 60% !important;
  }
}

.sectionContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 40px);
  left: 0;
  position: relative;
  top: 0;
  width: 100%;

  @at-root :global(.practice-small-screen) & {
    height: calc(100% - 48px) !important;
  }
}

.questionContainer {
  flex-grow: 1;
  min-height: 30%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
}

.answerContainer {
  flex-grow: 1;
  min-height: 45%;
  overflow: hidden;
  position: relative;

  &.light {
    background-color: #eaf4ff;

    .inputControl {
      background-color: #00000003;
      border: 1px solid #e0e0e0;

      &:hover {
        background-color: #fffc;
      }
    }
  }

  &:not(.light) {
    background-color: #242744;

    .inputControl {
      background-color: #ffffff0a;
      border: 1px solid #fff2;

      &:hover {
        background-color: #ffffff1e;
      }
    }
  }
}

.answerContent {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 20px;
  position: relative;
}

.inputControl {
  border-radius: 6px;
  margin-bottom: 16px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 8px 16px 8px 8px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0px;
  }
}

.inputControlInput {
  margin-right: 6px;
}

.inputControlLabel {
  font-weight: 400;
  overflow: hidden;
  padding: 10px 0px;
  text-overflow: ellipsis;
  word-wrap: break-word;

  * {
    background-color: transparent !important;
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: normal !important;
    font-weight: inherit !important;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  img {
    border-radius: 4px !important;
    max-height: 72px !important;
    max-width: 80% !important;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
    position: relative;
  }
}

.heading {
  color: inherit !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin: 0px;
  margin-bottom: 8px !important;
  margin-top: 6px !important;
  text-decoration: underline;
}

.sampleText {
  color: inherit !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin: 0px;
  margin-bottom: 8px !important;
  opacity: 0.75;
  white-space: pre-line;
  word-break: break-all;
}

.questionContainer,
.answerContent {
  @at-root :global(.practice-light-mode) & {
    @include scrollbarLight;
  }

  @at-root :global(.practice-dark-mode) & {
    @include scrollbarDark;
  }
}