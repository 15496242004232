.listItem {
  display: grid;
  gap: 0 16px;
  grid-template-columns: auto 1fr 1fr auto;
  grid-template-rows: 40px;
  margin-bottom: 28px;
  width: 100%;
}
.actionContainer,
.indicator,
.nameContainer,
.percentageCompleted {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}
.indicator {
  color: #fe8668;
  font-size: 38px;
}
.name {
  color: #000000;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.actionButton {
  background-color: transparent;
  border: 1px solid #794df5;
  border-radius: 6px;
  color: #794df5;
  font-size: 16px;
  height: 100%;
  padding: 0 24px;
  &:hover {
    background-color: #794df5 !important;
    color: #ffffff !important;
  }
}
